import React, { useEffect, useState } from "react";
import { printHTML } from "@utility/htmlUtility";
import style from "./style.module.scss";
import { clsx } from "@utility/utility";

type TextErrorProps = {
  htmlLabel: string;
  className?: string;
  useMarginTop?: boolean;
};
const TextError = ({
  htmlLabel = "",
  className = "",
  useMarginTop = false,
}: TextErrorProps) => {
  return (
    <p
      className={clsx(style.errorText, {
        [className]: !!className,
        [style.marginTop]: useMarginTop,
      })}
      role="alert"
      dangerouslySetInnerHTML={printHTML(htmlLabel)}
    />
  );
};

export default TextError;
