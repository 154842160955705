import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import PageStandard from "@components/common/PageStandard/component";
import { useTranslation } from "@hooks/useTranslation";
import style from "./style.module.scss";
import stylePageCardMobile from "../../components/PageCardMobile/style.module.scss";
import { printHTML } from "@utility/htmlUtility";
import { useGetSetupVideos } from "@hooks/useGetSetupVideos";
import VideoTutorial from "@components/common/VideoTutorial/component";
import { useDispatch, useSelector } from "react-redux";
import { acuityActions, selectEnableNextButtonMap } from "@slices/acuitySlice";
import Button from "@components/common/Button/component";
import { useLandoltC } from "@hooks/useLandoltC";
import ModalCheck from "@components/common/ModalCheck/component";
import ModalDetectDevice from "@components/common/ModalDetectDevice/component";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type SetupStepType = {
  title: string;
  description: string;
  footerButtonLabel?: string;
  footerAction?: () => void;
  footerInlineButtonLabel?: string;
  footerInlineButtonAction?: () => void;
  footerLabelUnderButton?: string;
  footerAnalyticsInfoButton?: any;
  video?: string;
  audio?: string;
};

type Props = {};

function SetupStepPage(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslation();
  const setupVideos = useGetSetupVideos("tutorial");
  const enableNextButtonMap = useSelector(selectEnableNextButtonMap);
  const { needManualCalibration, calibratedManually } = useLandoltC(10, false);
  const [showModalCheck, setShowModalCheck] = useState(false);
  const [stopVideo, setStopVideo] = useState(false);

  let tutorialStep = useParams()?.tutorialStep;

  useEffect(() => {
    if (!tutorialStep || +tutorialStep < 1 || +tutorialStep > 3) {
      changeTutorialStep(1, true);
    }
  }, [!!tutorialStep]);

  const changeTutorialStep = (
    tutorialStepId: number,
    replace: boolean = false
  ) => {
    navigate(
      ROUTING.SETUP_STEPS.url.replace(
        ":tutorialStep",
        tutorialStepId?.toString() || ""
      ),
      {
        replace: replace,
        state: replace ? { isReplace: true } : undefined,
      }
    );
  };

  const goToNextStep = () => {
    if (+tutorialStep < steps?.length) {
      changeTutorialStep(+tutorialStep + 1);
    } else {
      setStopVideo(true);
      setShowModalCheck(true);
    }
  };

  useEffect(() => {
    if (needManualCalibration === "mini" && +tutorialStep === 1) {
      //if maybe the iphone is mini and the user is in the first step
      navigate(
        ROUTING.SETUP_CALIBRATE_SCREEN.url?.replace(
          ":tutorialStep",
          tutorialStep
        ),
        { replace: true, state: { isReplace: true } }
      );
    }
  }, [needManualCalibration, tutorialStep]);

  const steps: SetupStepType[] = useMemo(() => {
    const stepsTemp: SetupStepType[] = [
      {
        title: "setup.step1_title",
        description: "setup.step1_description",
        footerButtonLabel: "setup.step1_footer",
        footerAction: () => {
          //go to calibration ruler page
          navigate(
            ROUTING.SETUP_CALIBRATION_RULER.url.replace(
              ":tutorialStep",
              tutorialStep
            )
          );
        },
        footerAnalyticsInfoButton: {
          "data-element-id": "RxRenewal_SetUp-CalibrateNow",
          "data-description": formatAnalyticsInnerText(
            translate("setup.step1_footer")
          ),
          "data-analytics_available_call": 1,
        },
        // footerInlineButtonLabel: "setup.step1_footer_secondary",
        // footerInlineButtonAction: () => {
        //   navigate(ROUTING.SETUP_STEPS.url.replace("", "1"));
        // },
        video: setupVideos[0]?.uri || "",
        audio: "",
      },
      {
        title: "setup.step2_title",
        description: "setup.step2_description",
        footerButtonLabel: "setup.step2_footer",
        footerLabelUnderButton: "setup.step2_under_footer",
        footerAnalyticsInfoButton: {
          "data-element-id": "RxRenewal_SetUp-Done",
          "data-description": formatAnalyticsInnerText(
            translate("setup.step2_footer")
          ),
          "data-analytics_available_call": 1,
        },
        video: setupVideos[1]?.uri || "",
        audio: "",
      },
      {
        title: "setup.step3_title",
        description: "setup.step3_description",
        footerButtonLabel: "setup.step3_footer",
        footerAction: () => {
          //show check modal and go to the acuity tutorial
          goToNextStep();
        },
        footerAnalyticsInfoButton: {
          "data-element-id": "RxRenewal_SetUp-Done",
          "data-description": formatAnalyticsInnerText(
            translate("setup.step2_footer")
          ),
          "data-analytics_available_call": 1,
        },
        video: setupVideos[2]?.uri || "",
        audio: "",
      },
    ];

    if (needManualCalibration === "false" && !calibratedManually) {
      //device detected --> skip manual calibration
      stepsTemp.shift();
    }

    return stepsTemp;
  }, [
    setupVideos,
    translate,
    needManualCalibration,
    tutorialStep,
    calibratedManually,
  ]);

  const currentStep = steps[+tutorialStep - 1];

  return (
    <PageStandard
      className={`${style.setupStep} ${style.pageCardMobile} ${stylePageCardMobile.pageCardMobile}`}
      classNamePageBody={stylePageCardMobile.pageCardMobileBody}
      isLoading={needManualCalibration === "loading"}
      footerInfo={{
        className:
          ((currentStep?.footerInlineButtonLabel &&
            currentStep?.footerInlineButtonAction) ||
          currentStep?.footerLabelUnderButton
            ? style.footer
            : "") +
          " " +
          style.footerShadow +
          " " +
          (needManualCalibration === "loading" ? style.none : ""),
        backgroundWhite: true,
        showLeftButton: true,
        classNameLeftButton: stylePageCardMobile.footerLeftButton,
        iconLeftButton: "leftArrow",
        labelLeftButton: "",
        analyticsInfoLeftButton: {
          "data-element-id": "RxRenewal_SetUp-Back",
          "data-analytics_available_call": 0,
        },
        showRightButton: true,
        classNameRightButton: stylePageCardMobile.footerRightButton,
        labelRightButton: translate(currentStep?.footerButtonLabel),
        actionRightButton: currentStep?.footerAction || goToNextStep,
        disabledRightButton: !enableNextButtonMap["setup_" + tutorialStep],
        analyticsInfoRightButton: currentStep?.footerAnalyticsInfoButton,
        otherElements: (
          <>
            {currentStep?.footerInlineButtonLabel &&
              currentStep?.footerInlineButtonAction && (
                <Button
                  className={style.footerInlineButtonLabel}
                  variant="transparent"
                  onClick={currentStep?.footerInlineButtonAction}
                >
                  {translate(currentStep?.footerInlineButtonLabel)}
                </Button>
              )}
            {currentStep?.footerLabelUnderButton && (
              <p className={style.footerLabelUnderButton}>
                {translate(currentStep?.footerLabelUnderButton)}
              </p>
            )}
          </>
        ),
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div className={stylePageCardMobile.overPageContainer}>
        <p className={stylePageCardMobile.overPageTitle}>
          {translate("setup.step_title")}
        </p>
        <p
          className={stylePageCardMobile.overPageDescription}
          dangerouslySetInnerHTML={printHTML(
            translate("setup.step_stepper")
              ?.replace("{{from}}", tutorialStep)
              .replace("{{to}}", steps.length.toString())
          )}
        />
      </div>

      <div className={stylePageCardMobile.pageBodyCard}>
        {currentStep?.title && (
          <p
            className={stylePageCardMobile.title}
            dangerouslySetInnerHTML={printHTML(translate(currentStep.title))}
          />
        )}
        {currentStep?.description && (
          <p
            className={`${stylePageCardMobile.description} ${style.description}`}
            dangerouslySetInnerHTML={printHTML(
              translate(currentStep.description)
            )}
          />
        )}

        {currentStep?.video && (
          <VideoTutorial
            id={"video_" + tutorialStep}
            key={tutorialStep}
            classNameVideoContainer={style.videoContainer}
            src={currentStep.video}
            ariaLabel={currentStep.title}
            width={"100%"}
            height={"auto"}
            autoPlay={true}
            controls={false}
            playsInline={true}
            audio={currentStep.audio}
            onEnded={() => {
              if (!enableNextButtonMap["setup_" + tutorialStep]) {
                dispatch(
                  acuityActions.enableNextButton({
                    idToEnable: "setup_" + tutorialStep,
                  })
                );
              }
            }}
            stopVideo={stopVideo}
          />
        )}
      </div>

      {showModalCheck && (
        <ModalCheck
          isOpen={showModalCheck}
          timeoutMm={3000}
          callbackAfterTimeout={() => {
            navigate(ROUTING.ACUITY_TUTORIAL_START.url);
          }}
          message={translate("setup.modal_title")}
        />
      )}

      <ModalDetectDevice />
    </PageStandard>
  );
}

export default SetupStepPage;
