import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import PageStartTutorial from "@components/common/PageStartTutorial/component";
import ModalDetectDevice from "@components/common/ModalDetectDevice/component";
import { useTranslation } from "@hooks/useTranslation";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type Props = {};

function AcuityTutorialStartPage(props: Props) {
  const navigate = useNavigate();
  const translate = useTranslation();

  const goToFirstSetupStep = () => {
    navigate(ROUTING.ACUITY_TUTORIAL.url.replace(":tutorialStep?", "0"));
  };

  return (
    <>
      <PageStartTutorial
        title={translate("acuity_tutorial.start_title")}
        descriptions={[
          translate("acuity_tutorial.start_descr1"),
          translate("acuity_tutorial.start_descr2"),
        ]}
        buttonAction={goToFirstSetupStep}
        showBackButton={true}
        analyticsInfoButton={{
          "data-element-id": "RxRenewal_TutorialTest-Next",
          "data-description": formatAnalyticsInnerText(
            translate("footer.next")
          ),
          "data-analytics_available_call": 0,
        }}
        analyticsInfoLeftButton={{
          "data-element-id": "RxRenewal_TutorialTest-Back",
          "data-analytics_available_call": 0,
        }}
      />
      <ModalDetectDevice />
    </>
  );
}

export default AcuityTutorialStartPage;
