import Chip from "@components/common/Chip/component";
import LazyImage from "@components/common/LazyImage/LazyImage";
import { ROUTING } from "@utility/routingUtility";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import { handleOnEnterKeyPress } from "@utility/utility";
import { useTranslation } from "@hooks/useTranslation";

type Props = {
  iconPath: string;
  iconAltPath: string;
  label: string;
  stepId: string;
  stepNumber: string;
  answer: string[];
};
const RecapElement = ({
  iconPath,
  iconAltPath,
  label,
  stepId,
  stepNumber,
  answer,
}: Props) => {
  const navigate = useNavigate();
  const translate = useTranslation();

  useEffect(() => {}, []);

  const changeStep = () => {
    navigate(ROUTING.SURVEY.url.replace(":stepId?", stepNumber || ""));
  };

  return (
    <>
      <div className={style.RecapElement}>
        <div className={style.RecapElementTitle}>
          <LazyImage
            src={iconPath}
            alt={iconAltPath}
            width={"20"}
            height={"20"}
          />
          {label}
          <button
            className={style.EditLink}
            tabIndex={0}
            aria-label={
              label +
              ". selected: " +
              answer?.map((answer) => answer) +
              ". " +
              translate("wcag.wcag_edit")
            }
            onClick={changeStep}
            onKeyDown={(e) =>
              handleOnEnterKeyPress(e, () => changeStep(), true, true)
            }
          >
            <LazyImage
              src={window.rxrEnv?.ASSETS_PATH + "/icons/edit.svg"}
              alt={iconAltPath}
              width={"20"}
              height={"20"}
            />
          </button>
        </div>
        <div className={style.answersContainer}>
          {answer?.map((answer, index) => (
            <Chip key={answer} label={answer} />
          ))}
        </div>
      </div>
    </>
  );
};

export default RecapElement;
