import React, { memo } from "react";
import style from "./style.module.scss";
import LazyImage from "@components/common/LazyImage/LazyImage";
import Button from "../Button/component";
import { clsx } from "@utility/utility";

type InfoBannerProps = {
  className?: string;
  label: string;
  variant: "info" | "warning" | "error";
  description?: string;
  ctaAction?: () => void;
  ctaLabel?: string;
  role?: string;
  tabIndex?: number;
};

const icons = {
  info: window.rxrEnv?.ASSETS_PATH + "/icons/banner/info.svg",
  warning: window.rxrEnv?.ASSETS_PATH + "/icons/banner/warning.svg",
  error: window.rxrEnv?.ASSETS_PATH + "/icons/banner/error.svg",
};

function InfoBanner({
  className = "",
  label = "",
  description = "",
  variant = "info",
  ctaAction,
  ctaLabel,
  role,
  tabIndex,
}: InfoBannerProps) {
  return (
    <div
      className={clsx(style.banner, {
        [className]: !!className,
        [style.warning]: variant === "warning",
        [style.error]: variant === "error",
      })}
      role={role ? role : variant === "error" ? "alert" : ""}
      tabIndex={tabIndex}
    >
      <LazyImage
        className={style.icon}
        src={icons[variant]}
        alt={label}
        width="20px"
        height="20px"
      />

      <div className={style.content}>
        {label && <p className={style.text}>{label}</p>}
        {description && <p className={style.description}>{description}</p>}

        {ctaAction && (
          <Button className={style.cta} variant="underline" onClick={ctaAction}>
            {ctaLabel}
          </Button>
        )}
      </div>
    </div>
  );
}

export default memo(InfoBanner);
