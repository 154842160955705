import stylePageCardMobile from "@components/PageCardMobile/style.module.scss";
import PageStandard from "@components/common/PageStandard/component";
import PageStartTutorial from "@components/common/PageStartTutorial/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import { AcuityTestAnswers } from "@model/SurveyModel";
import { selectAcuityTestResults } from "@slices/acuitySlice";
import { selectConfig } from "@slices/configSlice";
import { selectTBTInfo, updateTBTPayload } from "@slices/surveySlice";
import { utilityActions } from "@slices/utilitySlice";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";
import { printHTML } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import { sessionStorageIds } from "@utility/sessionStorageUtility";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";

type Props = {};

function AcuityEndPage(props: Props) {
  const navigate = useNavigate();
  const translate = useTranslation();
  const breakpoint = useBreakpointBoolean();
  const config = useSelector(selectConfig);
  const surveySubmittedHere =
    sessionStorage.getItem(sessionStorageIds.SURVEY_SUBMITTED_HERE) === "true"
      ? true
      : false;
  const acuityTestResults = useSelector(selectAcuityTestResults);
  const TBTInfo = useSelector(selectTBTInfo);
  const dispatch = useDispatch();

  // const [showModalLeave, setShowModalLeave] = useState(false);

  useEffect(() => {
    dispatch(utilityActions.setAcuityEnd({ value: true }));
  }, []);

  const submitResultCTA = () => {
    //If we're on mobile and the survey has been submitted on desktop (!surveySubmittedHere), we must ask if user wants to return to desktop or stay here
    if (!surveySubmittedHere && breakpoint.isPhoneOrTabletPortrait) {
      navigate(ROUTING.BEFORE_YOU_CONTINUE.url);
    } else {
      let newPayload = { ...TBTInfo.payload };
      newPayload.status = "ACUITY_END";
      dispatch(updateTBTPayload({ tokenId: TBTInfo.TBT, payload: newPayload }));
      navigate(ROUTING.CURRENT_PRESCRIPTION.url);
    }
  };

  // const skipSubmissionCTA = () => {
  //   setShowModalLeave(true);
  // };

  useEffect(() => {
    //updating TBT payload with Acuity Test Results
    if (acuityTestResults?.left && acuityTestResults?.right) {
      let acuityTest: AcuityTestAnswers = {
        step1: {
          t_id: "step1",
          t_label: translate("acuity.connect_label_6_10"),
          t_answer: {
            OD: {
              result:
                acuityTestResults.right[6]?.filter((value) => value === true)
                  .length === 3
                  ? "OK"
                  : "KO",
              score: !!acuityTestResults.right[6]
                ? acuityTestResults.right[6].filter((value) => value === true)
                    .length +
                  "/" +
                  acuityTestResults.right[6].length
                : "0/0",
            },
            OS: {
              result:
                acuityTestResults.left[6]?.filter((value) => value === true)
                  .length === 3
                  ? "OK"
                  : "KO",
              score: !!acuityTestResults.left[6]
                ? acuityTestResults.left[6].filter((value) => value === true)
                    .length +
                  "/" +
                  acuityTestResults.left[6].length
                : "0/0",
            },
          },
        },
        step2: {
          t_id: "step2",
          t_label: translate("acuity.connect_label_8_10"),
          t_answer: {
            OD: {
              result:
                acuityTestResults.right[8]?.filter((value) => value === true)
                  .length === 3
                  ? "OK"
                  : "KO",
              score: !!acuityTestResults.right[8]
                ? acuityTestResults.right[8].filter((value) => value === true)
                    .length +
                  "/" +
                  acuityTestResults.right[8].length
                : "0/0",
            },
            OS: {
              result:
                acuityTestResults.left[8]?.filter((value) => value === true)
                  .length === 3
                  ? "OK"
                  : "KO",
              score: !!acuityTestResults.left[8]
                ? acuityTestResults.left[8].filter((value) => value === true)
                    .length +
                  "/" +
                  acuityTestResults.left[8].length
                : "0/0",
            },
          },
        },
        step3: {
          t_id: "step3",
          t_label: translate("acuity.connect_label_10_10"),
          t_answer: {
            OD: {
              result:
                acuityTestResults.right[10]?.filter((value) => value === true)
                  .length === 3
                  ? "OK"
                  : "KO",
              score: !!acuityTestResults.right[10]
                ? acuityTestResults.right[10].filter((value) => value === true)
                    .length +
                  "/" +
                  acuityTestResults.right[10].length
                : "0/0",
            },
            OS: {
              result:
                acuityTestResults.left[10]?.filter((value) => value === true)
                  .length === 3
                  ? "OK"
                  : "KO",
              score: !!acuityTestResults.left[10]
                ? acuityTestResults.left[10].filter((value) => value === true)
                    .length +
                  "/" +
                  acuityTestResults.left[10].length
                : "0/0",
            },
          },
        },
      };

      const newPayload = {
        ...TBTInfo.payload,
        tests: acuityTest,
      };

      console.log("Saving acuity test in TBT payload", newPayload);

      dispatch(
        updateTBTPayload({
          tokenId: TBTInfo.TBT,
          payload: newPayload,
        })
      );
    }
  }, []);

  return (
    <>
      {breakpoint.isPhone ? (
        <PageStartTutorial
          title={translate("test_complete.title")}
          descriptions={[
            translate("test_complete.description").replace(
              "{{prescriptionCost}}",
              config.config.prescriptionCost
            ),
          ]}
          buttonAction={submitResultCTA}
          buttonLabelKey="test_complete.cta"
          analyticsInfoButton={{
            "data-element-id": "RxRenewal_ResultValidation-SubmitResults",
            "data-description": formatAnalyticsInnerText(
              translate("test_complete.cta")
            ),
            "data-analytics_available_call": 1,
          }}
          // otherElements={
          //   skip button eliminato per CR https://luxotticaretail.atlassian.net/browse/RXRENEWAL-215
          //   <Button
          //     className={style.footerInlineButtonLabel}
          //     variant="transparent"
          //     onClick={skipSubmissionCTA}
          //     analyticsInfo={{
          //       "data-element-id": "RxRenewal_ResultValidation-SkipSubmission",
          //       "data-description": formatAnalyticsInnerText(
          //         translate("test_complete.cta_secondary")
          //       ),
          //       "data-analytics_available_call": 1,
          //     }}
          //   >
          //     {translate("test_complete.cta_secondary")}
          //   </Button>
          // }
        />
      ) : (
        <PageStandard
          className={`${stylePageCardMobile.pageCardMobile} ${style.AcuityEndPage}`}
          classNamePageBody={stylePageCardMobile.pageCardMobileBody}
          footerInfo={{
            // left button eliminato per CR https://luxotticaretail.atlassian.net/browse/RXRENEWAL-215
            // showLeftButton: true,
            // labelLeftButton: translate("test_complete.cta_secondary"),
            // actionLeftButton: skipSubmissionCTA,
            // classNameLeftButton: style.leftButton,
            // analyticsInfoLeftButton: {
            //   "data-element-id": "RxRenewal_ResultValidation-SkipSubmission",
            //   "data-description": formatAnalyticsInnerText(
            //     translate("test_complete.cta_secondary")
            //   ),
            //   "data-analytics_available_call": 1,
            // },
            // variantLeftButton: "underline",
            showRightButton: true,
            labelRightButton: translate("test_complete.cta"),
            actionRightButton: submitResultCTA,
            analyticsInfoRightButton: {
              "data-element-id": "RxRenewal_ResultValidation-SubmitResults",
              "data-description": formatAnalyticsInnerText(
                translate("test_complete.cta")
              ),
              "data-analytics_available_call": 1,
            },
            backgroundWhite: true,
          }}
          showBackgroundVideo={true}
        >
          <div
            className={`${stylePageCardMobile.pageBodyCard} ${style.AcuityEndPageContent}`}
          >
            <p
              className={`${stylePageCardMobile.title} ${style.title}`}
              dangerouslySetInnerHTML={printHTML(
                translate("test_complete.title")
              )}
            />
            <p
              className={`${stylePageCardMobile.description} ${style.description}`}
              dangerouslySetInnerHTML={printHTML(
                translate("test_complete.description").replace(
                  "{{prescriptionCost}}",
                  config.config.prescriptionCost
                )
              )}
            />
          </div>
        </PageStandard>
      )}

      {/* 
        // modale eliminata per CR https://luxotticaretail.atlassian.net/browse/RXRENEWAL-215
      {showModalLeave && (
        <ModalCheck
          isOpen={showModalLeave}
          icon="exclamation"
          message={translate("test_complete.modal_skip_title")}
          description={translate("test_complete.modal_skip_description")}
          ctaPrimaryLabel={translate("test_complete.modal_skip_cta1")}
          ctaPrimaryAction={() => {
            dispatch(
              utilityAsyncActions.invokeEcommerceFunction({
                functionId: "updateAssessmentStatus",
                finallyCallback: () => {
                  exitRxr(config.config?.headerBackLinkUrl);
                },
              })
            );
          }}
          ctaLabel={translate("test_complete.modal_skip_cta2")}
          ctaAction={() => {
            if (config.config.findDoctorLink) {
              dispatch(
                utilityAsyncActions.invokeEcommerceFunction({
                  functionId: "updateAssessmentStatus",
                  finallyCallback: () => {
                    exitRxr(config.config.findDoctorLink);
                  },
                })
              );
            } else {
              const errorLabel = "findDoctorLink not found";
              console.error(errorLabel, {
                config: config.config,
              });
              dispatch(
                utilityActions.setShowErrorPopup({
                  showErrorPopup: true,
                  analyticsInfo: {
                    Error_Source: "Client",
                    Error_Details:
                      errorLabel +
                      "\n --- \n" +
                      JSON.stringify(config.config || {}),
                  },
                })
              );
            }
          }}
        />
      )} */}
    </>
  );
}

export default AcuityEndPage;
