import React, { memo } from "react";
import style from "./style.module.scss";

type OrSeparatorProps = {
  label: string;
  className?: string;
};

function OrSeparator({ label, className = "" }: OrSeparatorProps) {
  return (
    <div className={`${style.OrSeparator} ${className}`}>
      <hr />
      {label !== "" && (
        <>
          <span>{label}</span>
          <hr />
        </>
      )}
    </div>
  );
}

export default memo(OrSeparator);
