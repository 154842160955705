import { useCallback } from "react";
import { LabelMapType, selectLabelMap } from "@slices/utilitySlice";
import { useSelector } from "react-redux";

/**
 * USAGE EXAMPLE
 * const translate = useTranslation();
 * translate("wcag.wcag_right_arrow_icon")
 *
 * @returns a function to retrieve the label from the key
 */

export const useTranslation = () => {
  const labelMap = useSelector(selectLabelMap);

  const translate = useCallback(
    (key: string, returnKey: boolean = false): string => {
      return getLabelFromLabelMap(labelMap, key, returnKey);
    },
    [labelMap]
  );

  return translate;
};

export const getLabelFromLabelMap = (
  labelMap: LabelMapType,
  key: string,
  returnKey: boolean = false
) => {
  if (!labelMap || Object.keys(labelMap)?.length === 0 || !key) {
    return "";
  }

  if (returnKey) {
    return key;
  }

  const keys = key?.split(".");

  let label = "";
  let labelTemp: string | typeof labelMap = labelMap;
  keys.forEach((key) => {
    if (labelTemp) {
      labelTemp = labelTemp[key];
    }
  });

  label = typeof labelTemp === "string" ? labelTemp || "" : "";

  return label;
};
