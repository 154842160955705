import React from "react";
import style from "./style.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import PageStandard from "@components/common/PageStandard/component";

function FallbackError() {
  const translate = useTranslation();

  return (
    <PageStandard>
      <p className={style.fallbackTitle}>
        {translate("error.message") || "Something went wrong"}
      </p>
    </PageStandard>
  );
}

export default FallbackError;
