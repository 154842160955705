import ModalCheck from "@components/common/ModalCheck/component";
import { useTranslation } from "@hooks/useTranslation";
import { useUtag } from "@hooks/useUtag";
import { selectConfig } from "@slices/configSlice";
import {
  selectAnalyticsInfoErrorPopup,
  selectErrorModalRetryCloseModal,
  utilityActions,
  utilityAsyncActions,
} from "@slices/utilitySlice";
import { cleanSessionStorage } from "@utility/sessionStorageUtility";
import { exitRxr } from "@utility/utility";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";

function ErrorModal() {
  const translate = useTranslation();
  const config = useSelector(selectConfig);
  const errorModalRetryCloseModal = useSelector(
    selectErrorModalRetryCloseModal
  );
  const analyticsInfoErrorPopup = useSelector(selectAnalyticsInfoErrorPopup);
  const { sendAnalytics } = useUtag({ sendAtEveryPage: false });
  const dispatch = useDispatch();

  const retryCTAAction = () => {
    if (errorModalRetryCloseModal) {
      dispatch(utilityActions.setShowErrorPopup({ showErrorPopup: false }));
    } else {
      history.go(0);
    }
  };

  useEffect(() => {
    sendAnalytics("ERROR_EVENT", [""], {
      Events_Error: "1",
      Error_Source: "Server",
      Error_Code: "Generic Server Error",
      Error_Details: "Generic Server Error",
      Error_Message:
        translate("error.description") ||
        "Something went wrong, please retry to proceed with your Prescription renewal.",
      ...analyticsInfoErrorPopup,
    });

    if (!errorModalRetryCloseModal) {
      cleanSessionStorage();
    }
  }, []);

  return (
    <ModalCheck
      className={style.errorModal}
      classNameBody={style.errorModalBody}
      isOpen={true}
      icon="error"
      message={translate("error.title")}
      description={translate("error.description")}
      ctaPrimaryLabel={translate("error.retry_bottom_label")}
      ctaPrimaryAction={retryCTAAction}
      ctaLabel={translate("error.leave_bottom_label")}
      ctaAction={() => {
        dispatch(
          utilityAsyncActions.invokeEcommerceFunction({
            functionId: "updateAssessmentStatus",
            finallyCallback: () => {
              exitRxr(config.config?.headerBackLinkUrl);
            },
          })
        );
      }}
    />
  );
}

export default ErrorModal;
