import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageStandard from "@components/common/PageStandard/component";
import { useTranslation } from "@hooks/useTranslation";
import style from "./style.module.scss";
import stylePageCardMobile from "../../components/PageCardMobile/style.module.scss";
import { printHTML } from "@utility/htmlUtility";
import { useLandoltC } from "@hooks/useLandoltC";
import ModalDetectDevice from "@components/common/ModalDetectDevice/component";
import SelectList from "@components/SelectList/component";
import { useForm } from "react-hook-form";
import { ROUTING } from "@utility/routingUtility";
import InfoBanner from "@components/common/InfoBanner/component";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type SetupCalibrateScreenPageProps = {};

function SetupCalibrateScreenPage(props: SetupCalibrateScreenPageProps) {
  const navigate = useNavigate();
  const translate = useTranslation();
  const { needManualCalibration, storeCInfo } = useLandoltC(10, false);

  let tutorialStep = useParams()?.tutorialStep;

  const onSubmit = () => {
    if (selection === "yes") {
      //store iphone 12 mini / iphone 13 mini ppi in redux
      storeCInfo(496, true);
    } else {
      //store iphone 11 pro / iphone XS / iphone X ppi in redux
      storeCInfo(458, true);
    }

    //go to next setup step
    navigate(
      ROUTING.SETUP_STEPS.url.replace(
        ":tutorialStep",
        ((+tutorialStep || 1) + 1).toString()
      )
    );
  };

  const question = {
    idFE: "calibrationIphoneMini",
    answers: [
      {
        idFE: "yes",
        title: translate("setup.step1_calibratescreen_placeholder1"),
      },
      {
        idFE: "no",
        title: translate("setup.step1_calibratescreen_placeholder2"),
      },
    ],
  };

  const formData = useForm<{ calibrationIphoneMini: string }>({
    mode: "onChange",
  });
  const selection = formData?.watch("calibrationIphoneMini");

  return (
    <PageStandard
      className={`${style.setupCalibrateScreen} ${stylePageCardMobile.pageCardMobile}`}
      classNamePageBody={stylePageCardMobile.pageCardMobileBody}
      isLoading={needManualCalibration === "loading"}
      footerInfo={{
        className:
          style.footer +
          " " +
          (needManualCalibration === "loading" ? style.none : ""),
        showLeftButton: true,
        classNameLeftButton: stylePageCardMobile.footerLeftButton,
        iconLeftButton: "leftArrow",
        labelLeftButton: "",
        analyticsInfoLeftButton: {
          "data-element-id": "RxRenewal_SetUp-Back",
          "data-analytics_available_call": 0,
        },
        showRightButton: true,
        classNameRightButton: stylePageCardMobile.footerRightButton,
        labelRightButton: translate("setup.step1_calibratescreen_footer"),
        actionRightButton: onSubmit,
        disabledRightButton: !formData?.formState?.isValid,
        analyticsInfoRightButton: {
          "data-element-id": "RxRenewal_SetUp-Confirm",
          "data-description": formatAnalyticsInnerText(
            translate("setup.step1_calibratescreen_footer")
          ),
          "data-analytics_available_call": 1,
        },
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div className={stylePageCardMobile.overPageContainer}>
        <p className={stylePageCardMobile.overPageTitle}>
          {translate("setup.step_title")}
        </p>
        <p
          className={stylePageCardMobile.overPageDescription}
          dangerouslySetInnerHTML={printHTML(
            translate("setup.step_stepper")
              ?.replace("{{from}}", tutorialStep)
              .replace("{{to}}", "3")
          )}
        />
      </div>

      <div className={stylePageCardMobile.pageBodyCard}>
        <p
          className={stylePageCardMobile.title}
          dangerouslySetInnerHTML={printHTML(
            translate("setup.step1_calibration_title")
          )}
        />
        <p
          className={`${stylePageCardMobile.description} ${style.description}`}
          dangerouslySetInnerHTML={printHTML(
            translate("setup.step1_calibratescreen_descr")
          )}
        />

        <div className={style.selectionContainer}>
          <SelectList
            question={question}
            formData={formData}
            optional={false}
            border={false}
          />
        </div>

        <InfoBanner
          label={translate("setup.step1_calibratescreen_info_banner")}
          variant="info"
        />
      </div>

      <ModalDetectDevice />
    </PageStandard>
  );
}

export default SetupCalibrateScreenPage;
