import React from "react";
import style from "./style.module.scss";
import { useLandoltC } from "@hooks/useLandoltC";
import { printHTML } from "@utility/htmlUtility";

export type COrientationsType =
  | "right-right"
  | "bottom-right"
  | "bottom-bottom"
  | "bottom-left"
  | "left-left"
  | "top-left"
  | "top-top"
  | "top-right";

type LandoltCRingProps = {
  cSizeVa: 4 | 6 | 8 | 10;
  className?: string;
  classNameDots?: string;
  classNameLandoltC?: string;
  useDotContent?: boolean;
  dotsConfiguration?: {
    [key: COrientationsType]: {
      onClick: (dotId: string) => void;
      className: string;
    };
  };
};

function LandoltCRing({
  cSizeVa = 10,
  className = "",
  classNameDots = "",
  classNameLandoltC = "",
  useDotContent = false,
  dotsConfiguration = {},
}: LandoltCRingProps) {
  const { cSize, cSVG } = useLandoltC(cSizeVa, true);

  const dots = [
    { id: "right-right", className: style.dotRightRight },
    { id: "bottom-right", className: style.dotBottomRight },
    { id: "bottom-bottom", className: style.dotBottomBottom },
    { id: "bottom-left", className: style.dotBottomLeft },
    { id: "left-left", className: style.dotLeftLeft },
    { id: "top-left", className: style.dotTopLeft },
    { id: "top-top", className: style.dotTopTop },
    { id: "top-right", className: style.dotTopRight },
  ];

  if (!cSVG) {
    return null;
  }

  return (
    <div
      className={`${style.landoltCRing} ${className}`}
      style={{ "--cSize": cSize }}
      key={cSize}
    >
      <div className={`${style.dots} ${classNameDots}`}>
        {dots.map((dot) => {
          const dotConf = dotsConfiguration?.[dot.id] || {};

          return (
            <div
              key={dot.id}
              className={`${style.dot} ${dot.className} ${
                dotConf.className ? dotConf.className : ""
              }`}
              onClick={
                dotConf?.onClick ? () => dotConf.onClick(dot.id) : undefined
              }
            >
              {!!dotConf.dotContent && useDotContent && dotConf.dotContent(dot)}
            </div>
          );
        })}
      </div>

      <div
        dangerouslySetInnerHTML={printHTML(cSVG)}
        className={`${style.landoltC} ${classNameLandoltC}`}
      />
    </div>
  );
}

export default LandoltCRing;
