import React, { useEffect } from "react";
import style from "./style.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import { useDispatch, useSelector } from "react-redux";
import PageStandard from "@components/common/PageStandard/component";
import { selectLabelInfo } from "@slices/utilitySlice";
import Tooltip from "@components/common/Tooltip/component";
import { useLandoltC } from "@hooks/useLandoltC";
import VideoTutorial from "@components/common/VideoTutorial/component";
import { acuityActions, selectEnableNextButtonMap } from "@slices/acuitySlice";
import ModalDetectDevice from "@components/common/ModalDetectDevice/component";
import LandoltCRing from "@components/LandoltCRing/component";
import { useGetSetupVideos } from "@hooks/useGetSetupVideos";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type Props = {};

function AcuityTutorialPage(props: Props) {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const navigate = useNavigate();
  const isLabelLoading = useSelector(selectLabelInfo)?.isLoading;
  const { cSVG } = useLandoltC(4, true);
  const enableNextButtonMap = useSelector(selectEnableNextButtonMap);
  const setupVideos = useGetSetupVideos("training");

  const lastStepNum = 6;
  const classNameSteps = {
    0: style.acuityTutorialStep0,
    1: style.acuityTutorialStep1,
    2: style.acuityTutorialStep2,
    3: style.acuityTutorialStep3,
    4: style.acuityTutorialStep4,
    5: style.acuityTutorialStep5,
    6: style.acuityTutorialStep6,
  };

  let tutorialStep = useParams()?.tutorialStep;

  useEffect(() => {
    if (!tutorialStep || +tutorialStep < 0 || +tutorialStep > lastStepNum) {
      changeTutorialStep(0, true);
    }
  }, [!!tutorialStep]);

  useEffect(() => {
    if (+tutorialStep === lastStepNum) {
      setTimeout(() => {
        //re-check the condition because the user may have changed the step (e.g. using the back button)
        if (
          +tutorialStep === lastStepNum &&
          window.location.href?.endsWith(lastStepNum?.toString())
        ) {
          navigate(ROUTING.ACUITY_START.url);
        }
      }, 7000);
    }
  }, [tutorialStep]);

  const changeTutorialStep = (
    tutorialStepId: number,
    replace: boolean = false
  ) => {
    navigate(
      ROUTING.ACUITY_TUTORIAL.url.replace(
        ":tutorialStep?",
        tutorialStepId?.toString() || ""
      ),
      {
        replace: replace,
        state: replace ? { isReplace: true } : undefined,
      }
    );
  };

  const goToNextStep = () => {
    if (+tutorialStep < lastStepNum) {
      changeTutorialStep(+tutorialStep + 1);
    }
  };

  const bottomDotConfiguration = { className: style.dot };

  const bottomDotsConfiguration = {
    "right-right": bottomDotConfiguration,
    "bottom-right": bottomDotConfiguration,
    "bottom-bottom": bottomDotConfiguration,
    "bottom-left": bottomDotConfiguration,
    "left-left": bottomDotConfiguration,
    "top-left": bottomDotConfiguration,
    "top-top": bottomDotConfiguration,
    "top-right": bottomDotConfiguration,
  };

  const topDotsConfiguration = {
    "right-right": bottomDotConfiguration,
    "bottom-right": {
      className: style.dot + " " + style.dotBottomRight,
      onClick: () => {
        if (+tutorialStep === 3) {
          goToNextStep();
        }
      },
    },
    "bottom-bottom": bottomDotConfiguration,
    "bottom-left": bottomDotConfiguration,
    "left-left": bottomDotConfiguration,
    "top-left": bottomDotConfiguration,
    "top-top": bottomDotConfiguration,
    "top-right": bottomDotConfiguration,
  };

  return (
    <PageStandard
      className={`${style.acuityTutorial} ${
        isLabelLoading ? "" : classNameSteps[+tutorialStep]
      }`}
      classNamePage={style.acuityTutorialPage}
      isLoading={!cSVG}
      footerInfo={{
        className: style.footer,
        showLeftButton: true,
        classNameLeftButton: style.footerLeftButton,
        iconLeftButton: "leftArrow",
        labelLeftButton: "",
        analyticsInfoLeftButton: {
          "data-element-id": "RxRenewal_TutorialTest-Back",
          "data-analytics_available_call": 0,
        },
        showRightButton: true,
        classNameRightButton: style.footerRightButton,
        labelRightButton: translate("footer.next"),
        actionRightButton: goToNextStep,
        disabledRightButton:
          +tutorialStep === 0 && !enableNextButtonMap["acuity_tutorial_0"],
        analyticsInfoRightButton: {
          "data-element-id": "RxRenewal_TutorialTest-Next",
          "data-description": formatAnalyticsInnerText(
            translate("footer.next")
          ),
          "data-analytics_available_call": 0,
        },
      }}
      showHeader={false}
    >
      <div className={`${style.landoltContainer}`}>
        <LandoltCRing
          cSizeVa={4}
          className={style.landoltCRingBottom}
          classNameDots={style.dots}
          classNameLandoltC={style.landoltC}
          dotsConfiguration={bottomDotsConfiguration}
        />
        <LandoltCRing
          cSizeVa={4}
          className={style.landoltCRingUpper}
          classNameDots={style.dots}
          classNameLandoltC={style.landoltC}
          useDotContent={true}
          dotsConfiguration={topDotsConfiguration}
        />

        {+tutorialStep === 0 && (
          <VideoTutorial
            id={"acuity_tutorial_0"}
            key={tutorialStep}
            classNameVideoContainer={style.videoContainer}
            src={setupVideos[0]?.uri}
            audio={""}
            ariaLabel={""}
            width={"100%"}
            height={"auto"}
            autoPlay={true}
            controls={false}
            playsInline={true}
            // audio={currentStep.audio}
            onEnded={() => {
              if (!enableNextButtonMap["acuity_tutorial_0"]) {
                dispatch(
                  acuityActions.enableNextButton({
                    idToEnable: "acuity_tutorial_0",
                  })
                );
              }
            }}
          />
        )}

        {+tutorialStep === 1 && (
          <Tooltip className={`${style.tooltip} ${style.step1Tooltip}`}>
            {translate("acuity_tutorial.step1_tooltip")}
          </Tooltip>
        )}
        {+tutorialStep === 2 && (
          <Tooltip className={`${style.tooltip} ${style.step2Tooltip}`}>
            {translate("acuity_tutorial.step2_tooltip")}
          </Tooltip>
        )}

        {+tutorialStep === 3 && (
          <Tooltip
            className={`${style.tooltip} ${style.step3Tooltip}`}
            classNameContent={style.step3TooltipContent}
            classNameTail={style.step3TooltipTail}
          >
            {translate("acuity_tutorial.step3_tooltip")}
          </Tooltip>
        )}

        {+tutorialStep === 4 && (
          <>
            <div className={style.step4dotBorder} />
            <div className={style.step4Background} />
            <div className={`${style.step4DotBlue} ${style.step4DotBlue1}`} />
            <div className={`${style.step4DotBlue} ${style.step4DotBlue2}`} />
            <div className={`${style.step4DotBlue} ${style.step4DotBlue3}`} />
            <Tooltip className={`${style.tooltip} ${style.step4Tooltip}`}>
              {translate("acuity_tutorial.step4_tooltip")}
            </Tooltip>
          </>
        )}

        {+tutorialStep === 5 && (
          <Tooltip
            className={`${style.tooltip} ${style.step5Tooltip}`}
            classNameContent={style.step5TooltipContent}
            classNameTail={style.step5TooltipTail}
          >
            {translate("acuity_tutorial.step5_tooltip")}
          </Tooltip>
        )}

        <div className={`${style.blurLayer}`} />
      </div>

      <ModalDetectDevice />
    </PageStandard>
  );
}

export default AcuityTutorialPage;
