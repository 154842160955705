import { matchPath } from "react-router-dom";
import { ROUTING } from "./routingUtility";
import { removeHTMLtags } from "./htmlUtility";

export const initializeUtag = () => {
  if (window.location.hostname !== "localhost") {
    //add tealium_data2track initialization
    let scriptTealiumData2Track = document.createElement("script");
    scriptTealiumData2Track.type = "application/javascript";
    scriptTealiumData2Track.innerHTML = `
      var tealium_data2track = [];
    `;
    document.head?.appendChild(scriptTealiumData2Track);

    //add utag_data initialization
    let scriptUtagData = document.createElement("script");
    scriptUtagData.type = "application/javascript";
    scriptUtagData.innerHTML = `
      var utag_data = {
        Page_Platform: "WHITELABEL_RXR",
        Page_Brand: 'CD',
        Page_DeviceType: "X",
        Page_Country: "US",
        Page_Language: "EN",
        Page_Type: "Home",
        Page_Section1: "Home",
        Page_Section2: "",
        Order_Currency: "USD",
        Page_Server: 'Test',
        User_LoginType: 'Standard',
        User_LoginStatus: 'Logged',
        User_Email_MD5: 'de1b522c7f8e37cebfe86c576b64a743',
        User_Email_SHA256: 'C41D1FB12FA9821012EF4D056BDA7B3603FBF002AC804C717D2C992189DB0482',
        User_Country: 'US',
        User_Id: 'n123',
        User_Segments: 'Super'
      }
    `;
    document.body?.appendChild(scriptUtagData);

    //add utag_data initialization
    let scriptUtagImport = document.createElement("script");
    scriptUtagImport.type = "application/javascript";
    scriptUtagImport.innerHTML = `
      <!-- Loading Tealium library script asynchronously -->
      (function(a, b, c, d) {
        a = '//tags.tiqcdn.com/utag/luxottica/ray-ban/qa/utag.js';
        b = document;
        c = 'script';
        d = b.createElement(c);
        d.src = a;
        d.type = 'text/java' + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
    `;
    document.body?.appendChild(scriptUtagImport);
  }
};

const UTAG_PAGE_DATA = {
  START_AND_DISCALIMERS: {
    id: "VirtualPage-View",
    Page_Section1: "RxRenewal",
    Page_Section2: "Start",
    Page_Type: "Static",
  },
  ELIGIBILITY_CHECK: {
    id: "VirtualPage-View",
    Page_Section1: "RxRenewal",
    Page_Section2: "Eligibility",
    Page_Type: "Static",
  },
  ELIGIBILITY_OK: {
    id: "VirtualPage-View",
    Page_Section1: "RxRenewal",
    Page_Section2: "Eligibility:Ok",
    Page_Type: "Static",
    Events_RenewalEligibility: "1",
  },
  ELIGIBILITY_KO: {
    id: "Impression",
    Page_Section1: "RxRenewal",
    Page_Section2: "Eligibility:Ko",
  },
  SETUP: {
    id: "VirtualPage-View",
    Page_Section1: "RxRenewal",
    Page_Section2: "SetUp",
    Page_Type: "Static",
  },
  TUTORIAL: {
    id: "VirtualPage-View",
    Page_Section1: "RxRenewal",
    Page_Section2: "TutorialTest",
    Page_Type: "Static",
  },
  ACUITY_TEST: {
    id: "VirtualPage-View",
    Page_Section1: "RxRenewal",
    Page_Section2: "Test",
    Page_Type: "Static",
  },
  RESULT_VALIDATION: {
    id: "VirtualPage-View",
    Page_Section1: "RxRenewal",
    Page_Section2: "ResultValidation",
    Page_Type: "Static",
  },
  RESULT_VALIDATION_OK: {
    id: "VirtualPage-View",
    Page_Section1: "RxRenewal",
    Page_Section2: "ResultValidation:Ok",
    Page_Type: "Static",
  },
  ERROR_EVENT: {
    id: "Event",
  },
};

//analytics are not going to be sent for all the Rxr pages
const UTAG_PAGE_DATA_MAPPED = {
  START_1: UTAG_PAGE_DATA.START_AND_DISCALIMERS,
  // START_2: UTAG_PAGE_DATA.START_AND_DISCALIMERS,
  SURVEY_START: UTAG_PAGE_DATA.ELIGIBILITY_CHECK,
  // SURVEY: UTAG_PAGE_DATA.ELIGIBILITY_CHECK,
  // SURVEY_ERROR: UTAG_PAGE_DATA.ELIGIBILITY_KO, //survey error page is handled inside SurveyErrorPage.tsx component
  // LEGAL_DISCLAIMER: UTAG_PAGE_DATA.SETUP,
  SETUP_START: UTAG_PAGE_DATA.SETUP,
  // SETUP_STEPS: UTAG_PAGE_DATA.SETUP,
  // SETUP_CALIBRATE_SCREEN: UTAG_PAGE_DATA.SETUP,
  // SETUP_CALIBRATION_RULER: UTAG_PAGE_DATA.SETUP,
  ACUITY_TUTORIAL_START: UTAG_PAGE_DATA.TUTORIAL,
  // ACUITY_TUTORIAL: UTAG_PAGE_DATA.TUTORIAL,
  ACUITY_START: UTAG_PAGE_DATA.ACUITY_TEST,
  // ACUITY_VIDEO: UTAG_PAGE_DATA.ACUITY_TEST,
  // ACUITY_TEST: UTAG_PAGE_DATA.ACUITY_TEST,
  // ACUITY_WRONG: UTAG_PAGE_DATA.ACUITY_TEST,
  // ACUITY_END: UTAG_PAGE_DATA.RESULT_VALIDATION,
  BEFORE_YOU_CONTINUE: UTAG_PAGE_DATA.RESULT_VALIDATION,
  // CURRENT_PRESCRIPTION: UTAG_PAGE_DATA.RESULT_VALIDATION,
  // COMMENT: UTAG_PAGE_DATA.RESULT_VALIDATION,
};

export const getUtagStaticInfoPage = (
  urlParam?: string,
  analyticsKey?: string
) => {
  const url = urlParam || window.location.pathname;

  if (analyticsKey) {
    return UTAG_PAGE_DATA[analyticsKey];
  } else {
    const keyRoute = Object.keys(ROUTING).find((key) =>
      matchPath(ROUTING[key].pattern || ROUTING[key].url, url)
    );
    if (!keyRoute) {
      return {};
    }
    return UTAG_PAGE_DATA_MAPPED[keyRoute];
  }
};

export const formatAnalyticsInnerText = (text: string) => {
  if (!text) {
    return "";
  }

  return (
    removeHTMLtags(text)
      .toLowerCase()
      //first character to upper case
      .replace(/^[a-z]/, (m) => m.toUpperCase())
      //transform text in camel case and remove special characters
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  );
};
