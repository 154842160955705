import { useUtag } from "@hooks/useUtag";
import React, { useEffect } from "react";

type ErrorBoundaryProps = {
  children: React.ReactNode;
  fallback: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState & { error: any; info: any }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("ErrorBoundary", {
      error,
      componentStack: info.componentStack,
    });
    this.setState({ error, info });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <AnalyticsFunctionComponent
            error={this.state.error}
            info={this.state.info}
          />
          {this.props.fallback}
        </>
      );
    }

    return this.props.children;
  }
}

const AnalyticsFunctionComponent = ({ error, info }) => {
  const { sendAnalytics } = useUtag({ sendAtEveryPage: false });

  useEffect(() => {
    if (error) {
      sendAnalytics("ERROR_EVENT", [""], {
        Events_Error: "1",
        Error_Source: "Client",
        Error_Code: "Error in JS execution",
        Error_Details: error.stack + "\n --- \n" + info?.componentStack,
        Error_Message: "Something went wrong",
      });
    }
  }, [!!error]);

  return null;
};

export default ErrorBoundary;
