import React, { memo, useState } from "react";
import style from "./style.module.scss";
import { clsx } from "@utility/utility";
import { useTranslation } from "@hooks/useTranslation";
import LazyImage from "../LazyImage/LazyImage";

type TextareaProps = {
  placeholderKey?: string;
  className?: string;
  classNameContainer?: string;
  value: string;
  onChange: (value: string) => void;
  maxLength?: number;
};
const Textarea = ({
  className = "",
  classNameContainer = "",
  placeholderKey = "",
  value = "",
  onChange = () => {},
  maxLength = 1000,
}: TextareaProps) => {
  const translate = useTranslation();

  //used to prevent Safari zooming at input focus
  const [fontTo16px, setFontTo16px] = useState(false);

  return (
    <div
      className={clsx(style.textareaContainer, {
        [classNameContainer]: !!classNameContainer,
      })}
    >
      <textarea
        className={clsx(style.textarea, {
          [className]: !!className,
          [style.withValue]: !!value,
        })}
        placeholder={translate(placeholderKey) || ""}
        value={value}
        onChange={(e) => onChange(e?.target?.value)}
        maxLength={maxLength}
        cols={100}
        onBlur={() => setFontTo16px(false)}
        style={{ fontSize: fontTo16px ? "16px" : undefined }}
        onTouchStart={() => setFontTo16px(true)}
      />
      <LazyImage
        className={style.grabber}
        src={window.rxrEnv?.ASSETS_PATH + "/icons/textarea-grabber.svg"}
        width="18px"
        height="18px"
        alt=""
      />
    </div>
  );
};

export default memo(Textarea);
