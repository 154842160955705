import { TutorialVideosCMSResponse } from "@model/SetupVideosModel";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getEndpoint, getHeaders, mockService } from "@utility/networkUtility";
import { sessionStorageIds } from "@utility/sessionStorageUtility";
import { setCSSVariable } from "@utility/utility";
import axios from "axios";
import heic2any from "heic2any";
import { RootState } from "../Store";
import { utilityActions } from "./utilitySlice";

export type SetupVideoData = {
  tutorial: SetupVideoType[];
  training: SetupVideoType[];
  "acuity-dx": SetupVideoType[];
  "acuity-sx": SetupVideoType[];
};

export type SetupVideoType = {
  uri: string;
  type: "video" | "audio";
};

export type cSizeMapType = {
  10?: number;
  8?: number;
  6?: number;
  4?: number;
};

export type AcuityTestResultSingleEye = {
  10?: boolean[];
  8?: boolean[];
  6?: boolean[];
};

export type AcuityTestResults = {
  left: AcuityTestResultSingleEye;
  right: AcuityTestResultSingleEye;
};

export type CurrentPrescriptionType = {
  kind: "photo" | "document";
  file: File;
};

export type StateAcuity = {
  setupVideosInfo: {
    isLoading: boolean;
    language: string;
    country: string;
    data: SetupVideoData;
  };
  enableNextButtonMap: { [key: string]: boolean };
  cSizeMap: cSizeMapType;
  calibratedManually: boolean;
  cSVG: string;
  needManualCalibration: "loading" | "true" | "false" | "mini";
  acuityTestResults: AcuityTestResults;
  currentPrescriptionIsLoading: boolean;
  currentPrescription: CurrentPrescriptionType;
  commentIsLoading: boolean;
  comment: string;
};

const initialState: StateAcuity = {
  setupVideosInfo: {
    isLoading: false,
    language: "",
    country: "",
    data: { tutorial: [], training: [], "acuity-dx": [], "acuity-sx": [] },
  },
  enableNextButtonMap: {},
  cSizeMap: {},
  calibratedManually: false,
  cSVG: "",
  needManualCalibration: "loading",
  acuityTestResults: {
    left: null,
    right: null,
  },
  currentPrescriptionIsLoading: false,
  currentPrescription: null,
  commentIsLoading: false,
  comment: "",
};

const surveySlice = createSlice({
  name: "acuity",
  initialState: initialState,
  reducers: {
    requestGetSetupVideos: (state) => {
      state.setupVideosInfo = {
        ...state.setupVideosInfo,
        isLoading: true,
      };
    },
    successGetSetupVideos: (
      state,
      {
        payload,
      }: PayloadAction<{
        language: string;
        country: string;
        data: SetupVideoData;
      }>
    ) => {
      state.setupVideosInfo = {
        isLoading: false,
        language: payload.language,
        country: payload.country,
        data: payload.data,
      };
    },
    failGetSetupVideos: (state) => {
      state.setupVideosInfo = {
        ...state.setupVideosInfo,
        isLoading: false,
      };
    },
    enableNextButton: (
      state,
      { payload }: PayloadAction<{ idToEnable: string }>
    ) => {
      state.enableNextButtonMap = {
        ...state.enableNextButtonMap,
        [payload.idToEnable]: true,
      };
    },
    setCSize: (
      state,
      {
        payload,
      }: PayloadAction<{ cSizeMap: cSizeMapType; calibratedManually?: boolean }>
    ) => {
      state.cSizeMap = payload.cSizeMap;
      state.calibratedManually = !!payload.calibratedManually;

      setCSSVariable("--cSize4", payload.cSizeMap[4]?.toString() + "px");
      setCSSVariable("--cSize6", payload.cSizeMap[6]?.toString() + "px");
      setCSSVariable("--cSize8", payload.cSizeMap[8]?.toString() + "px");
      setCSSVariable("--cSize10", payload.cSizeMap[10]?.toString() + "px");
    },
    setCSVG: (state, { payload }: PayloadAction<{ cSVG: string }>) => {
      state.cSVG = payload.cSVG;
    },
    setNeedManualCalibration: (
      state,
      {
        payload,
      }: PayloadAction<{
        needManualCalibration: "loading" | "true" | "false" | "mini";
      }>
    ) => {
      state.needManualCalibration = payload.needManualCalibration;
    },
    setAcuityTestResults: (
      state,
      {
        payload,
      }: PayloadAction<{
        eye: "left" | "right";
        result: AcuityTestResultSingleEye;
      }>
    ) => {
      let acuityTestResultsTemp = {
        ...state.acuityTestResults,
      };

      if (payload.eye === "left") {
        acuityTestResultsTemp.left = payload.result;
      }

      if (payload.eye === "right") {
        acuityTestResultsTemp.right = payload.result;
      }

      state.acuityTestResults = acuityTestResultsTemp;
    },
    requestSendCurrentPrescription: (state) => {
      state.currentPrescriptionIsLoading = true;
    },
    successSendCurrentPrescription: (
      state,
      {
        payload,
      }: PayloadAction<{
        currentPrescription: CurrentPrescriptionType;
      }>
    ) => {
      state.currentPrescriptionIsLoading = false;
      state.currentPrescription = payload?.currentPrescription;
    },
    failSendCurrentPrescription: (state) => {
      state.currentPrescriptionIsLoading = false;
    },
    reset: () => initialState,
  },
});

////ASYNC ACTIONS////
export const getSetupVideos = createAsyncThunk(
  "acuity/getSetupVideos",
  async (_, { dispatch, getState }) => {
    const mock = false;

    dispatch(acuityActions.requestGetSetupVideos());

    const state = getState() as RootState;
    const language = state.utility.labelInfo?.language?.toLowerCase();
    const country = "US";
    const area = "na";
    const url = getEndpoint("getSetupVideos")
      // .replace("{language}", language || "en-us")
      .replace("{language}", "en-us") //for now CMS has data only for some languages, not all
      .replace("{country}", country || "US")
      .replace("{area}", area || "na");

    (mock
      ? mockService("/mock/acuity/cmsrequest.json", 1000)
      : axios({
          url,
          method: "GET",
          headers: getHeaders(),
          withCredentials: true,
        })
    )
      .then((response) => {
        let cmsResponse: TutorialVideosCMSResponse | null = null;
        if (response.data) {
          cmsResponse = response.data;
        }

        //transform CMS structure into a more readable and practical structure
        const setupVideosInfo: SetupVideoData = {
          tutorial: [],
          training: [],
          "acuity-dx": [],
          "acuity-sx": [],
        };

        cmsResponse?.content?.result?.forEach((result) => {
          result?.grid?.rows?.forEach((row) => {
            row?.placements?.forEach((placement) => {
              const placementType = placement.name;

              placement?.items?.forEach((item) => {
                let data = null;

                if (item.data?.uri) {
                  data = {
                    uri: item.data.uri,
                    type: item.type === "CMVideo" ? "video" : "audio",
                  };

                  setupVideosInfo[placementType].push(data);
                }
              });
            });
          });
        });

        dispatch(
          acuityActions.successGetSetupVideos({
            language: language,
            country: country,
            data: setupVideosInfo,
          })
        );
      })
      .catch((error) => {
        const errorLabel = "Error getting setup videos";
        console.error(errorLabel, { error });
        dispatch(
          utilityActions.setShowErrorPopup({
            showErrorPopup: true,
            analyticsInfo: {
              Error_Details:
                errorLabel + "\n --- \n" + JSON.stringify(error || {}),
            },
          })
        );
        dispatch(acuityActions.failGetSetupVideos());
      });
  }
);

const convertHeicToJpeg = async (heicFile: File): Promise<File> => {
  if (!heicFile) {
    return null;
  }

  return await heic2any({
    blob: heicFile,
    toType: "image/jpeg",
    quality: 1,
  })
    .then(function (jpegBlob) {
      const jpegUrl = URL.createObjectURL(jpegBlob);
      // You can use jpegUrl to display or download the converted JPEG image.
      console.log({ jpegUrl, name: heicFile.name });

      return new File([jpegBlob], heicFile.name?.replace(".heic", ".jpg"), {
        type: "application/jpeg",
      });
    })
    .catch(function (error) {
      console.error("Error converting HEIC to JPEG:", error);
      return null;
    });
};

export const sendCurrentPrescription = createAsyncThunk(
  "acuity/sendCurrentPrescription",
  async (
    payload: {
      currentPrescription: CurrentPrescriptionType;
      onSuccessCallback: () => void;
      onErrorCallback: () => void;
    },
    { dispatch, getState }
  ) => {
    if (!payload?.currentPrescription?.file) {
      console.error("sendCurrentPrescription - param file not found");
      return;
    }

    const mock = false;

    const connectToken = sessionStorage.getItem(sessionStorageIds.CONNECT);
    if (!connectToken) {
      console.error("sendCurrentPrescription - connectToken not found");
      return;
    }

    const fileExtension = payload.currentPrescription.file.name
      ?.split(".")
      .pop()
      .toLowerCase();
    const urls = {
      pdf: "sendCurrentPrescriptionPdf",
      jpg: "sendCurrentPrescriptionJpg",
      png: "sendCurrentPrescriptionPng",
      heic: "sendCurrentPrescriptionJpg",
    };
    const url = getEndpoint(urls[fileExtension]).replace(
      "{token}",
      connectToken
    );
    if (!url) {
      console.error("File extension not supported", {
        fileExtension,
        fileName: payload.currentPrescription.file.name,
      });
      return;
    }

    dispatch(acuityActions.requestSendCurrentPrescription());

    let file = payload.currentPrescription.file;

    if (fileExtension === "heic") {
      file = await convertHeicToJpeg(file);
      console.log(".heic converted to .jpg", { file });

      if (!file) {
        console.error("Error converting HEIC FILE", { file });
        dispatch(acuityActions.failSendCurrentPrescription());
        return;
      }
    }

    // var file = new File([myBlob], "name");
    const formData = new FormData();
    formData.append("rx_file", file);

    (mock
      ? mockService("/mock/acuity/sendCurrentPrescription.json", 1000)
      : axios({
          url,
          method: "POST",
          headers: getHeaders(""),
          withCredentials: true,
          data: formData,
        })
    )
      .then((response) => {
        let responseBody: { code: number; message: string } | null = null;
        if (response.data) {
          responseBody = response.data;
        }

        if (responseBody.code === 200) {
          dispatch(
            acuityActions.successSendCurrentPrescription({
              currentPrescription: payload.currentPrescription,
            })
          );

          if (payload?.onSuccessCallback) {
            payload.onSuccessCallback();
          }
        } else {
          const errorLabel = "Error sending the current prescription";
          dispatch(acuityActions.failSendCurrentPrescription());
          dispatch(
            utilityActions.setShowErrorPopup({
              showErrorPopup: true,
              analyticsInfo: {
                Error_Details:
                  errorLabel + "\n --- \n" + JSON.stringify(response || {}),
              },
              retryCloseModal: true,
            })
          );
          if (payload?.onErrorCallback) {
            payload?.onErrorCallback();
          }
        }
      })
      .catch((error) => {
        const errorLabel = "Error sending the current prescription";
        console.error(errorLabel, { error });
        dispatch(
          utilityActions.setShowErrorPopup({
            showErrorPopup: true,
            analyticsInfo: {
              Error_Details:
                errorLabel + "\n --- \n" + JSON.stringify(error || {}),
            },
            retryCloseModal: true,
          })
        );
        dispatch(acuityActions.failSendCurrentPrescription());
        if (payload?.onErrorCallback) {
          payload?.onErrorCallback();
        }
      });
  }
);

////SELECTORS////
export const selectSetupVideosInfo = (state: RootState) => {
  return state.acuity.setupVideosInfo;
};
export const selectEnableNextButtonMap = (state: RootState) => {
  return state.acuity.enableNextButtonMap;
};
export const selectCSizeMap = (state: RootState) => {
  return state.acuity.cSizeMap;
};
export const selectCSize = createSelector(
  (state: RootState) => state.acuity.cSizeMap,
  (_, vaValue: number) => vaValue,
  (cSizeMap, vaValue: number): string => {
    return cSizeMap[vaValue] + "px" || "0";
  }
);
export const selectCalibratedManually = (state: RootState) => {
  return state.acuity.calibratedManually;
};
export const selectCSVG = (state: RootState) => {
  return state.acuity.cSVG;
};
export const selectNeedManualCalibration = (state: RootState) => {
  return state.acuity.needManualCalibration;
};
export const selectAcuityTestResults = (state: RootState) => {
  return state.acuity.acuityTestResults;
};
export const selectCurrentPrescriptionIsLoading = (state: RootState) => {
  return state.acuity.currentPrescriptionIsLoading;
};
export const selectCurrentPrescription = (state: RootState) => {
  return state.acuity.currentPrescription;
};
export const selectCommentIsLoading = (state: RootState) => {
  return state.acuity.commentIsLoading;
};
export const selectComment = (state: RootState) => {
  return state.acuity.comment;
};

export const acuityReducer = surveySlice.reducer;
export const acuityActions = surveySlice.actions;
export const acuityAsyncActions = {
  getSetupVideos,
  sendCurrentPrescription,
};
