import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import PageStartTutorial from "@components/common/PageStartTutorial/component";
import { useTranslation } from "@hooks/useTranslation";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type Props = {};

function SurveyPageStart(props: Props) {
  const navigate = useNavigate();
  const translate = useTranslation();

  const goToSurvey = () => {
    navigate(ROUTING.SURVEY.url);
  };

  return (
    <PageStartTutorial
      title={translate("survey_start.title")}
      descriptions={[translate("survey_start.description")]}
      buttonAction={goToSurvey}
      showBackButton={true}
      analyticsInfoButton={{
        "data-element-id": "RxRenewal_Eligibility-Next",
        "data-description": formatAnalyticsInnerText(translate("footer.next")),
        "data-analytics_available_call": 0,
      }}
      analyticsInfoLeftButton={{
        "data-element-id": "RxRenewal_Eligibility-Back",
        "data-analytics_available_call": 0,
      }}
    />
  );
}

export default SurveyPageStart;
