import stylePageCardMobile from "@components/PageCardMobile/style.module.scss";
import Button from "@components/common/Button/component";
import PageStandard from "@components/common/PageStandard/component";
import Sidebar from "@components/common/Sidebar/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import { useUtag } from "@hooks/useUtag";
import { selectConfig } from "@slices/configSlice";
import {
  selectSendAnswersInfo,
  selectSurvey,
  selectSurveyInfo,
  surveyAsyncActions,
} from "@slices/surveySlice";
import { utilityAsyncActions } from "@slices/utilitySlice";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";
import { printHTML, removeHTMLtags } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import {
  findStepInfoByUrlParam,
  numberOfFurtherSteps,
  prescriptionStepId,
  reviewStepId,
} from "@utility/surveyUtility";
import { exitRxr } from "@utility/utility";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import style from "./style.module.scss";

type SurveyErrorPageProps = {};

const SurveyErrorPage = (props: SurveyErrorPageProps) => {
  const translate = useTranslation();
  const breakpoint = useBreakpointBoolean();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendAnswersInfo = useSelector(selectSendAnswersInfo);
  const config = useSelector(selectConfig);
  const survey = useSelector(selectSurvey);
  const surveyInfo = useSelector(selectSurveyInfo);

  let params = useParams();
  const [totalStepNumber, setTotalStepNumber] = useState(-1);
  const { sendAnalytics } = useUtag({ sendAtEveryPage: false });

  const stepInfoByUrl = findStepInfoByUrlParam(params.stepId, survey);
  // const stepId = stepInfoByUrl.stepId;
  const stepNumber = stepInfoByUrl.stepNumber;

  useEffect(() => {
    focusTitle();
  }, []);

  const focusTitle = () => {
    const title = document.querySelector("." + style.title);
    if (title) {
      title.focus();
    } else {
      setTimeout(() => {
        focusTitle();
      }, 100);
    }
  };

  useEffect(() => {
    if (sendAnswersInfo?.error) {
      sendAnalytics("ELIGIBILITY_KO", ["Rx_Renewal_Release"], {
        Renewal_Ko: removeHTMLtags(
          translate(sendAnswersInfo.error)?.replace(
            "{{answer}}",
            sendAnswersInfo.answer
          )
        ),
      });
    }
  }, [sendAnswersInfo]);

  useEffect(() => {
    if (!surveyInfo.isLoaded && !surveyInfo.isLoading) {
      //if the application doesn't have the survey --> download it
      dispatch(surveyAsyncActions.getSurvey());
    }
  }, [surveyInfo?.isLoaded, surveyInfo?.isLoading]);

  useEffect(() => {
    if (survey?.steps?.length > 0) {
      setTotalStepNumber(survey?.steps?.length + numberOfFurtherSteps);
    }
  }, [survey]);

  const changeStep = (stepId: string, replace: boolean = false) => {
    navigate(ROUTING.SURVEY.url.replace(":stepId?", stepId || ""), {
      replace: replace,
      state: replace ? { isReplace: true } : undefined,
    });
  };

  const handleStepChange = (stepIndex: number) => {
    if (stepIndex != undefined) {
      var stepId;
      switch (stepIndex + 1) {
        //prescription_step case
        case totalStepNumber - 1:
          stepId = prescriptionStepId;
          break;
        //review_step case
        case totalStepNumber:
          stepId = reviewStepId;
          break;
        //all other cases
        default:
          stepId = stepIndex + 1;
          break;
      }
      changeStep(stepId);
    }
  };

  const buttonsFooter = {
    findDoctor: {
      action: () => {
        dispatch(
          utilityAsyncActions.invokeEcommerceFunction({
            functionId: "updateAssessmentStatus",
            finallyCallback: () => {
              exitRxr(config.config?.findDoctorLink);
            },
          })
        );
      },
      keyLabel: "survey.find_eye_doctor",
      analyticsInfo: {
        "data-element-id": "RxRenewal_Eligibility-FindAnEyeDoctor",
        "data-description": "survey.find_eye_doctor",
        "data-analytics_available_call": 1,
      },
    },
    shop: {
      action: () => {
        dispatch(
          utilityAsyncActions.invokeEcommerceFunction({
            functionId: "updateAssessmentStatus",
            finallyCallback: () => {
              exitRxr(config.config?.shopGlassesContactLensesLink);
            },
          })
        );
      },
      keyLabel: "survey.shop_eyeglasses_contactlenses",
      analyticsInfo: {
        "data-element-id": "RxRenewal_Eligibility-ShopNow",
        "data-description": "survey.shop_eyeglasses_contactlenses",
        "data-analytics_available_call": 1,
      },
    },
  };

  return (
    <PageStandard
      className={`${style.Background} ${stylePageCardMobile.pageCardMobile}`}
      classNamePageBody={`${style.surveyErrorPageBody} ${stylePageCardMobile.pageCardMobileBody}`}
      footerInfo={{
        className: `${style.footer}`,
        backgroundWhite: true,
        showLeftButton: true,
        iconLeftButton: "leftArrow",
        analyticsInfoLeftButton: {
          "data-element-id": "RxRenewal_Eligibility-Back",
          "data-analytics_available_call": 0,
        },
        showRightButton: true,
        actionRightButton: breakpoint.isPhoneOrTabletPortrait
          ? buttonsFooter.findDoctor.action
          : buttonsFooter.shop.action,
        labelRightButton: translate(
          breakpoint.isPhoneOrTabletPortrait
            ? buttonsFooter.findDoctor.keyLabel
            : buttonsFooter.shop.keyLabel
        ),
        variantRightButton: breakpoint.isPhoneOrTabletPortrait
          ? "dark"
          : "lightBlue",
        analyticsInfoRightButton: breakpoint.isPhoneOrTabletPortrait
          ? {
              ...buttonsFooter.findDoctor.analyticsInfo,
              "data-description": formatAnalyticsInnerText(
                translate(
                  buttonsFooter.findDoctor.analyticsInfo["data-description"]
                )
              ),
            }
          : {
              ...buttonsFooter.shop.analyticsInfo,
              "data-description": formatAnalyticsInnerText(
                translate(buttonsFooter.shop.analyticsInfo["data-description"])
              ),
            },
        otherElements: (
          <Button
            className={style.shopGlassesButton}
            variant={breakpoint.isPhoneOrTabletPortrait ? "lightBlue" : "dark"}
            onClick={
              breakpoint.isPhoneOrTabletPortrait
                ? buttonsFooter.shop.action
                : buttonsFooter.findDoctor.action
            }
            analyticsInfo={
              breakpoint.isPhoneOrTabletPortrait
                ? {
                    ...buttonsFooter.shop.analyticsInfo,
                    "data-description": formatAnalyticsInnerText(
                      translate(
                        buttonsFooter.shop.analyticsInfo["data-description"]
                      )
                    ),
                  }
                : {
                    ...buttonsFooter.findDoctor.analyticsInfo,
                    "data-description": formatAnalyticsInnerText(
                      translate(
                        buttonsFooter.findDoctor.analyticsInfo[
                          "data-description"
                        ]
                      )
                    ),
                  }
            }
          >
            {translate(
              breakpoint.isPhoneOrTabletPortrait
                ? buttonsFooter.shop.keyLabel
                : buttonsFooter.findDoctor.keyLabel
            )}
          </Button>
        ),
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div
        className={`${stylePageCardMobile.overPageContainer} ${style.overPageContainer}`}
      >
        <p className={stylePageCardMobile.overPageTitle}>
          {translate("survey.eligibility_check")}
        </p>

        <p
          className={stylePageCardMobile.overPageSteps}
          dangerouslySetInnerHTML={printHTML(
            totalStepNumber > 0
              ? translate("current_prescription.steps_recap")
                  ?.replace("{{step}}", stepNumber?.toString())
                  .replace("{{totalSteps}}", totalStepNumber.toString())
              : "​"
          )}
        />
      </div>
      <div
        className={`${stylePageCardMobile.pageBodyCard} ${stylePageCardMobile.noPaddingBodyCard}`}
      >
        {breakpoint.isDesktopOrTabletLandscape && (
          <Sidebar
            currentStepIndex={stepNumber - 1}
            totalSteps={totalStepNumber}
            changeStepFunction={handleStepChange}
          />
        )}
        <div className={style.pageBody}>
          <p
            className={style.title}
            dangerouslySetInnerHTML={printHTML(
              translate("survey.survey_error_title")
            )}
            aria-label={removeHTMLtags(translate("survey.survey_error_title"))}
            tabIndex={0}
          />

          {sendAnswersInfo?.error && (
            <p
              className={style.description}
              dangerouslySetInnerHTML={printHTML(
                translate(sendAnswersInfo.error)?.replace(
                  "{{answer}}",
                  sendAnswersInfo.answer
                )
              )}
            />
          )}

          <p className={`${style.error}`}>
            {translate("survey.survey_error_descr")}
          </p>

          {/* <div className={style.buttonsContainer}>
          <Button
            className={style.shopGlassesButton}
            variant="lightBlue"
            stretchTo330px={true}
            onClick={() => {
              exitRxr(config.config?.shopGlassesContactLensesLink);
            }}
          >
            {translate("survey.shop_eyeglasses_contactlenses")}
          </Button>
          <Button
            className={style.findDoctorButton}
            variant="dark"
            onClick={() => {
              exitRxr(config.config?.findDoctorLink);
            }}
          >
            {translate("survey.find_eye_doctor")}
          </Button>
        </div> */}
        </div>
      </div>
    </PageStandard>
  );
};

export default SurveyErrorPage;
