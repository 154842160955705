import { SELECTOR_RXR, clsx } from "@utility/utility";
import React, { memo, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import style from "./style.module.scss";

type ModalCountdownProps = {
  className?: string;
  isOpen?: boolean;
  countdownStart?: number;
  callbackAfterTimeout?: () => void;
  isReverse?: boolean;
};

const ModalCountdown = memo(
  ({
    className = "",
    isOpen = true,
    countdownStart = 3,
    callbackAfterTimeout,
    isReverse = true,
  }: ModalCountdownProps) => {
    const [countdownValue, setCountdownValue] = useState(countdownStart || 3);

    useEffect(() => {
      setTimeout(() => {
        if (callbackAfterTimeout) {
          callbackAfterTimeout();
        }
      }, countdownStart * 1000);

      updateCountdown(countdownValue - 1);
    }, []);

    const updateCountdown = (value: number) => {
      if (value > 0) {
        setTimeout(() => {
          setCountdownValue(value);
          updateCountdown(value - 1);
        }, 1000);
      }
    };

    return isOpen
      ? createPortal(
          <div
            className={`${style.modal} ${className}`}
            role="dialog"
            aria-modal="true"
          >
            <div className={style.modalBody}>
              <p
                className={clsx(style.message, {
                  [style.reverse]: isReverse,
                })}
              >
                {countdownValue.toString()}
              </p>
            </div>
          </div>,
          document.querySelector(SELECTOR_RXR)
        )
      : null;
  }
);

export default ModalCountdown;
