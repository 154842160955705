import { useTranslation } from "@hooks/useTranslation";
import { clsx } from "@utility/utility";
import React, { memo, useState } from "react";
import LazyImage from "../LazyImage/LazyImage";
import style from "./style.module.scss";

type InputProps = {
  id: string;
  className?: string;
  ariaLabel?: string;
  placeholder?: string;
  disabled?: boolean;
  pattern?: string;
  type?: string;
  isError?: boolean;
  ariaErrormessage?: string;
  icon?: string;
  value?: string;
  maxLength?: number;
  onChange?: (event?) => void;
  inputMode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal";
};
const Input = memo(
  ({
    id,
    ariaLabel = "",
    className = "",
    placeholder = "",
    disabled,
    pattern,
    type,
    isError = false,
    ariaErrormessage = "",
    icon,
    value,
    maxLength,
    onChange,
    inputMode,
    ...rest
  }: InputProps) => {
    const translate = useTranslation();

    //used to prevent Safari zooming at input focus
    const [fontTo16px, setFontTo16px] = useState(false);

    return (
      <div
        className={clsx(style.container, {
          [className]: !!className,
          [style.error]: isError,
          [style.disabled]: disabled,
        })}
      >
        {!!icon && (
          <LazyImage
            className={style.icon}
            src={icon}
            alt={translate("wcag.wcag_input_icon")?.replace(
              "{{placeholder}}",
              placeholder || ""
            )}
            width={"24px"}
            height={"24px"}
          />
        )}
        <input
          {...rest}
          className={clsx(style.input, {
            [style.withValue]: !!value,
          })}
          id={id}
          aria-label={ariaLabel}
          type={type}
          disabled={disabled}
          pattern={pattern}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          inputMode={inputMode}
          // onFocus={() => setFontTo16px(true)}
          onBlur={() => setFontTo16px(false)}
          style={{ fontSize: fontTo16px ? "16px" : undefined }}
          onTouchStart={() => setFontTo16px(true)}
          aria-invalid={isError}
          aria-errormessage={isError ? ariaErrormessage : ""}
        />
        {placeholder && (
          <label htmlFor={id} className={style.floatingLabel}>
            {placeholder}
          </label>
        )}
      </div>
    );
  }
);

export default Input;
