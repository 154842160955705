import Button from "@components/common/Button/component";
import LazyVideo, {
  LazyVideoProps,
} from "@components/common/LazyVideo/LazyVideo";
import { useTranslation } from "@hooks/useTranslation";
import React, { useEffect, useState } from "react";
import style from "./style.module.scss";

interface VideoTutorialProps extends LazyVideoProps {
  id: string;
  classNameVideoContainer?: string;
  onChangeMuted?: (muted: boolean) => void;
  stopVideo?: boolean;
}

function VideoTutorial({
  id = "",
  onChangeMuted,
  stopVideo,
  ...props
}: VideoTutorialProps) {
  const translate = useTranslation();
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [muted, setMuted] = useState(props.muted);

  const toggleMuted = () => {
    if (onChangeMuted) {
      onChangeMuted(!muted);
    }
    setMuted(!muted);
  };

  useEffect(() => {
    // const skipvideo = getCookie("skipvideo");
    // if (skipvideo) {
    //   props.onEnded();
    // }

    if (props.autoPlay) {
      setTimeout(() => {
        const videoElem = document.getElementById(id) as HTMLVideoElement;
        if (videoElem?.paused) {
          setShowPlayButton(true);
        }
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (stopVideo) {
      //stop video
      const videoElem = document.getElementById(id) as HTMLVideoElement;
      videoElem?.pause();
      //stop audio
      const audioElem = document.querySelector(
        "#" + id + " audio"
      ) as HTMLAudioElement;
      audioElem?.pause();
    }
  }, [stopVideo]);

  const playVideo = (restart: boolean = true) => {
    if (id) {
      //get video DOM element
      const videoElem = document.getElementById(id) as HTMLVideoElement;
      if (videoElem) {
        if (restart) {
          //reset video
          videoElem.pause();
          videoElem.currentTime = 0;
        }
        //play video
        videoElem.play();

        //get audio DOM element
        const audioElem = document.querySelector(
          "#" + id + " audio"
        ) as HTMLAudioElement;
        if (audioElem) {
          if (restart) {
            //reset audio
            audioElem.pause();
            audioElem.currentTime = 0;
          }
          //play audio
          audioElem.play();
        }
      }
    }

    if (restart) {
      setIsVideoEnded(false);
    } else {
      setShowPlayButton(false);
    }
  };

  const onVideoEnded = () => {
    setIsVideoEnded(true);
    if (props.onEnded) {
      props.onEnded();
    }
  };

  const onPlay = () => {
    if (showPlayButton) {
      setShowPlayButton(false);
    }
    if (isVideoEnded) {
      setIsVideoEnded(false);
    }
  };

  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

  const handleTutorialVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <div
      className={`${style.lazyVideoContainer} ${props.classNameVideoContainer}`}
    >
      <LazyVideo
        {...props}
        id={id}
        className={`${style.video} ${props.className}`}
        onError={onVideoEnded}
        onEnded={onVideoEnded}
        onPause={() => {
          setShowPlayButton(true);
        }}
        onPlay={onPlay}
        muted={muted}
        onLoadedData={handleTutorialVideoLoad}
      />
      {videoLoaded && (
        <Button
          className={style.volumeButton}
          variant="blur"
          onClick={toggleMuted}
          icon="custom"
          iconInfo={{
            position: "left",
            path: muted
              ? window.rxrEnv?.ASSETS_PATH + "/icons/acuity/volume-off.svg"
              : window.rxrEnv?.ASSETS_PATH + "/icons/acuity/volume-on.svg",
            width: "20px",
            height: "20px",
            className: style.volumeIcon,
            alt: translate(
              muted ? "wcag.wcag_volume_on" : "wcag.wcag_volume_off"
            ),
          }}
          ariaLabel={translate(
            muted ? "wcag.wcag_volume_on" : "wcag.wcag_volume_off"
          )}
        />
      )}

      {videoLoaded && isVideoEnded && (
        <Button
          className={style.replayButton}
          variant="blur"
          onClick={() => playVideo(true)}
          icon="custom"
          iconInfo={{
            position: "left",
            path: window.rxrEnv?.ASSETS_PATH + "/icons/acuity/replay.svg",
            width: "20px",
            height: "20px",
            className: style.replayIcon,
            alt: translate("wcag.wcag_replay"),
          }}
          ariaLabel={translate("wcag.wcag_replay")}
        />
      )}

      {showPlayButton && !isVideoEnded && (
        <Button
          className={style.replayButton}
          variant="blur"
          onClick={() => playVideo(false)}
          icon="custom"
          iconInfo={{
            position: "left",
            path: window.rxrEnv?.ASSETS_PATH + "/icons/acuity/play.svg",
            width: "20px",
            height: "20px",
            className: style.replayIcon,
            alt: translate("wcag.wcag_replay"),
          }}
          ariaLabel={translate("wcag.wcag_replay")}
        />
      )}
    </div>
  );
}

export default VideoTutorial;
