export const sessionStorageIds = {
  TBT: "TBT",
  CONNECT: "connectToken",
  SURVEY_SUBMITTED_HERE: "surveySubmittedHere",
  PATIENT_ID: "patientId",
  USER_ID: "userId",
};

export const cleanSessionStorage = () => {
  sessionStorage.removeItem(sessionStorageIds.TBT);
  sessionStorage.removeItem(sessionStorageIds.CONNECT);
  sessionStorage.removeItem(sessionStorageIds.SURVEY_SUBMITTED_HERE);
  sessionStorage.removeItem(sessionStorageIds.PATIENT_ID);
  sessionStorage.removeItem(sessionStorageIds.USER_ID);
};
