import React, { memo } from "react";
import style from "./style.module.scss";
import Lottie from "lottie-react";
import LoaderLottie from "../../../../public/assets/lottie/loader.json";

type SpinnerProps = {
  className?: string;
};

const Spinner = memo(({ className = "" }: SpinnerProps) => {
  return (
    <div className={`${style.spinner} ${className}`}>
      <Lottie
        animationData={LoaderLottie}
        loop={true}
        width={"75px"}
        height={"75px"}
      />
    </div>
  );
});

export default Spinner;
