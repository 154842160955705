import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import Button from "@components/common/Button/component";
import { useTranslation } from "@hooks/useTranslation";
import { printHTML } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import { useNavigate } from "react-router-dom";
import LazyImage from "@components/common/LazyImage/LazyImage";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import PageStandard from "@components/common/PageStandard/component";
import YouWillNeedComponent from "@components/YouWillNeedComponent/component";
import ModalLegal from "@components/common/ModalLegal/component";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type Props = {};

function StartPage_2(props: Props) {
  const translate = useTranslation();
  const navigate = useNavigate();
  const breakpoint = useBreakpointBoolean();

  //"About" section index.
  //Sections are from 0 to 6 (7 needed elements)
  //If "start_page2.about_element_" + index + "_content" label is empty, no "?" icon is displayed.
  const [aboutSectionIndex, setAboutSectionIndex] = useState(-1);

  const retrieveCurrentAboutTitle = (index: number) => {
    return translate("start_page2.my_prescription_renewal_3_element_" + index);
  };

  const retrieveCurrentAboutContent = (index: number) => {
    return translate("start_page2.about_element_" + index + "_content");
  };

  return (
    <>
      <PageStandard
        classNamePage={`${style.StartPage2}`}
        classNamePageBody={style.StartPage2Body}
        footerInfo={{
          showLeftButton: true,
          showRightButton: true,
          iconLeftButton: "leftArrow",
          actionLeftButton: () => navigate(ROUTING.START_1.url),
          labelRightButton: translate("footer.start"),
          actionRightButton: () => navigate(ROUTING.SURVEY_START.url),
          analyticsInfoRightButton: {
            "data-element-id": "RxRenewal_WhatYouNeed-Start",
            "data-description": formatAnalyticsInnerText(
              translate("footer.start")
            ),
            "data-analytics_available_call": 1,
          },
          backgroundWhite: true,
        }}
        showBackgroundVideo={true}
      >
        <YouWillNeedComponent indexSetterFunction={setAboutSectionIndex} />
      </PageStandard>
      {aboutSectionIndex > -1 && (
        <ModalLegal
          htmlTitle={
            printHTML(retrieveCurrentAboutTitle(aboutSectionIndex), {
              ALLOWED_TAGS: [],
            })?.__html
          }
          htmlDescription={retrieveCurrentAboutContent(aboutSectionIndex)}
          onClose={() => {
            //focus question mark button on close
            document
              .getElementById("question_mark_" + aboutSectionIndex)
              ?.focus();
            setAboutSectionIndex(-1);
          }}
        >
          {aboutSectionIndex === 4 && (
            <LazyImage
              className={style.creditCardImage}
              src={window.rxrEnv?.ASSETS_PATH + "/icons/IDcard-sample-dark.svg"}
              width={"auto"}
              height={"auto"}
              alt={"rule"}
            />
          )}
        </ModalLegal>
      )}
    </>
  );
}

export default StartPage_2;
