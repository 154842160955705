import React from "react";
import { useTranslation } from "@hooks/useTranslation";
import { QuestionType } from "@model/SurveyModel";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { isValidDate } from "@utility/utility";
import style from "./style.module.scss";
import Input from "@components/common/Input/component";
import TextError from "@components/common/TextError/component";
import LazyImage from "@components/common/LazyImage/LazyImage";

type SelectStateProps = {
  question: QuestionType;
  formData: any;
  optional?: boolean;
  initialValue?: string;
};
function SurveyBirthdayInput({
  question,
  formData,
  optional = false,
}: SelectStateProps) {
  const translate = useTranslation();
  const birthdayText = formData?.watch(question.idFE);

  //the function returns the error to show
  const validateDateOfBirth = (value: string): string => {
    if (!value || value?.length < 10) {
      return "";
    }

    const month = value.slice(0, 2) || "00";
    const day = value.slice(3, 5) || "00";
    const year = value.slice(6, 10) || "0000";

    const dateFormatYYYYMMDD = year + "/" + month + "/" + day;
    const currentDate = new Date();
    const selectedDate = new Date(dateFormatYYYYMMDD);

    const errors = [];
    var calculatedErrorsAmount = 0;

    //check month
    if (month.length >= 2 && (+month > 12 || +month < 1)) {
      errors.push(translate("survey.birthday_error_month"));
      calculatedErrorsAmount++;
    }

    //check day
    if (day.length >= 2 && (+day > 31 || +day < 1)) {
      errors.push(translate("survey.birthday_error_day"));
      calculatedErrorsAmount++;
    }

    //check year
    if (
      year.length >= 4 &&
      (+year > currentDate.getFullYear() || +year < 1900)
    ) {
      errors.push(
        translate("survey.birthday_error_year")?.replace(
          "{{currentYear}}",
          currentDate.getFullYear().toString()
        )
      );
      calculatedErrorsAmount++;
    }

    //if day, month and years are uncorrect OR if date doesn't exist (like 31 february)
    if (
      calculatedErrorsAmount === 3 ||
      (errors.length === 0 && !isValidDate(+year, +month, +day))
    ) {
      errors.splice(0, errors.length);
      errors.push(translate("survey.birthday_error_date_not_exist"));
    }

    //check if date is in the past
    if (errors.length === 0 && selectedDate.getTime() > currentDate.getTime()) {
      errors.push(translate("survey.birthday_error_date_future"));
    }

    if (errors?.length > 0) {
      return errors.join("</br>") as string;
    }
  };

  return (
    <div className={style.SurveyBirthdayInputContainer}>
      <Controller
        control={formData.control}
        name={question.idFE}
        rules={{
          required: !optional,
          validate: validateDateOfBirth,
        }}
        render={({ field: { onChange: controllerOnChange } }) => (
          <InputMask
            mask="99/99/9999"
            maskChar={null}
            value={birthdayText}
            onChange={controllerOnChange}
          >
            {(inputProps) => (
              <Input
                {...inputProps}
                id={"Birthday_Input"}
                className={style.SurveyBirthdayInput}
                type="text"
                pattern="[\d\/]*"
                placeholder={question.placeholder.label}
                isError={!!formData.formState?.errors?.[question.idFE]?.message}
                icon={question.placeholder.iconPath}
                inputMode="numeric"
              />
            )}
          </InputMask>
        )}
      />
      {!!formData.formState?.errors?.[question.idFE]?.message && (
        <div className={style.errorContainer}>
          <LazyImage
            src={window.rxrEnv?.ASSETS_PATH + "/icons/red-warning.svg"}
            alt={translate("wcag.wcag_danger_icon")}
            width={"16"}
            height={"16"}
          />
          <TextError
            className={style.TextError}
            htmlLabel={formData.formState?.errors?.[question.idFE].message}
          />
        </div>
      )}
    </div>
  );
}

export default SurveyBirthdayInput;
