import TopicCategoryComponent from "@components/common/FAQsModal/topic_category_component";
import { useAutoFocus } from "@hooks/useAutoFocus";
import { useFocusTrap } from "@hooks/useFocusTrap";
import { useTranslation } from "@hooks/useTranslation";
import { selectConfig } from "@slices/configSlice";
import { selectLabelMap } from "@slices/utilitySlice";
import { printHTML } from "@utility/htmlUtility";
import { SELECTOR_RXR } from "@utility/utility";
import React, { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import Button from "../Button/component";
import style from "./style.module.scss";

type FAQsModalProps = {
  onClose: () => void;
};

const FAQsModal = memo(({ onClose = null }: FAQsModalProps) => {
  useFocusTrap({ isOpen: true, onClose, selector: "." + style.modal });
  useAutoFocus({ selector: "." + style.closeIcon });
  const translate = useTranslation();
  const config = useSelector(selectConfig);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const FAQsLabels = useSelector(selectLabelMap)?.faq || {};

  const [openedTopic, setOpenedTopic] = useState(null);
  const [openedQuestion, setOpenedQuestion] = useState({
    topicKey: null,
    questionKey: null,
  });

  useEffect(() => {
    if (openedQuestion.questionKey && openedQuestion.topicKey) {
      document.querySelector("." + style.closeIcon)?.focus();
    }
  }, [openedQuestion]);

  const handleCloseModal = () => {
    setIsFadingOut(true);

    setTimeout(() => {
      setIsFadingOut(false);
      onClose();
    }, 200);
  };

  return createPortal(
    <div
      className={`${style.modal} ${isFadingOut && style.fadingOut}`}
      role="dialog"
      aria-modal="true"
      aria-labelledby={translate("start_page1.FAQs")}
    >
      <div className={`${style.modalBody}`}>
        <div className={`${style.titleRow}`}>
          <p className={style.htmlTitle}>{translate("start_page1.FAQs")}</p>
          <Button
            className={style.closeIcon}
            variant="underline"
            icon="close"
            onClick={handleCloseModal}
            analyticsInfo={{
              "data-analytics_available_call": 0,
            }}
          />
        </div>
        {openedQuestion.questionKey && openedQuestion.topicKey ? (
          <div className={style.openedQuestionContainer} aria-live="polite">
            <div className={style.openedQuestionTopic}>
              {translate("faq." + openedQuestion.topicKey)}
            </div>
            <div className={style.openedQuestionTitle}>
              {translate("faq." + openedQuestion.questionKey)}
            </div>
            <div
              className={style.openedQuestionAnswer}
              dangerouslySetInnerHTML={printHTML(
                translate(
                  "faq." + openedQuestion.questionKey + "_answer"
                )?.replace(/{{email}}/g, config?.config?.supportEmail || "")
              )}
            />

            <Button
              className={style.goBackButton}
              variant="dark"
              icon="leftArrow"
              onClick={() =>
                setOpenedQuestion({
                  topicKey: null,
                  questionKey: null,
                })
              }
              analyticsInfo={{
                "data-analytics_available_call": 0,
              }}
            >
              {translate("faq.go_back")}
            </Button>
          </div>
        ) : (
          <div className={style.topicsContainer}>
            {Object.keys(FAQsLabels)
              .filter((key) => /^topic_\d+$/.test(key))
              .map((topicKey) => (
                <TopicCategoryComponent
                  key={topicKey}
                  isOpen={openedTopic === topicKey}
                  topicKey={topicKey}
                  onQuestionOpen={(topicKey, questionKey) =>
                    setOpenedQuestion({
                      topicKey: topicKey,
                      questionKey: questionKey,
                    })
                  }
                  onClick={() =>
                    setOpenedTopic(
                      !!openedTopic && openedTopic === topicKey
                        ? null
                        : topicKey
                    )
                  }
                ></TopicCategoryComponent>
              ))}
          </div>
        )}
      </div>
    </div>,
    document.querySelector(SELECTOR_RXR)
  );
});

export default FAQsModal;
