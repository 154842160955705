import { SendAnswersPayload } from "@model/SurveyModel";

export const shallowEqual = (object1: object, object2: object): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export const deepEqual = (object1: object, object2: object): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

const isObject = (object: object): boolean => {
  return object != null && typeof object === "object";
};

export const handleOnEnterKeyPress = (
  event,
  action,
  shouldPreventDefault: boolean = false,
  stopPropagation: boolean = false
) => {
  if (event.key === "Enter" || event.key === " ") {
    action?.();
    if (!action) {
      console.warn("Invalid action");
    }
    if (stopPropagation) {
      event.stopPropagation();
    }
    if (shouldPreventDefault) {
      event.preventDefault();
    }
  }
};

const toVal = (mix) => {
  var k,
    y,
    str = "";

  if (typeof mix === "string" || typeof mix === "number") {
    str += mix;
  } else if (typeof mix === "object") {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k++) {
        if (mix[k]) {
          if ((y = toVal(mix[k]))) {
            str && (str += " ");
            str += y;
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix[k]) {
          str && (str += " ");
          str += k;
        }
      }
    }
  }

  return str;
};

export const clsx = (...args) => {
  let i = 0,
    tmp,
    x,
    str = "";
  while (i < args.length) {
    if ((tmp = args[i++])) {
      if ((x = toVal(tmp))) {
        str && (str += " ");
        str += x;
      }
    }
  }
  return str;
};

export const getQueryParam = (queryParam: string): string => {
  if (!queryParam) {
    return "";
  }

  const actualQueryParams = new URLSearchParams(window.location.search);
  return actualQueryParams.get(queryParam) || "";
};

export const isValidDate = (
  year: number,
  month: number,
  day: number
): boolean => {
  const monthIndex = month - 1;
  const d = new Date(year, monthIndex, day);
  if (
    d.getFullYear() == year &&
    d.getMonth() == monthIndex &&
    d.getDate() == day
  ) {
    return true;
  }
  return false;
};

export const transformIntoArray = <T>(param: T | T[]): T[] => {
  if (!param) {
    return [];
  }

  return Array.isArray(param) ? param : [param];
};

//get CSS variable
export function getCSSVariable(key: string) {
  if (!key) {
    console.error("getCSSVariable - key is not defined");
    return "";
  }

  const root = document.querySelector(SELECTOR_RXR);

  // Get the styles (properties and values) for the root
  const rs = getComputedStyle(root);

  return rs.getPropertyValue(key);
}

//set CSS variable
export function setCSSVariable(key: string, value: string) {
  if (!key || !value) {
    console.error("getCSSVariable - key or value are not defined");
    return "";
  }

  const root = document.querySelector(SELECTOR_RXR);
  if (root) {
    root.style?.setProperty(key, value);
  }
}

export const SELECTOR_RXR = ".OVTwidget";

//get user age from string date to now
export function getAgeFromDateString(date: string) {
  if (!date) {
    return 0;
  }

  const today = new Date();
  const birthDate = transformStringDateToDate(date);

  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

//transform string date mm/dd/yyyy to javascript date
export function transformStringDateToDate(date: string): Date {
  if (!date) {
    return null;
  }

  const dateParts: string[] = date.split("/");
  return new Date(+dateParts[2], +dateParts[0] - 1, +dateParts[1]);
}

export const transformTBTSPayloadIntoConnect = (
  payload: SendAnswersPayload
) => {
  if (!payload) {
    return null;
  }

  const prescriptionTbts = payload.eligibility_questionnarie?.find(
    (question) => question.q_prescription
  )?.q_prescription;
  const pupillaryDistanceTbts = payload.eligibility_questionnarie?.find(
    (question) => question.q_pupillary_distance
  )?.q_pupillary_distance;

  //get prescription and pupillary distance
  const prescription = {
    lensBrandRight: prescriptionTbts.CLBrandAndModel || "",
    lensBrandLeft: "",
    sameValues: prescriptionTbts.sameValues,
    leftSphere: prescriptionTbts.leftSphere || "",
    rightSphere: prescriptionTbts.rightSphere || "",
    leftAxis: prescriptionTbts.leftAxis || "",
    rightAxis: prescriptionTbts.rightAxis || "",
    leftCylinder: prescriptionTbts.leftCylinder || "",
    rightCylinder: prescriptionTbts.rightCylinder || "",
    leftAdd: prescriptionTbts.leftAdd || "",
    rightAdd: prescriptionTbts.rightAdd || "",
    leftHPrism: prescriptionTbts.leftHPrism || "",
    rightHPrism: prescriptionTbts.rightHPrism || "",
    leftVPrism: prescriptionTbts.leftVPrism || "",
    rightVPrism: prescriptionTbts.rightVPrism || "",
    leftHBaseDirection: prescriptionTbts.leftHPrismBaseDir || "",
    rightHBaseDirection: prescriptionTbts.rightHPrismBaseDir || "",
    leftVBaseDirection: prescriptionTbts.leftVPrismBaseDir || "",
    rightVBaseDirection: prescriptionTbts.rightVPrismBaseDir || "",
    leftPd: pupillaryDistanceTbts.leftEye || "",
    rightPd: pupillaryDistanceTbts.rightEye || "",
    rightBaseCurve: prescriptionTbts.rightBaseCurve || "",
    leftBaseCurve: prescriptionTbts.leftBaseCurve || "",
    rightDiameter: prescriptionTbts.rightDiameter || "",
    leftDiameter: prescriptionTbts.leftDiameter || "",
  };

  //get user eligibility questionnarie
  const eligibility_questionnarie = payload.eligibility_questionnarie
    ?.filter(
      (question) => !question.q_prescription && !question.q_pupillary_distance
    )
    .map((question) => ({
      q_id: question.q_id?.toString(),
      q_label: question.q_label,
      q_answer: question.labelConnect,
    }));

  //get user acuity test results
  const acuity_test = [];
  if (payload.tests) {
    if (payload.tests.step1) {
      acuity_test.push({
        t_id: payload.tests.step1.t_id,
        t_label: payload.tests.step1.t_label,
        t_answers: [
          {
            result:
              payload.tests.step1.t_answer?.OD?.result === "OK" &&
              payload.tests.step1.t_answer?.OS?.result === "OK"
                ? "OK"
                : "KO",
            oD: payload.tests.step1.t_answer?.OD?.score,
            oS: payload.tests.step1.t_answer?.OS?.score,
          },
        ],
      });
    }
    if (payload.tests.step2) {
      acuity_test.push({
        t_id: payload.tests.step2.t_id,
        t_label: payload.tests.step2.t_label,
        t_answers: [
          {
            result:
              payload.tests.step2.t_answer?.OD?.result === "OK" &&
              payload.tests.step2.t_answer?.OS?.result === "OK"
                ? "OK"
                : "KO",
            oD: payload.tests.step2.t_answer?.OD?.score,
            oS: payload.tests.step2.t_answer?.OS?.score,
          },
        ],
      });
    }
    if (payload.tests.step3) {
      acuity_test.push({
        t_id: payload.tests.step3.t_id,
        t_label: payload.tests.step3.t_label,
        t_answers: [
          {
            result:
              payload.tests.step3.t_answer?.OD?.result === "OK" &&
              payload.tests.step3.t_answer?.OS?.result === "OK"
                ? "OK"
                : "KO",
            oD: payload.tests.step3.t_answer?.OD?.score,
            oS: payload.tests.step3.t_answer?.OS?.score,
          },
        ],
      });
    }
  }

  //get user state
  const state = payload.eligibility_questionnarie?.find(
    (question) => +question.q_id === 4
  )?.q_answer?.[0];

  //get user date of birth
  const dateOfBirth = payload.eligibility_questionnarie.find(
    (question) => question.q_id === 5
  )?.q_answer?.[0];

  return {
    flow_mode: payload.flow_mode,
    state: state,
    first_name: payload.first_name,
    last_name: payload.last_name,
    email: payload.email,
    age: payload.age?.toString(),
    date_of_birth: dateOfBirth,
    language: payload.language,
    product_type: payload.product_type,
    prescription: prescription,
    eligibility_questionnarie: eligibility_questionnarie || [],
    acuity_test: acuity_test,
  };
};

export const isDeviceIos = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1)
  );
};
export const isDeviceMac = () => {
  return /Macintosh/.test(navigator.userAgent);
};
export const isDeviceAndroid = () => {
  return /Android/.test(navigator.userAgent);
};

const handlePreventUserExit = (event) => {
  // Cancel the event
  event.preventDefault();
  // Chrome requires returnValue to be set
  event.returnValue = "";

  // Display confirmation message
  var confirmationMessage = "Are you sure you want to leave?";
  (event || window.event).returnValue = confirmationMessage; // For IE and Firefox
  return confirmationMessage; // For Safari
};

export const enablePreventUserExit = () => {
  window.addEventListener("beforeunload", handlePreventUserExit);
};
export const disablePreventUserExit = () => {
  window.removeEventListener("beforeunload", handlePreventUserExit);
};

export const exitRxr = (link: string): void => {
  if (link) {
    disablePreventUserExit();
    window.location.href = link;
  }
};
