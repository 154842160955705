import React, { ReactNode, useEffect, useState } from "react";
import style from "./style.module.scss";
import SurveyStepButton from "../SurveyStepButton/component";
import { useTranslation } from "@hooks/useTranslation";
import { printHTML } from "@utility/htmlUtility";

type Props = {
  currentStepIndex: number;
  totalSteps: number;
  changeStepFunction: (value: number) => void;
  children?: ReactNode;
};

export default function ResultSidebar(props: Props) {
  const translate = useTranslation();

  const [currentStepIndex, setCurrentStepIndex] = useState(
    props.currentStepIndex
  );

  useEffect(() => {
    if (props.currentStepIndex != undefined) {
      setCurrentStepIndex(props.currentStepIndex);
    }
  }, [props.currentStepIndex]);

  const handleClick = (index: number) => {
    setCurrentStepIndex(index);
    props.changeStepFunction(index);
  };

  return (
    <div className={style.SurveySteps}>
      <div className={style.SurveyStepsContent}>
        <div className={style.TitleAndSteps}>
          {translate("current_prescription.over_title")}
          <div
            className={style.StepRecap}
            dangerouslySetInnerHTML={printHTML(
              translate("current_prescription.steps_recap")
                ?.replace("{{step}}", "1")
                .replace("{{totalSteps}}", "2")
            )}
          ></div>
        </div>

        <SurveyStepButton
          key={"recap_personal_data"}
          onClick={() => handleClick(1)}
          stepIndex={1}
          isOnFocus={currentStepIndex == 1}
          disabled={currentStepIndex < 1}
        >
          {translate("current_prescription.upload_prescription_button_label")}
        </SurveyStepButton>
        <SurveyStepButton
          key={"recap_prescription_data"}
          onClick={() => handleClick(2)}
          stepIndex={2}
          isOnFocus={currentStepIndex == 2}
          disabled={currentStepIndex < 2}
        >
          {translate("comment.additional_comment_button_label")}
        </SurveyStepButton>
      </div>

      <div className={style.hiddenRightSection} />
      <div className={style.rightTransaction} />
    </div>
  );
}
