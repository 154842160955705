import React, { memo, useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import { printHTML } from "@utility/htmlUtility";
import Button from "@components/common/Button/component";
import { clsx } from "@utility/utility";
import DottedIndex from "@components/common/DottedIndex/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";

type HowToReadPrescriptionModalContentProps = {
  isCL?: boolean;
};

export default memo(function HowToReadPrescriptionModalContent({
  isCL,
}: HowToReadPrescriptionModalContentProps) {
  const breakpoint = useBreakpointBoolean();
  const translate = useTranslation();

  const tableData = useMemo(() => {
    const items = [
      {
        key: 1,
        id: "eyes",
        label: translate("survey.field_OD/OS"),
        content: translate("survey.eyes_description"),
      },
      {
        key: 2,
        id: "sphere",
        label: translate("survey.field_sph"),
        content: translate("survey.sph_description"),
      },
      {
        key: 3,
        id: "cylinder",
        label: translate("survey.field_cyl"),
        content: translate("survey.cyl_description"),
      },
      {
        key: 4,
        id: "axis",
        label: translate("survey.field_axis"),
        content: translate("survey.axis_description"),
      },
      {
        key: 5,
        id: "add",
        label: translate("survey.field_add"),
        content: translate("survey.add_description"),
      },
    ];

    if (!isCL) {
      items.push({
        key: 6,
        id: "prism",
        label: translate("survey.field_prism"),
        content: translate("survey.prism_description"),
      });
    }

    return items;
  }, [translate, isCL]);

  const [selectedCell, setSelectedCell] = useState(1);

  const selectedContent =
    tableData.find((item) => item.key === selectedCell)?.content || "";

  return (
    <>
      <div className={style.badgeButtonContainer}>
        {tableData.map((value) => (
          <Button
            className={clsx(
              style.badgeButton,
              value.key === selectedCell && style.badgeButtonSelected
            )}
            onClick={() => setSelectedCell(value.key)}
            analyticsInfo={{
              "data-analytics_available_call": 0,
            }}
          >
            {value.label}
          </Button>
        ))}
      </div>
      <div className={style.howToReadPrescriptionModalContainer}>
        {tableData.map((element, index) => (
          <div
            key={index}
            aria-hidden={`${!(
              tableData.find((item) => item.key === selectedCell)?.key ===
              index + 1
            )}`}
            className={`${style.fieldDescription} ${
              tableData.find((item) => item.key === selectedCell)?.key ===
              index + 1
                ? style.isSelected
                : ""
            }`}
            dangerouslySetInnerHTML={printHTML(element.content)}
          />
        ))}
        {!breakpoint.isDesktopOrTabletLandscape && (
          <div className={style.dottedIndex}>
            <DottedIndex
              currentStepIndex={selectedCell - 1}
              totalSteps={tableData.length}
            />
          </div>
        )}
      </div>
    </>
  );
});
