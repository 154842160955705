export const BASE_ROUTING_PATH = "/onlinevisiontest";
export const ROUTING = {
  START_1: {
    url: BASE_ROUTING_PATH + "/my-prescription-renewal",
  },
  START_2: {
    url: BASE_ROUTING_PATH + "/what-you-need",
  },
  SURVEY_START: {
    url: BASE_ROUTING_PATH + "/survey-start",
  },
  SURVEY: {
    url: BASE_ROUTING_PATH + "/survey/:stepId?",
    pattern: BASE_ROUTING_PATH + "/survey/:stepId",
  },
  SURVEY_ERROR: {
    url: BASE_ROUTING_PATH + "/survey/:stepId/error",
  },
  LEGAL_DISCLAIMER: {
    url: BASE_ROUTING_PATH + "/legal-disclaimer",
  },
  SETUP_START: {
    url: BASE_ROUTING_PATH + "/setup/start",
  },
  SETUP_STEPS: {
    url: BASE_ROUTING_PATH + "/setup/:tutorialStep",
  },
  SETUP_CALIBRATE_SCREEN: {
    url: BASE_ROUTING_PATH + "/setup/:tutorialStep/calibrate",
  },
  SETUP_CALIBRATION_RULER: {
    url: BASE_ROUTING_PATH + "/setup/:tutorialStep/ruler",
  },
  ACUITY_TUTORIAL_START: {
    url: BASE_ROUTING_PATH + "/acuity-tutorial/start",
  },
  ACUITY_TUTORIAL: {
    url: BASE_ROUTING_PATH + "/acuity-tutorial/:tutorialStep?",
    pattern: BASE_ROUTING_PATH + "/acuity-tutorial/:tutorialStep",
  },
  ACUITY_START: {
    url: BASE_ROUTING_PATH + "/acuity/start",
  },
  ACUITY_VIDEO: {
    url: BASE_ROUTING_PATH + "/acuity/video/:videoId?",
    pattern: BASE_ROUTING_PATH + "/acuity/video/:videoId",
  },
  ACUITY_TEST: {
    url: BASE_ROUTING_PATH + "/acuity/test/:eyeParam",
  },
  ACUITY_WRONG: {
    url: BASE_ROUTING_PATH + "/acuity/wrong/:eyeParam",
  },
  ACUITY_END: {
    url: BASE_ROUTING_PATH + "/acuity/end",
  },
  BEFORE_YOU_CONTINUE: {
    url: BASE_ROUTING_PATH + "/before-you-continue",
  },
  CURRENT_PRESCRIPTION: {
    url: BASE_ROUTING_PATH + "/current-prescription",
  },
  COMMENT: {
    url: BASE_ROUTING_PATH + "/comment",
  },
};
