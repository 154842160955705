import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import Button, {
  ButtonIconInfoProps,
  ButtonIconProps,
  ButtonVariantProps,
} from "@components/common/Button/component";
import Header, { HeaderProps } from "../Header/component";
import { useNavigate } from "react-router-dom";
import Footer from "@components/common/Footer/component";
import Spinner from "@components/common/Spinner/component";
import { useSelector } from "react-redux";
import { selectLabelInfo } from "@slices/utilitySlice";
import LazyVideo from "../LazyVideo/LazyVideo";
import { clsx } from "@utility/utility";
import {
  useBreakpoint,
  useBreakpointBoolean,
} from "@hooks/useBreakpointBoolean";
import { getPreviousPageLabel } from "@hooks/getPreviousPageLabel";

type PageStandardProps = {
  className?: string;
  classNamePage?: string;
  classNamePageBody?: string;
  showHeader?: boolean;
  children?: React.JSX.Element | React.JSX.Element[];
  footerElement?: React.JSX.Element | React.JSX.Element[];
  footerInfo?: {
    className?: string;
    showLeftButton?: boolean;
    labelLeftButton?: string;
    iconLeftButton?: ButtonIconProps;
    iconInfoLeftButton?: ButtonIconInfoProps;
    actionLeftButton?: () => void;
    leftButtonType?: "button" | "submit" | "reset";
    disabledLeftButton?: boolean;
    classNameLeftButton?: string;
    variantLeftButton?: ButtonVariantProps;
    analyticsInfoLeftButton?: any;
    showRightButton?: boolean;
    labelRightButton?: string;
    iconRightButton?: ButtonIconProps;
    iconInfoRightButton?: ButtonIconInfoProps;
    actionRightButton?: () => void;
    rightButtonType?: "button" | "submit" | "reset";
    rightButtonForm?: string;
    classNameRightButton?: string;
    disabledRightButton?: boolean;
    variantRightButton?: ButtonVariantProps;
    analyticsInfoRightButton?: any;
    otherElements?: React.ReactNode;
    otherElementsTop?: React.ReactNode;
    elementNextToRightButton?: React.ReactNode;
    backgroundWhite?: boolean;
  };
  isLoading?: boolean;
  headerProps?: HeaderProps;
  showIntro?: boolean;
  afterIntroCallback?: () => void;
  showBackgroundVideo?: boolean;
};

export default function PageStandard({
  className = "",
  classNamePage = "",
  classNamePageBody = "",
  showHeader = true,
  children = null,
  footerElement = null,
  footerInfo,
  isLoading = false,
  headerProps = {},
  showIntro = false,
  afterIntroCallback = null,
  showBackgroundVideo = false,
}: PageStandardProps) {
  const navigate = useNavigate();
  const isLabelLoading = useSelector(selectLabelInfo)?.isLoading;
  const breakpoint = useBreakpointBoolean();
  const previousPageLabel = getPreviousPageLabel();

  const [currentVideoId, setCurrentVideoId] = useState(
    showIntro ? "intro" : "background"
  );

  const handleVideoEnd = () => {
    if (currentVideoId === "intro") {
      setCurrentVideoId("background");
      if (afterIntroCallback) {
        afterIntroCallback();
      }
    }
  };

  useEffect(() => {
    if (afterIntroCallback && currentVideoId === "background") {
      afterIntroCallback();
    }
  }, []);

  const [videoLoaded, setVideoLoaded] = useState<boolean>(
    showBackgroundVideo ? false : true
  ); //setting this state to true if video is not to be shown

  const handleBackgroundVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <div
      className={clsx(style.Background, {
        [className]: !!className,
        [style.backgroundWithVideo]: showBackgroundVideo,
      })}
    >
      {(showIntro || showBackgroundVideo) && (
        <LazyVideo
          className={
            currentVideoId === "intro"
              ? style.introVideo
              : style.backgroundVideo
          }
          src={
            currentVideoId === "intro"
              ? `${window.rxrEnv?.ASSETS_PATH}/video/intro.mp4`
              : `${window.rxrEnv?.ASSETS_PATH}/video/background-${
                  breakpoint.isDesktopOrTabletLandscape ? "desktop" : "mobile"
                }.mp4`
          }
          width={"100%"}
          height={"100%"}
          ariaLabel={currentVideoId === "intro" ? "intro" : "background"}
          autoPlay={true}
          muted={true}
          loop={currentVideoId === "intro" ? false : true}
          controls={false}
          onEnded={handleVideoEnd}
          playsInline={true}
          onLoadedData={handleBackgroundVideoLoad}
        />
      )}

      {currentVideoId === "background" && (
        <div className={`${style.Page} ${classNamePage}`}>
          {showHeader && <Header {...headerProps} />}

          <main className={`${style.PageBody} ${classNamePageBody}`}>
            {isLabelLoading || isLoading || !videoLoaded ? (
              <Spinner className={style.spinner} />
            ) : (
              children
            )}
          </main>

          {!!footerElement && footerElement}

          {!!footerInfo && (
            <Footer
              className={clsx(style.footer, {
                [footerInfo.className]: !!footerInfo.className,
                [style.footerBackgroundWhite]: footerInfo.backgroundWhite,
              })}
              fixed={true}
              justifyContent={
                !footerInfo.showLeftButton && footerInfo.showRightButton
                  ? "end"
                  : "space-between"
              }
              showShadowScroll={footerInfo.backgroundWhite}
            >
              {!!footerInfo.otherElementsTop && footerInfo.otherElementsTop}
              <div
                className={`${style.footerButtonsRow} ${
                  !footerInfo.showLeftButton && footerInfo.showRightButton
                    ? style.justifyContentEnd
                    : ""
                }`}
              >
                {footerInfo.showLeftButton && (
                  <Button
                    className={footerInfo.classNameLeftButton}
                    onClick={
                      footerInfo.actionLeftButton
                        ? footerInfo.actionLeftButton
                        : () => navigate(-1)
                    }
                    variant={footerInfo.variantLeftButton || "light"}
                    type={footerInfo.leftButtonType}
                    border={true}
                    icon={footerInfo.iconLeftButton}
                    iconInfo={footerInfo.iconInfoLeftButton}
                    disabled={footerInfo.disabledLeftButton}
                    analyticsInfo={{
                      ...footerInfo.analyticsInfoLeftButton,
                      "data-description":
                        footerInfo.iconLeftButton === "leftArrow" &&
                        footerInfo?.analyticsInfoLeftButton?.[
                          "data-element-id"
                        ] &&
                        !footerInfo?.analyticsInfoLeftButton?.[
                          "data-description"
                        ]
                          ? previousPageLabel
                          : footerInfo?.analyticsInfoLeftButton?.[
                              "data-description"
                            ],
                    }}
                  >
                    {footerInfo.labelLeftButton}
                  </Button>
                )}
                {footerInfo.showRightButton && (
                  <div className={style.rightButtonSection}>
                    {!!footerInfo.elementNextToRightButton &&
                      footerInfo.elementNextToRightButton}

                    <Button
                      className={`${style.rightButton} ${footerInfo.classNameRightButton}`}
                      onClick={footerInfo.actionRightButton}
                      variant={footerInfo.variantRightButton || "dark"}
                      type={footerInfo.rightButtonType}
                      form={footerInfo.rightButtonForm}
                      icon={footerInfo.iconRightButton}
                      iconInfo={footerInfo.iconInfoRightButton}
                      disabled={footerInfo.disabledRightButton}
                      analyticsInfo={footerInfo.analyticsInfoRightButton}
                    >
                      {footerInfo.labelRightButton}
                    </Button>
                  </div>
                )}
              </div>
              {!!footerInfo.otherElements && footerInfo.otherElements}
            </Footer>
          )}
        </div>
      )}
    </div>
  );
}
