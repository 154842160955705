import React, { memo, ReactNode } from "react";
import style from "./style.module.scss";

type TooltipProps = {
  className?: string;
  classNameContent?: string;
  classNameTail?: string;
  positionTail?: "top" | "bottom" | "left" | "right";
  children?: ReactNode;
};

function TooltipFunc({
  className = "",
  classNameContent = "",
  classNameTail = "",
  positionTail = "bottom",
  children,
}: TooltipProps) {
  const tooltipTailClassName = {
    top: style.footerTailTop,
    bottom: style.footerTailBottom,
    left: style.footerTailLeft,
    right: style.footerTailRight,
  };

  return (
    <div className={`${style.tooltip} ${className}`}>
      <div className={`${style.tooltipContent} ${classNameContent}`}>
        {children}
        <div
          className={`${style.tooltipTail} ${tooltipTailClassName[positionTail]} ${classNameTail}`}
        />
      </div>
    </div>
  );
}

const Tooltip = memo(TooltipFunc);
export default Tooltip;
