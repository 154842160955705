import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useTranslation } from "@hooks/useTranslation";
// import { removeHTMLtags } from "@utility/htmlUtility";
import { selectHistoryStack } from "@slices/utilitySlice";
import { ROUTING } from "@utility/routingUtility";
import { matchPath } from "react-router-dom";
// import { selectSurvey } from "@slices/surveySlice";

export const getPreviousPageLabel = () => {
  // const translate = useTranslation();
  // const survey = useSelector(selectSurvey);
  const historyStack = useSelector(selectHistoryStack);

  const [label, setLabel] = useState("");

  useEffect(() => {
    const pathname =
      historyStack?.length > 1 ? historyStack[historyStack.length - 2] : "";

    if (!pathname) {
      setLabel("");
      return;
    }

    let labelTemp = "";
    if (matchPath(ROUTING.START_1.url, pathname)) {
      // labelTemp = translate("start_page1.my_prescription_renewal_1_title");
      labelTemp = "Initial page";
    } else if (matchPath(ROUTING.START_2.url, pathname)) {
      // labelTemp = translate("start_page2.my_prescription_renewal_3_title");
      labelTemp = "'You will need' page";
    } else if (matchPath(ROUTING.SURVEY_START.url, pathname)) {
      // labelTemp = translate("survey_start.title");
      labelTemp = "Eligibility test - 'Before you start' page";
    } else if (matchPath(ROUTING.SURVEY.pattern, pathname)) {
      const stepId = matchPath(ROUTING.SURVEY.pattern, pathname)?.params
        ?.stepId;
      switch (stepId) {
        case "prescription_step":
          // labelTemp = translate("survey.q9_step");
          labelTemp = "Eligibility test - Current prescription";
          break;
        case "review_step":
          // labelTemp = translate("survey.q10_title");
          labelTemp = "Eligibility test - Recap section";
          break;
        default:
          labelTemp = "Eligibility test - Step " + stepId?.replace("step_", "");
          // labelTemp = survey?.steps?.find((step) => step.idFE === stepId)?.stepLabel?.label;
          break;
      }
    } else if (matchPath(ROUTING.SURVEY_ERROR.url, pathname)) {
      // labelTemp = translate("survey.survey_error_title");
      labelTemp = "Eligibility test - Not eligible page";
    } else if (matchPath(ROUTING.LEGAL_DISCLAIMER.url, pathname)) {
      // labelTemp = translate("legal_disclaimer.title");
      labelTemp = "Legal disclaimer page";
    } else if (matchPath(ROUTING.SETUP_START.url, pathname)) {
      // labelTemp = translate("setup.start_title");
      labelTemp = "Setup section - 'Let's get in place for the test' page";
    } else if (matchPath(ROUTING.SETUP_STEPS.url, pathname)) {
      const tutorialStep = matchPath(ROUTING.SETUP_STEPS.url, pathname)?.params
        ?.tutorialStep;
      labelTemp = "Setup section - Step " + tutorialStep;
    } else if (matchPath(ROUTING.SETUP_CALIBRATE_SCREEN.url, pathname)) {
      // labelTemp = translate("setup.step1_calibration_title");
      labelTemp = "Setup section - 'Do you have an iphone mini?' page";
    } else if (matchPath(ROUTING.SETUP_CALIBRATION_RULER.url, pathname)) {
      // labelTemp = translate("setup.step1_title");
      labelTemp = "Setup section - Ruler page";
    } else if (matchPath(ROUTING.ACUITY_TUTORIAL_START.url, pathname)) {
      // labelTemp = translate("acuity_tutorial.start_title");
      labelTemp = "Acuity tutorial - 'Let's see how the test works!' page";
    } else if (matchPath(ROUTING.ACUITY_TUTORIAL.pattern, pathname)) {
      const tutorialStep = matchPath(ROUTING.ACUITY_TUTORIAL.pattern, pathname)
        ?.params?.tutorialStep;
      labelTemp = "Acuity tutorial - Step " + tutorialStep;
    } else if (matchPath(ROUTING.ACUITY_START.url, pathname)) {
      // labelTemp = translate("acuity.start_title");
      labelTemp = "Acuity test - 'Let's go for the test!' page";
    } else if (matchPath(ROUTING.ACUITY_VIDEO.pattern, pathname)) {
      const videoId = matchPath(ROUTING.ACUITY_VIDEO.pattern, pathname)?.params
        ?.videoId;
      labelTemp = "Acuity test - Video " + videoId;
    } else if (matchPath(ROUTING.ACUITY_TEST.url, pathname)) {
      const eyeParam = matchPath(ROUTING.ACUITY_TEST.url, pathname)?.params
        ?.eyeParam;
      switch (eyeParam) {
        case "left-eye-tutorial":
          labelTemp = "Acuity test - Left eye tutorial";
          break;
        case "left-eye":
          labelTemp = "Acuity test - Left eye test";
          break;
        case "right-eye-tutorial":
          labelTemp = "Acuity test - Right eye tutorial";
          break;
        case "right-eye":
          labelTemp = "Acuity test - Right eye test";
          break;
      }
    } else if (matchPath(ROUTING.ACUITY_WRONG.url, pathname)) {
      const eyeParam = matchPath(ROUTING.ACUITY_WRONG.url, pathname)?.params
        ?.eyeParam;
      labelTemp = "Acuity test - something went wrong for " + eyeParam + " eye";
    } else if (matchPath(ROUTING.ACUITY_END.url, pathname)) {
      // labelTemp = translate("test_complete.title");
      labelTemp = "Acuity test - 'Good, your vision test is complete!' page";
    } else if (matchPath(ROUTING.BEFORE_YOU_CONTINUE.url, pathname)) {
      // labelTemp = translate("before_you_continue.title");
      labelTemp = "Switch to desktop or continue to smartphone page";
    } else if (matchPath(ROUTING.CURRENT_PRESCRIPTION.url, pathname)) {
      // labelTemp = translate("current_prescription.title");
      labelTemp = "Current prescription upload page";
    } else if (matchPath(ROUTING.COMMENT.url, pathname)) {
      labelTemp = "Comment page";
      // labelTemp = translate("comment.title");
    }

    // labelTemp = removeHTMLtags(labelTemp || "");
    setLabel(labelTemp);
  }, [historyStack]);

  return label;
};
