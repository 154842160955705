import React from "react";
import style from "./style.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import PageStandard from "@components/common/PageStandard/component";
import { printHTML } from "@utility/htmlUtility";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useNavigate } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";

type PageStartTutorialProps = {
  title?: string;
  descriptions?: string[];
  buttonAction: () => void;
  buttonLabelKey?: string;
  otherElements?: React.ReactNode;
  showBackButton?: boolean;
  analyticsInfoButton?: any;
  analyticsInfoLeftButton?: any;
};

function PageStartTutorial({
  title,
  descriptions,
  buttonAction,
  buttonLabelKey,
  otherElements,
  showBackButton = false,
  analyticsInfoButton = {},
  analyticsInfoLeftButton = {},
}: PageStartTutorialProps) {
  const translate = useTranslation();
  const breakpoint = useBreakpointBoolean();
  const navigate = useNavigate();

  return (
    <PageStandard
      className={style.acuityTutorialStart}
      classNamePageBody={style.acuityTutorialStartPage}
      footerInfo={{
        className: style.footer,
        showLeftButton: showBackButton,
        iconLeftButton: "leftArrow",
        analyticsInfoLeftButton: showBackButton ? analyticsInfoLeftButton : {},
        showRightButton: true,
        classNameRightButton: style.footerRightButton,
        labelRightButton: translate(buttonLabelKey || "footer.next"),
        actionRightButton: buttonAction,
        analyticsInfoRightButton: analyticsInfoButton,
        otherElements: otherElements,
        ...{
          ...(breakpoint.isDesktopOrTabletLandscape && {
            backgroundWhite: true,
            showLeftButton: true,
            iconLeftButton: "leftArrow",
            actionLeftButton: () => navigate(ROUTING.START_2.url),
          }),
        },
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div className={style.acuityTutorialStartBody}>
        <div className={style.acuityTutorialStartBodyContent}>
          <p
            className={style.title}
            dangerouslySetInnerHTML={printHTML(title)}
          />

          {descriptions?.map((description) => (
            <p
              key={description}
              className={`${style.description}`}
              dangerouslySetInnerHTML={printHTML(description)}
            />
          ))}
        </div>
      </div>
    </PageStandard>
  );
}

export default PageStartTutorial;
