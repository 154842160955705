import { handleOnEnterKeyPress } from "@utility/utility";
import React from "react";
import Checkbox from "../Checkbox/component";
import RadioButton from "../RadioButton/component";
import LazyImage from "../LazyImage/LazyImage";
import style from "./style.module.scss";
import { SelectProps } from "./component";

export type SelectOptionProps = {
  value: string;
  id: number;
  label: string;
  description?: string;
  isFixed?: boolean;
  isDisabled?: boolean;
  logo?: string;
  exclude?: string[];
  labelAfterSelection?: string;
  separator?: boolean;
  order: number;
  [key: string]: any;
};

export type OptionCallbackActionsProps = {
  selectedElements: SelectOptionProps[];
  setSelectedElements: (value: any) => void;
  openMenuSetterFunction: (value: any) => void;
  confirmChange: (val: SelectOptionProps | SelectOptionProps[]) => void;
};

export const Option = (innerProps: {
  option: SelectOptionProps;
  optionCallbackActions: OptionCallbackActionsProps;
  isDisabled: boolean;
  isSelected: boolean;
  selectProps: SelectProps;
}) => {
  const props = innerProps.selectProps;
  const { option } = innerProps;
  const {
    selectedElements,
    setSelectedElements,
    openMenuSetterFunction,
    confirmChange,
  } = innerProps.optionCallbackActions;

  const onClickOption = (click?: boolean) => {
    if (props.isMultiSelect) {
      //if user is selecting an option that excludes some others --> exclude them
      if (option?.exclude?.length > 0 && !!selectedElements) {
        //innerProps.selectProps.value contains just selected options
        let values = selectedElements;
        if (!innerProps.isSelected) {
          values.push(option);
        } else {
          values = values.filter((a) => a.id !== option.id);
        }

        values = values.filter((a) => !option.exclude.includes(a.id));

        setSelectedElements(values);
      }
    } else {
      let values = [option];
      setSelectedElements(values);

      if (!click) {
        openMenuSetterFunction(false);
        confirmChange(values);
      }
    }
  };

  const forceFocus = (query, repetition, maxRepetition) => {
    setTimeout(() => {
      const doc = document.querySelector(query);
      console.log("forceFocus", {
        activeElement: document.activeElement,
        doc,
        repetition,
        query,
      });
      if (document.activeElement !== doc) {
        doc?.focus();
        if (repetition < maxRepetition) {
          forceFocus(query, repetition + 1, maxRepetition);
        }
      }
    }, 50 * repetition);
  };

  return (
    <>
      {option.separator && <div className={style.optionSeparator} />}
      <div
        aria-label={option.label}
        className={style.selectOption}
        onClick={() => onClickOption(true)}
        onKeyDown={(e) =>
          handleOnEnterKeyPress(e, () => onClickOption(false), true, true)
        }
      >
        <div className={style.option}>
          {props.isMultiSelect ? (
            <Checkbox
              className={style.optionCheckbox}
              id={option.value}
              name={option.value}
              checked={innerProps.isSelected}
              readOnly={true}
              ariaLabelledBy={"label_" + option.value}
              onKeyDown={() => {
                const query = "input#" + option.value;
                forceFocus(query, 0, 4);
              }}
              isInsideSelect={true}
            />
          ) : (
            <RadioButton
              className={style.optionRadio}
              ariaLabel={option.label}
              id={option.value}
              name={option.value}
              checked={innerProps.isSelected}
              readOnly={true}
              disabled={innerProps.isDisabled}
              onKeyDown={() => {
                const query = "input#" + option.value;
                forceFocus(query, 0, 4);
              }}
            />
          )}
          {option.image && (
            <div className={style.optionImageContainer}>
              <LazyImage
                className={style.optionImage}
                src={option.image}
                alt={option.label}
                width={"64px"}
                height={"35px"}
              />
            </div>
          )}
          <div className={style.optionTextContainer}>
            <p className={style.optionTitle}>{option.label}</p>

            {option.description && (
              <p className={style.optionDescription}>{option.description}</p>
            )}
            <label
              htmlFor={option.value}
              id={"label_" + option.value}
              className={style.invisibleLabel}
            >
              {option.label}
              {!!option.description ? option.description : ""}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
