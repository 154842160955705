import FallbackError from "@components/ErrorBoundary/FallbackError";
import ErrorBoundary from "@components/ErrorBoundary/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useUtag } from "@hooks/useUtag";
import { configActions } from "@slices/configSlice";
import {
  getTBTPayload,
  selectTBTInfo,
  surveyActions,
} from "@slices/surveySlice";
import {
  selectAcuityEnd,
  selectCannotGoBackPopup,
  selectShowErrorPopup,
  selectShowTimeoutPopup,
  utilityActions,
  utilityAsyncActions,
} from "@slices/utilitySlice";
import { ROUTING } from "@utility/routingUtility";
import {
  cleanSessionStorage,
  sessionStorageIds,
} from "@utility/sessionStorageUtility";
import { enablePreventUserExit, setCSSVariable } from "@utility/utility";
import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import OnlineVisionTestConfig, {
  IOnlineVisionTestConfig,
} from "./engine/OnlineVisionTestConfig";
const ErrorModal = lazy(() => import("@components/ErrorBoundary/ErrorModal"));
const TimeoutModal = lazy(
  () => import("@components/ErrorBoundary/TimeoutModal")
);
const CannotGoBackModal = lazy(
  () => import("@components/ErrorBoundary/CannotGoBackModal")
);
const Start_1 = lazy(() => import("@pages/StartPage_1/component"));
const Start_2 = lazy(() => import("@pages/StartPage_2/component"));
const SurveyPageStart = lazy(() => import("@pages/SurveyPageStart/component"));
const SurveyPage = lazy(() => import("@pages/SurveyPage/component"));
const SurveyErrorPage = lazy(() => import("@pages/SurveyErrorPage/component"));
const LegalDisclaimerPage = lazy(
  () => import("@pages/LegalDisclaimerPage/component")
);
const SetupStartPage = lazy(() => import("@pages/SetupStartPage/component"));
const SetupCalibrateScreenPage = lazy(
  () => import("@pages/SetupCalibrateScreenPage/component")
);
const SetupCalibrationRulerPage = lazy(
  () => import("@pages/SetupCalibrationRulerPage/component")
);
const SetupStepPage = lazy(() => import("@pages/SetupStepPage/component"));
const AcuityTutorialStartPage = lazy(
  () => import("@pages/AcuityTutorialStartPage/component")
);
const AcuityTutorialPage = lazy(
  () => import("@pages/AcuityTutorialPage/component")
);
const AcuityStartPage = lazy(() => import("@pages/AcuityStartPage/component"));
const AcuityVideoPage = lazy(() => import("@pages/AcuityVideoPage/component"));
const AcuityTestPage = lazy(() => import("@pages/AcuityTestPage/component"));
const AcuityWrongPage = lazy(() => import("@pages/AcuityWrongPage/component"));
const AcuityEndPage = lazy(() => import("@pages/AcuityEndPage/component"));
const BeforeYouContinuePage = lazy(
  () => import("@pages/BeforeYouContinuePage/component")
);
const CurrentPrescriptionPage = lazy(
  () => import("@pages/CurrentPrescriptionPage/component")
);
const CommentPage = lazy(() => import("@pages/CommentPage/component"));

interface AppProps {
  config: IOnlineVisionTestConfig;
}

export const App = ({ config }: AppProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const breakpoint = useBreakpointBoolean();
  const location = useLocation();
  const showErrorPopup = useSelector(selectShowErrorPopup);
  const showTimeoutPopup = useSelector(selectShowTimeoutPopup);
  const TBTInfo = useSelector(selectTBTInfo);
  const cannotGoBackPopup = useSelector(selectCannotGoBackPopup);
  const acuityEnd = useSelector(selectAcuityEnd);
  useUtag({ sendAtEveryPage: true });

  //If there is a TBT in redux or from queryParams, save it to session storage
  //and it means that we came from desktop
  useEffect(() => {
    const TBT = new URLSearchParams(location.search).get("TBT");
    const connectToken = new URLSearchParams(location.search).get(
      "connectToken"
    );
    const patientId = new URLSearchParams(location.search).get("patientId");
    const userId = new URLSearchParams(location.search).get("userId");
    if (TBT && connectToken) {
      sessionStorage.setItem(sessionStorageIds.TBT, TBT);
      sessionStorage.setItem(sessionStorageIds.CONNECT, connectToken);
      sessionStorage.setItem(sessionStorageIds.PATIENT_ID, patientId || "");
      sessionStorage.setItem(sessionStorageIds.USER_ID, userId || "");
    }
  }, []);

  //keyLabel mode check
  useEffect(() => {
    const url = window.location.href;

    const urlParams = new URLSearchParams(new URL(url).search);
    const keyLabelMode = urlParams.get("keyLabelMode");
    if (keyLabelMode) {
      sessionStorage.setItem("keyLabelMode", "true");
    }
  }, []);

  useEffect(() => {
    const configApp = new OnlineVisionTestConfig({
      ...config,
    });

    dispatch(configActions.setConfig(configApp));

    //get labels (checking if we are in keyLabel mode)

    const keyLabelMode = sessionStorage.getItem("keyLabelMode");

    const language =
      keyLabelMode === "true" ? "dev-DEV" : configApp.config?.language;

    dispatch(
      utilityAsyncActions.getLabelMap({
        language,
      })
    );

    //store device height without navigation bar
    const setVh = () => {
      //store in a CSS variable the device height without navigation bars
      //It is very useful for Safari in smartphone and tablets
      const vh = window.innerHeight;
      setCSSVariable("--vh-nobars", `${vh}px`);
    };

    setVh();
    window.addEventListener("load", setVh);
    window.addEventListener("resize", setVh);

    //if there is a connectId in config, save it to session storage
    if (configApp.config?.connectId) {
      sessionStorage.setItem(
        sessionStorageIds.CONNECT,
        configApp.config?.connectId
      );
    }

    //search for TBT in session storage, if it is present, restart from legal disclaimer page
    const TBT = sessionStorage.getItem(sessionStorageIds.TBT);
    const connectToken = sessionStorage.getItem(sessionStorageIds.CONNECT);
    if (TBT && connectToken) {
      dispatch(
        surveyActions.setTBTandConnectToken({
          TBT: TBT,
          connectToken: connectToken,
        })
      );
      dispatch(getTBTPayload(TBT));
    }

    //if there is both TBT and Connect Token and we are on mobile, we can start from legal disclaimer
    //otherwise, we are on desktop and we wait on desktop
    if (TBT && connectToken) {
      if (breakpoint.isPhoneOrTabletPortrait) {
        const cid = new URLSearchParams(location.search).get("cid");
        navigate(ROUTING.LEGAL_DISCLAIMER.url + (cid ? "?cid=" + cid : ""), {
          replace: true,
          state: { isReplace: true },
        });
      } else {
        navigate(ROUTING.SURVEY.url, {
          replace: true,
          state: { isReplace: true },
        });
      }
    }
    //otherwise => still in first part (survey), so go to start page
    else {
      navigate(ROUTING.START_1.url, {
        replace: true,
        state: { isReplace: true },
      });
    }

    if (window.location.hostname !== "localhost") {
      enablePreventUserExit();
    }

    return () => {
      window.removeEventListener("load", setVh);
      window.removeEventListener("resize", setVh);
    };
  }, []);

  //when a TBT or Connect Token is defined, save it to session storage
  useEffect(() => {
    if (TBTInfo.TBT) {
      sessionStorage.setItem(sessionStorageIds.TBT, TBTInfo.TBT);
    }
    if (TBTInfo.connectToken) {
      sessionStorage.setItem(sessionStorageIds.CONNECT, TBTInfo.connectToken);

      const patientId = sessionStorage.getItem(sessionStorageIds.PATIENT_ID);
      const userId = sessionStorage.getItem(sessionStorageIds.USER_ID);

      if (!patientId) {
        sessionStorage.setItem(sessionStorageIds.PATIENT_ID, config.patientId);
      }
      if (!userId) {
        sessionStorage.setItem(sessionStorageIds.USER_ID, config.userId);
      }
    }
  }, [TBTInfo?.TBT, TBTInfo.connectToken]);

  useEffect(() => {
    const tbt = sessionStorage.getItem(sessionStorageIds.TBT);
    const connectToken = sessionStorage.getItem(sessionStorageIds.CONNECT);
    const choiceContinueDevice = ["ACUITY_END", "ACUITY_END_DESKTOP"].includes(
      TBTInfo?.payload?.status
    );

    if (
      (choiceContinueDevice || acuityEnd) &&
      !(
        matchPath(ROUTING.ACUITY_END.url, location.pathname) &&
        !choiceContinueDevice
      ) &&
      !matchPath(ROUTING.BEFORE_YOU_CONTINUE.url, location.pathname) &&
      !matchPath(ROUTING.CURRENT_PRESCRIPTION.url, location.pathname) &&
      !matchPath(ROUTING.COMMENT.url, location.pathname) &&
      !(
        location.pathname ===
          ROUTING.SURVEY.url.replace(":stepId?", "review_step") &&
        breakpoint.isDesktopOrTabletLandscape &&
        TBTInfo?.payload?.status === "ACUITY_END"
      )
    ) {
      //cannot go back after acuity test + decision go back to desktop or continue to smartphone
      dispatch(
        utilityActions.setCannotGoBackPopup({ cannotGoBackPopup: true })
      );
    } else if (
      tbt &&
      connectToken &&
      (matchPath(ROUTING.START_1.url, location.pathname) ||
        matchPath(ROUTING.START_2.url, location.pathname) ||
        matchPath(ROUTING.SURVEY_START.url, location.pathname) ||
        (matchPath(ROUTING.SURVEY.pattern, location.pathname) &&
          (breakpoint.isPhoneOrTabletPortrait ||
            location.pathname !==
              ROUTING.SURVEY.url.replace(":stepId?", "review_step"))) ||
        matchPath(ROUTING.SURVEY_ERROR.url, location.pathname))
    ) {
      //cannot go back after survey completion
      dispatch(
        utilityActions.setCannotGoBackPopup({ cannotGoBackPopup: true })
      );
    } else if (cannotGoBackPopup) {
      //close cannot go back popup if it is open and not in the previous cases
      dispatch(
        utilityActions.setCannotGoBackPopup({ cannotGoBackPopup: false })
      );
    }

    if ((tbt && !connectToken) || (!tbt && connectToken)) {
      cleanSessionStorage();
    }

    dispatch(
      utilityActions.addUrlToHistoryStack({
        url: location.pathname,
        replace: location.state?.isReplace,
      })
    );
  }, [location]);

  return (
    <ErrorBoundary fallback={<FallbackError />}>
      <Suspense fallback={null}>
        <Routes>
          <Route path={ROUTING.START_1.url} Component={Start_1} />
          <Route path={ROUTING.START_2.url} Component={Start_2} />
          <Route path={ROUTING.SURVEY_START.url} Component={SurveyPageStart} />
          <Route path={ROUTING.SURVEY.url} Component={SurveyPage} />
          <Route path={ROUTING.SURVEY_ERROR.url} Component={SurveyErrorPage} />
          <Route path={ROUTING.SETUP_START.url} Component={SetupStartPage} />
          <Route
            path={ROUTING.SETUP_CALIBRATE_SCREEN.url}
            Component={SetupCalibrateScreenPage}
          />
          <Route
            path={ROUTING.SETUP_CALIBRATION_RULER.url}
            Component={SetupCalibrationRulerPage}
          />
          <Route
            path={ROUTING.LEGAL_DISCLAIMER.url}
            Component={LegalDisclaimerPage}
          />
          <Route path={ROUTING.SETUP_STEPS.url} Component={SetupStepPage} />
          <Route
            path={ROUTING.ACUITY_TUTORIAL_START.url}
            Component={AcuityTutorialStartPage}
          />
          <Route
            path={ROUTING.ACUITY_TUTORIAL.url}
            Component={AcuityTutorialPage}
          />
          <Route path={ROUTING.ACUITY_START.url} Component={AcuityStartPage} />
          <Route path={ROUTING.ACUITY_VIDEO.url} Component={AcuityVideoPage} />
          <Route path={ROUTING.ACUITY_TEST.url} Component={AcuityTestPage} />
          <Route path={ROUTING.ACUITY_WRONG.url} Component={AcuityWrongPage} />
          <Route path={ROUTING.ACUITY_END.url} Component={AcuityEndPage} />
          <Route
            path={ROUTING.BEFORE_YOU_CONTINUE.url}
            Component={BeforeYouContinuePage}
          />
          <Route
            path={ROUTING.CURRENT_PRESCRIPTION.url}
            Component={CurrentPrescriptionPage}
          />
          <Route path={ROUTING.COMMENT.url} Component={CommentPage} />
          {/* if no match, redirect to Welcome page */}
          <Route
            path="*"
            element={<Navigate replace to={ROUTING.START_1.url} />}
          />
        </Routes>
      </Suspense>

      {showErrorPopup && (
        <Suspense fallback={null}>
          <ErrorModal />
        </Suspense>
      )}
      {showTimeoutPopup && (
        <Suspense fallback={null}>
          <TimeoutModal />
        </Suspense>
      )}
      {cannotGoBackPopup && (
        <Suspense fallback={null}>
          <CannotGoBackModal />
        </Suspense>
      )}
    </ErrorBoundary>
  );
};
