import RecapElement from "@components/RecapElement/component";
import { useTranslation } from "@hooks/useTranslation";
import React, { useEffect, useState } from "react";
import style from "./style.module.scss";

import {
  PrescriptionData,
  getPrescriptionChips,
  getPrescriptionIds,
} from "@components/PrescriptionForm/component";
import { getPupillaryDistanceChips } from "@components/SelectPD/component";
import { selectSurvey } from "@slices/surveySlice";
import { selectLabelMap } from "@slices/utilitySlice";
import { prescriptionStepId } from "@utility/surveyUtility";
import { useSelector } from "react-redux";

const defaultRecapDataPrescription = {
  stepId: prescriptionStepId,
  stepNumber: prescriptionStepId,
  recapGroup: {
    iconPath:
      window.rxrEnv?.ASSETS_PATH + "/icons/survey/question_group/note.svg",
    label: "",
  },
  answers: [],
};
const defaultRecapDataPupillaryDistance = {
  stepId: prescriptionStepId,
  stepNumber: prescriptionStepId,
  recapGroup: {
    iconPath:
      window.rxrEnv?.ASSETS_PATH +
      "/icons/survey/question_group/pupillary-distance.svg",
    label: "",
  },
  answers: [],
};

const defaultRecapDataBrandModelCL = {
  stepId: prescriptionStepId,
  stepNumber: prescriptionStepId,
  recapGroup: {
    iconPath:
      window.rxrEnv?.ASSETS_PATH +
      "/icons/survey/question_group/contact-lenses.svg",
    label: "",
  },
  answers: [],
};

type SelectStateProps = {
  formData: any;
  optional?: boolean;
};
const RecapData = ({ formData, optional = false }: SelectStateProps) => {
  const translate = useTranslation();
  const labelMap = useSelector(selectLabelMap);

  const recapData = formData.getValues();
  const survey = useSelector(selectSurvey);

  const [recapDataPrescriptionLeft, setRecapDataPrescriptionLeft] = useState(
    defaultRecapDataPrescription
  );
  const [recapDataPrescriptionRight, setRecapDataPrescriptionRight] = useState(
    defaultRecapDataPrescription
  );
  const [recapDataPupillaryDistance, setRecapDataPupillaryDistance] = useState(
    defaultRecapDataPupillaryDistance
  );
  const [recapDataBrandModelCL, setRecapDataBrandModelCL] = useState(
    defaultRecapDataBrandModelCL
  );

  useEffect(() => {
    let recapDataPrescriptionLeftTemp = null;
    let recapDataPrescriptionRightTemp = null;
    let recapDataPupillaryDistanceTemp = null;
    let recapDataBrandModelTemp = null;

    //takes prescription chips
    const ids = getPrescriptionIds("prescription_question");
    let data = {};
    Object.keys(ids).forEach((key) => {
      data[key] = recapData[ids[key]];
    });
    const chips = getPrescriptionChips(data as PrescriptionData, translate);

    const recapGroupPrescriptionLeft = {
      iconPath:
        window.rxrEnv?.ASSETS_PATH + "/icons/survey/question_group/note.svg",
      label: translate("survey.questiongroup_prescription_values_OS"),
    };
    recapDataPrescriptionLeftTemp = {
      stepId: prescriptionStepId,
      stepNumber: prescriptionStepId,
      recapGroup: recapGroupPrescriptionLeft,
      answers: chips.left,
    };

    const recapGroupPrescriptionRight = {
      iconPath:
        window.rxrEnv?.ASSETS_PATH + "/icons/survey/question_group/note.svg",
      label: translate("survey.questiongroup_prescription_values_OD"),
    };

    recapDataPrescriptionRightTemp = {
      stepId: prescriptionStepId,
      stepNumber: prescriptionStepId,
      recapGroup: recapGroupPrescriptionRight,
      answers: chips.right,
    };

    //get pupillary distance chips
    const answer = getPupillaryDistanceChips(labelMap, {
      iHaveTwoPDs: recapData.pd_question_iHaveTwoPDs,
      leftPD: recapData.pd_question_leftPD,
      rightPD: recapData.pd_question_rightPD,
      iDontKnowMyPD: recapData.pd_question_dontknowPD,
    });

    const recapGroupPupillaryDistance = {
      iconPath:
        window.rxrEnv?.ASSETS_PATH +
        "/icons/survey/question_group/pupillary-distance.svg",
      label: translate("survey.questiongroup_pupillary_distance"),
    };

    const wearGlasses = recapData.question_6?.length > 0;
    console.log("wearGlasses", { answer, wearGlasses, recapData });

    //show pupillary distance only if user has selected glasses, not contact lenses
    recapDataPupillaryDistanceTemp = {
      stepId: prescriptionStepId,
      stepNumber: prescriptionStepId,
      recapGroup: recapGroupPupillaryDistance,
      answers: wearGlasses ? answer : [],
    };

    //get brand and model chips
    const brand = recapData.prescription_question_CLBrand;
    const model = recapData.prescription_question_CLModel;

    const answerBrandModelCL = [];
    if (brand) {
      answerBrandModelCL.push(translate("survey.q9_CL_brand") + ": " + brand);
    }
    if (model) {
      answerBrandModelCL.push(translate("survey.q9_CL_model") + ": " + model);
    }

    const recapGroupBrandModelCL = {
      iconPath:
        window.rxrEnv?.ASSETS_PATH +
        "/icons/survey/question_group/contact-lenses.svg",
      label: translate("survey.q9_a3_title"),
    };

    //show brand and model CL only if user has selected contact lenses, not glasses
    recapDataBrandModelTemp = {
      stepId: prescriptionStepId,
      stepNumber: prescriptionStepId,
      recapGroup: recapGroupBrandModelCL,
      answers: wearGlasses ? [] : answerBrandModelCL,
    };

    setRecapDataPrescriptionLeft(recapDataPrescriptionLeftTemp);
    setRecapDataPrescriptionRight(recapDataPrescriptionRightTemp);
    setRecapDataPupillaryDistance(recapDataPupillaryDistanceTemp);
    setRecapDataBrandModelCL(recapDataBrandModelTemp);
  }, [JSON.stringify(recapData || {})]);

  const retrieveAnswers = () => {
    const answeredQuestionsData = [];

    survey.steps.map((step, index) => {
      step.questions.map((question) => {
        if (
          recapData.hasOwnProperty(question.idFE) &&
          recapData[question.idFE]
        ) {
          const answers = [];

          switch (question.type) {
            case "localization":
              //localization never has values --> do nothing
              break;
            case "input-birth-date":
              answers.push(recapData[question.idFE]);
              break;
            case "select-list":
              answers.push(
                question.answers.find(
                  (answer) => answer.idFE === recapData[question.idFE]
                ).title
              );
              break;
            case "select-state":
            case "select":
            case "multiselect":
              if (Array.isArray(recapData[question.idFE])) {
                recapData[question.idFE]?.map((answer) => {
                  if (answer.label) {
                    answers.push(answer.labelAfterSelection || answer.label);
                  }
                });
              } else {
                if (recapData[question.idFE]?.label) {
                  answers.push(
                    recapData[question.idFE]?.labelAfterSelection ||
                      recapData[question.idFE]?.label
                  );
                }
              }
              break;
          }

          answeredQuestionsData.push({
            stepId: step.idFE,
            stepNumber: (index + 1).toString(),
            // questionIdFE: question.idFE,
            recapGroup: question.recapGroup,
            // answerKey: recapData[question.idFE],
            answers: answers,
          });
        }
      });
    });

    //retrieving prescription (OD & OS) and pupillary distance answers
    answeredQuestionsData.push(recapDataPrescriptionRight);
    answeredQuestionsData.push(recapDataPrescriptionLeft);
    answeredQuestionsData.push(recapDataPupillaryDistance);
    answeredQuestionsData.push(recapDataBrandModelCL);

    return answeredQuestionsData;
  };

  return (
    <>
      <div className={style.RecapData}>
        {retrieveAnswers()?.map(
          (element, index) =>
            element.answers.length > 0 && (
              <RecapElement
                key={index}
                iconPath={element?.recapGroup?.iconPath}
                iconAltPath={element?.recapGroup?.label}
                label={element?.recapGroup?.label}
                stepId={element.stepId}
                stepNumber={element.stepNumber}
                answer={element.answers}
              />
            )
        )}
      </div>
    </>
  );
};

export default RecapData;
