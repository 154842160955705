import React, { memo } from "react";

export interface LazyVideoProps {
  src: string;
  ariaLabel: string;
  width: number | string; //to prevent CLS
  height: number | string; //to prevent CLS
  id?: string;
  className?: string;
  onError?: () => void;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  controls?: boolean;
  playsInline?: boolean;
  audio?: string;
  onEnded?: () => void;
  onPause?: () => void;
  onPlay?: () => void;
  onLoadedData?: () => void;
}

const LazyVideo = memo(
  ({
    src,
    width,
    height,
    ariaLabel,
    className,
    onError = () => {},
    id,
    autoPlay = false,
    muted = false,
    loop = false,
    controls = true,
    playsInline = false,
    audio = "",
    onEnded = () => {},
    onPause = () => {},
    onPlay = () => {},
    onLoadedData = () => {},
  }: LazyVideoProps) => {
    return src ? (
      <video
        className={className}
        src={src}
        width={width}
        height={height}
        aria-label={ariaLabel || ""}
        id={id}
        onError={onError}
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        controls={controls}
        playsInline={playsInline}
        webkit-playsinline={playsInline ? "true" : undefined}
        controlsList="nodownload"
        preload="none"
        onEnded={onEnded}
        onPause={onPause}
        onPlay={onPlay}
        onLoadedData={onLoadedData}
      >
        {audio && (
          <audio
            autoPlay={autoPlay}
            muted={muted}
            loop={loop}
            controls={controls}
            preload="none"
          >
            <source src={audio} type="audio/mpeg" />
          </audio>
        )}
      </video>
    ) : null;
  }
);

export default LazyVideo;
