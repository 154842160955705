import { initializeUtag } from "@utility/analyticsUtility";
import React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";
import "./index.scss";
import { store } from "./redux/Store";
import { detectStoreId, storeId } from "@utility/utility";

let root = null;
const widget = {
  new: (config: any, standalone = false) => {
    widget["selector"] = config?.selector || "";

    root = ReactDOM.createRoot(document.querySelector(config.selector));
    return {
      render: async () => {
        // get environment variables
        let urlEnv = "/env.js";
        if (
          !window.location.host.includes("fe-rxrenewal.luxdeepblue.com") &&
          !window.location.host.includes("onlinevisiontest.luxottica.com")
        ) {
          //get absolute path if the app is imported by an external e-commerce
          const environments = {
            local: "/public/env.js",
            dev: "https://dev-fe-rxrenewal.luxdeepblue.com/env.js",
            qa: "https://qa-onlinevisiontest.luxottica.com/env.js",
            prod: "https://onlinevisiontest.luxottica.com/env.js",
          };
          urlEnv = environments[config?.env || "prod"];
        }

        let script = document.createElement("script");
        script.type = "application/javascript";
        script.src = urlEnv;
        document.body.appendChild(script);

        //SUCCESS CASE
        script.addEventListener("load", () => {
          //if app is in standalone mode --> load utag library
          if (detectStoreId() === storeId.STANDALONE) {
            initializeUtag();
          }

          //add style to html and body if standalone mode
          if (standalone || window.rxrEnv?.STANDALONE_STYLE) {
            const htmlStyle = document.createElement("style");
            htmlStyle.innerHTML = `
            html {
              scroll-behavior: smooth;
              -ms-overflow-style: none;
              scrollbar-width: 6px;
              
              ::-webkit-scrollbar {
                width: 6px;
              }
              ::-webkit-scrollbar-track {
                background: #d9e4f5;
                border-radius: 3px;
              }
        
              ::-webkit-scrollbar-thumb {
                background: #5c6b82;
                border-radius: 3px;
                cursor: pointer;
              }
            }

            html, body {
              margin: 0;
              padding: 0;
              border: 0;
              font-size: 100%;
              vertical-align: baseline;
              overscroll-behavior-y: none;
            }
            `;
            document.head?.appendChild(htmlStyle);
          }

          root.render(
            <div
              className="OVTwidget"
              widget-id="OVTwidget"
              data-analytics_available_call="0"
            >
              <Router>
                <Provider store={store}>
                  <App config={config} />
                </Provider>
              </Router>
            </div>
          );
        });
        //ERROR CASE
        script.addEventListener("error", () => {
          console.error("Error downloading environment variables", { urlEnv });
        });
      },
    };
  },
  close: (selector?: string) => {
    let isDifferentSelector = selector
      ? selector !== (widget as any)?.selector
        ? true
        : false
      : false;

    try {
      if (widget?.["selector"]) {
        delete widget["selector"];
      }

      if (root) {
        root.unmount();
      }
    } catch (error) {
      isDifferentSelector
        ? !document.querySelector(selector) &&
          console.error(
            "Error during widget unmount. Check if the widget is actually instantiated or the selector is correct. Selector inserted: " +
              selector
          )
        : !document.querySelector(selector) &&
          console.error(
            "Error during widget unmount. The widget is already unmounted."
          );

      let eventListenerList = (window as any).RXR?.widget?.listeners;

      if (eventListenerList) {
        Object.keys(eventListenerList).forEach((key: string) => {
          window.removeEventListener(key, eventListenerList[key]);
        });
        delete widget["listeners"];
      }
    }
  },
};

declare global {
  interface Window {
    RXR: any;
    rxrEnv: any;
  }
}
window.RXR = { widget };
