import LazyImage from "@components/common/LazyImage/LazyImage";
import { useAutoFocus } from "@hooks/useAutoFocus";
import { useFocusTrap } from "@hooks/useFocusTrap";
import { printHTML, removeHTMLtags } from "@utility/htmlUtility";
import { SELECTOR_RXR } from "@utility/utility";
import React, { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Button from "../Button/component";
import style from "./style.module.scss";

type ModalSurveyProps = {
  id?: string;
  isOpen?: boolean;
  className?: string;
  htmlTitle?: string;
  htmlDescription?: string;
  children?: React.ReactNode;
  onClose: () => void;
  icon?: string;
  footerCtaAction?: () => void;
  footerCtaLabel?: string;
  footerCtaDisabled?: boolean;
  footerTopElement?: React.ReactNode;
  footerBottomElement?: React.ReactNode;
  key?: string;
  hide?: boolean;
  triggerShadow?: any;
  isNestedModalOpen?: boolean;
};

const ModalSurvey = memo(
  ({
    id = "",
    isOpen = true,
    className = "",
    htmlTitle = "",
    children,
    onClose = () => {},
    icon = "",
    footerCtaAction,
    footerCtaLabel = "",
    footerCtaDisabled = false,
    footerTopElement,
    footerBottomElement,
    key,
    hide,
    triggerShadow,
    isNestedModalOpen = false,
  }: ModalSurveyProps) => {
    useFocusTrap({
      isOpen: isOpen && !hide && !isNestedModalOpen,
      onClose,
      selector: className ? "." + className : null,
    });
    useAutoFocus({
      selector:
        isOpen && !hide && !isNestedModalOpen && className
          ? "." + className + " ." + style.closeIcon
          : "." + style.closeIcon,
    });

    useEffect(() => {
      //opening the modal, the background will be unscrollable
      if (isOpen && !hide) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.removeAttribute("style");
      }

      //unmounting the modal, overflow: hidden attribute will be removed
      return () => {
        document.body.removeAttribute("style");
      };
    }, [isOpen, hide]);

    const [scrollingPosition, setScrollingPosition] = useState<
      "top" | "middle" | "bottom" | "unscrollable"
    >("unscrollable");

    const refScroll = useRef();

    useEffect(() => {
      const handleScroll = (e) => {
        try {
          let element = e?.target;
          if (!element) {
            return;
          }
          if (!element.scrollHeight) {
            element = element.scrollingElement;
          }

          const scrollHeight = element.scrollHeight;
          const clientHeight = element.clientHeight;
          const scrollTop = element.scrollTop;

          const scrollbarPresent = scrollHeight - 1 > clientHeight;
          const arrivedToBottom =
            Math.round((100 * scrollTop) / (scrollHeight - clientHeight)) || 0;

          setScrollingPosition(
            scrollHeight <= clientHeight
              ? "unscrollable"
              : scrollTop === 0
              ? "top"
              : scrollbarPresent && arrivedToBottom < 97
              ? "middle"
              : "bottom"
          );
        } catch (e) {
          // console.error("error during shadow scroll handling in ModalSurvey", e);
        }
      };

      const clean = () => {
        if (refScroll.current) {
          refScroll.current.removeEventListener("scroll", handleScroll);
          refScroll.current.removeEventListener("resize", handleScroll);
        }
      };

      if (refScroll.current) {
        clean();
        refScroll.current.addEventListener("scroll", handleScroll);
        refScroll.current.addEventListener("resize", handleScroll);

        setTimeout(() => {
          handleScroll({ target: refScroll.current });
        }, 100);
      }

      return () => {
        clean();
      };
    }, [refScroll, triggerShadow]);

    return isOpen
      ? createPortal(
          <div
            className={`${style.modal} ${className} ${hide ? style.none : ""}`}
            key={key}
            aria-modal="true"
            role="dialog"
            aria-labelledby={"popupTitle" + id}
          >
            <div className={`${style.modalBody}`}>
              <div
                className={`${style.titleRow} ${
                  scrollingPosition !== "top" &&
                  scrollingPosition !== "unscrollable"
                    ? style.topShadow
                    : ""
                }`}
              >
                <div className={style.titleContainer}>
                  {icon && (
                    <LazyImage
                      className={style.titleIcon}
                      src={icon}
                      alt={""}
                      width={"20"}
                      height={"20"}
                    />
                  )}
                  <p
                    className={style.htmlTitle}
                    dangerouslySetInnerHTML={printHTML(htmlTitle)}
                    id={"popupTitle" + id}
                  />
                </div>
                <Button
                  className={style.closeIcon}
                  variant="underline"
                  icon="close"
                  onClick={onClose}
                  analyticsInfo={{
                    "data-analytics_available_call": 0,
                  }}
                />
              </div>
              <div className={style.descriptionSection} ref={refScroll}>
                {children}
              </div>
              <div
                className={`${style.footer} ${
                  scrollingPosition !== "bottom" &&
                  scrollingPosition !== "unscrollable"
                    ? style.bottomShadow
                    : ""
                }`}
              >
                {!!footerTopElement && footerTopElement}
                {footerCtaAction && (
                  <Button
                    variant="dark"
                    className={style.footerCta}
                    disabled={footerCtaDisabled}
                    onClick={footerCtaAction}
                    analyticsInfo={{
                      "data-analytics_available_call": 0,
                    }}
                  >
                    {footerCtaLabel}
                  </Button>
                )}
                {!!footerBottomElement && footerBottomElement}
              </div>
            </div>
          </div>,
          document.querySelector(SELECTOR_RXR)
        )
      : null;
  }
);

export default ModalSurvey;
