import React, { memo } from "react";
import { clsx } from "@utility/utility";
import style from "./style.module.scss";

type IconCircleModalProps = {
  children?: React.ReactNode;
  className?: string;
  classNameCircle?: string;
  inAcuityTestFlow?: boolean;
};

const IconCircleModal = memo(
  ({
    className = "",
    classNameCircle = "",
    children,
    inAcuityTestFlow = false,
  }: IconCircleModalProps) => {
    return (
      <div
        className={clsx(style.ring, {
          [className]: !!className,
          [style.inAcuityTestFlow]: inAcuityTestFlow,
        })}
        style={{
          flexShrink: 0,
        }}
      >
        <div
          className={clsx(style.circle, {
            [classNameCircle]: !!classNameCircle,
            [style.inAcuityTestFlow]: inAcuityTestFlow,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default IconCircleModal;
