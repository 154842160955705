import React, { memo, useState } from "react";
import Button from "../Button/component";
import { createPortal } from "react-dom";
import style from "./style.module.scss";
import { printHTML } from "@utility/htmlUtility";
import { handleOnEnterKeyPress, SELECTOR_RXR } from "@utility/utility";
import { useTranslation } from "@hooks/useTranslation";
import { selectLabelMap } from "@slices/utilitySlice";
import { useSelector } from "react-redux";
import LazyImage from "../LazyImage/LazyImage";

type TopicCategoryComponentProps = {
  isOpen: boolean;
  ariaLabel?: string;
  topicKey: string;
  onClick: () => void;
  onQuestionOpen: (topicKey: string, questionKey: string) => void;
};

const TopicCategoryComponent = ({
  isOpen = false,
  ariaLabel = "",
  topicKey = "",
  onClick = null,
  onQuestionOpen = null,
}: TopicCategoryComponentProps) => {
  const translate = useTranslation();
  const FAQsLabels = useSelector(selectLabelMap)?.faq || {};

  //regex to retrieve all questions related to a certain topic key
  const allTopicQuestionRegex = new RegExp(`^${topicKey}_question_\\d+$`);

  return (
    <div
      className={`${style.topicContainer} ${isOpen ? style.isOpen : ""}`}
      onClick={onClick}
      data-analytics_available_call={0}
    >
      <div
        className={style.topicTitleContainer}
        tabIndex={0}
        onKeyDown={(e) => handleOnEnterKeyPress(e, onClick, true, true)}
        aria-expanded={isOpen}
        aria-label={`${translate("faq." + topicKey)} ${
          isOpen ? "expanded" : "collapsed"
        }`}
      >
        <span aria-hidden>{translate("faq." + topicKey)}</span>
        <LazyImage
          ariaHidden={true}
          className={`${style.topicContainerArrow} ${
            isOpen ? style.isOpen : ""
          }`}
          src={window.rxrEnv?.ASSETS_PATH + "/icons/right-arrow.svg"}
          alt={translate("wcag.wcag_right_arrow_icon")}
          width={14}
          height={14}
        />
      </div>

      {/*show all question keys given a topic key*/}
      {isOpen &&
        Object.keys(FAQsLabels)
          .filter((key) => allTopicQuestionRegex.test(key))
          .map((questionKey) => (
            <button
              className={style.topicQuestionsContainer}
              key={questionKey}
              onClick={(e) => {
                onQuestionOpen(topicKey, questionKey);
                e.stopPropagation();
              }}
              data-analytics_available_call={0}
            >
              {translate("faq." + questionKey)}
              <LazyImage
                src={window.rxrEnv?.ASSETS_PATH + "/icons/right-arrow.svg"}
                alt={translate("wcag.wcag_right_arrow_icon")}
                width={14}
                height={14}
              />
            </button>
          ))}
    </div>
  );
};

export default TopicCategoryComponent;
