import IconCircleModal from "@components/common/IconCircleModal/component";
import { useAutoFocus } from "@hooks/useAutoFocus";
import { useFocusTrap } from "@hooks/useFocusTrap";
import { printHTML } from "@utility/htmlUtility";
import {
  getCheckLottie,
  getErrorLottie,
  getIconExclamationMarkLottie,
  getSwitchLottie,
  getTimeoutLottie,
} from "@utility/lottie";
import { SELECTOR_RXR, clsx } from "@utility/utility";
import Lottie from "lottie-react";
import React, { memo, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Button from "../Button/component";
import style from "./style.module.scss";

type ModalCheckProps = {
  className?: string;
  classNameBody?: string;
  isOpen?: boolean;
  timeoutMm?: number;
  callbackAfterTimeout?: () => void;
  message?: string;
  messageReverse?: boolean;
  description?: string;
  subdescription?: string;
  audio?: string;
  onEndedAudio?: () => void;
  closeAction?: () => void;
  escAction?: () => void;
  inAcuityTestFlow?: boolean;
  icon?: "check" | "exclamation" | "switch" | "error" | "timeout";
  ctaAction?: () => void;
  ctaLabel?: string;
  ctaDisabled?: boolean;
  ctaAnalyticsInfo?: any;
  ctaPrimaryAction?: () => void;
  ctaPrimaryLabel?: string;
  ctaNoFooter?: boolean;
};

const ModalCheck = memo(
  ({
    className = "",
    classNameBody = "",
    isOpen = true,
    timeoutMm = 3000,
    callbackAfterTimeout,
    message = "",
    messageReverse = false,
    description = "",
    subdescription = "",
    audio = "",
    onEndedAudio,
    closeAction,
    escAction,
    inAcuityTestFlow = false,
    icon = "check",
    ctaAction,
    ctaLabel = "",
    ctaDisabled = false,
    ctaAnalyticsInfo = {},
    ctaPrimaryAction,
    ctaPrimaryLabel = "",
    ctaNoFooter = false,
  }: ModalCheckProps) => {
    useFocusTrap({
      isOpen: true,
      onClose: escAction || closeAction,
      selector: "." + style.modal,
    });
    useAutoFocus({
      selector: closeAction ? "." + style.closeIcon : "." + style.cta,
    });

    const [iconCheckLottie, setIconCheckLottie] = useState();
    const [iconExclamationMarkLottie, setIconExclamationMarkLottie] =
      useState();
    const [errorLottie, setErrorLottie] = useState();
    const [switchLottie, setSwitchLottie] = useState();
    const [timeoutLottie, setTimeoutLottie] = useState();
    const [shrinkForSmallDevices, setShrinkForSmallDevices] = useState(false);

    useEffect(() => {
      const result = messageReverse && message?.length > 40;
      if (result !== shrinkForSmallDevices) {
        setShrinkForSmallDevices(result);
      }
    }, [messageReverse, message]);

    useEffect(() => {
      const useEffectLogic = async () => {
        switch (icon) {
          case "check":
            setIconCheckLottie(await getCheckLottie());
            break;
          case "exclamation":
            setIconExclamationMarkLottie(await getIconExclamationMarkLottie());
            break;
          case "error":
            setErrorLottie(await getErrorLottie());
            break;
          case "switch":
            setSwitchLottie(await getSwitchLottie());
            break;
          case "timeout":
            setTimeoutLottie(await getTimeoutLottie());
            break;
        }
      };
      useEffectLogic();
    }, [icon]);

    useEffect(() => {
      setTimeout(() => {
        if (callbackAfterTimeout) {
          callbackAfterTimeout();
        }
      }, timeoutMm);
    }, []);

    const onAudioError = () => {
      setTimeout(() => {
        if (onEndedAudio) {
          onEndedAudio();
        }
      }, timeoutMm);
    };

    useEffect(() => {
      setTimeout(() => {
        if (onEndedAudio) {
          const audioElem = document.getElementById(
            "modalCheckAudio"
          ) as HTMLAudioElement;
          if (audioElem?.paused) {
            //if audio is not going --> call onEndedAudio() after 5s
            setTimeout(() => {
              onEndedAudio();
            }, 5000);
          }
        }
      }, 2000);
    }, []);

    const ctaSection = (
      <>
        {(ctaAction || ctaPrimaryAction) && (
          <div
            style={
              ctaNoFooter
                ? {
                    width: "100%",
                    padding: "8px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                    marginTop: "auto",
                  }
                : {
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    padding: "8px 24px",
                    background: "rgba(32, 35, 61, 0.5)", //$dark-blue-button-color #20233d
                    backdropFilter: "blur(10px)",
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                    WebkitBackdropFilter: "blur(10px)",
                  }
            }
          >
            {ctaPrimaryAction && (
              <Button
                className={`${style.cta} ${style.ctaDark}`}
                variant="dark"
                onClick={ctaPrimaryAction}
                analyticsInfo={{
                  "data-analytics_available_call": 0,
                }}
              >
                {ctaPrimaryLabel}
              </Button>
            )}
            {ctaAction && (
              <Button
                className={style.cta}
                variant="lightBlue"
                onClick={ctaAction}
                analyticsInfo={{
                  "data-analytics_available_call": 0,
                  ...ctaAnalyticsInfo,
                }}
                disabled={ctaDisabled}
              >
                {ctaLabel}
              </Button>
            )}
          </div>
        )}
      </>
    );

    return isOpen
      ? createPortal(
          <div
            className={`${style.modal} ${className} ${
              shrinkForSmallDevices ? style.shrinkForSmallDevices : ""
            } ${messageReverse ? style.modalReverse : ""}`}
            role="dialog"
            aria-modal="true"
            aria-labelledby={message}
          >
            {closeAction && (
              <Button
                className={style.CloseIcon}
                variant="underline"
                icon="close"
                onClick={closeAction}
                analyticsInfo={{
                  "data-analytics_available_call": 0,
                }}
              />
            )}

            <div className={`${style.modalBody} ${classNameBody}`}>
              <IconCircleModal
                inAcuityTestFlow={inAcuityTestFlow}
                className={`${style.iconCircleModal} ${
                  messageReverse ? style.reverse : ""
                }`}
              >
                <>
                  {icon === "check" && iconCheckLottie && (
                    <Lottie
                      animationData={iconCheckLottie}
                      loop={false}
                      width={"185px"}
                      height={"185px"}
                    />
                  )}
                  {icon === "exclamation" && iconExclamationMarkLottie && (
                    <Lottie
                      animationData={iconExclamationMarkLottie}
                      loop={false}
                      width={"185px"}
                      height={"185px"}
                    />
                  )}
                  {icon === "error" && (
                    <Lottie
                      animationData={errorLottie}
                      loop={false}
                      width={"185px"}
                      height={"185px"}
                    />
                  )}
                  {icon === "switch" && (
                    <Lottie
                      animationData={switchLottie}
                      loop={false}
                      width={"185px"}
                      height={"185px"}
                    />
                  )}
                  {icon === "timeout" && (
                    <Lottie
                      animationData={timeoutLottie}
                      loop={false}
                      width={"185px"}
                      height={"185px"}
                    />
                  )}
                </>
              </IconCircleModal>

              {message && (
                <p
                  className={clsx(style.message, {
                    [style.reverse]: messageReverse,
                    [style.inAcuityTestFlow]: inAcuityTestFlow,
                  })}
                >
                  {message}
                </p>
              )}

              {description && (
                <p
                  className={clsx(style.description, {
                    [style.reverse]: messageReverse,
                  })}
                  dangerouslySetInnerHTML={printHTML(description)}
                />
              )}

              {subdescription && (
                <p
                  className={clsx(style.subdescription, {
                    [style.reverse]: messageReverse,
                  })}
                  dangerouslySetInnerHTML={printHTML(subdescription)}
                />
              )}

              {ctaNoFooter && ctaSection}
            </div>

            {!ctaNoFooter && ctaSection}

            {audio && (
              <audio
                id="modalCheckAudio"
                src={audio}
                autoPlay={true}
                controls={false}
                playsInline={true}
                webkit-playsinline="true"
                preload="none"
                onEnded={onEndedAudio}
                onError={onAudioError}
              />
            )}
          </div>,
          document.querySelector(SELECTOR_RXR)
        )
      : null;
  }
);

export default ModalCheck;
