import { useEffect, useLayoutEffect } from "react";

interface useFocusTrapParams {
  runAutofocus?: boolean;
  selector: string;
}
export const useAutoFocus = ({
  runAutofocus = true,
  selector = "",
}: useFocusTrapParams): void => {
  useLayoutEffect(() => {
    if (runAutofocus && selector) {
      document.querySelector(selector)?.focus();
    }
  }, [runAutofocus, selector]);

  return;
};
