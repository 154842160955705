import axios from "axios";

export const getCheckLottie = async () => {
  const result = await axios.get(
    window.rxrEnv?.ASSETS_PATH + "/lottie/check.json"
  );
  return result?.data;
};

export const getIconExclamationMarkLottie = async () => {
  const result = await axios.get(
    window.rxrEnv?.ASSETS_PATH + "/lottie/exclamation-mark.json"
  );
  return result?.data;
};

export const getErrorLottie = async () => {
  const result = await axios.get(
    window.rxrEnv?.ASSETS_PATH + "/lottie/error.json"
  );
  return result?.data;
};

export const getSwitchLottie = async () => {
  const result = await axios.get(
    window.rxrEnv?.ASSETS_PATH + "/lottie/switch.json"
  );
  return result?.data;
};

export const getTimeoutLottie = async () => {
  const result = await axios.get(
    window.rxrEnv?.ASSETS_PATH + "/lottie/time-out.json"
  );
  return result?.data;
};

export const getLoaderLottie = async () => {
  const result = await axios.get(
    window.rxrEnv?.ASSETS_PATH + "/lottie/loader.json"
  );
  return result?.data;
};

export const getWhiteLoaderLottie = async () => {
  const result = await axios.get(
    window.rxrEnv?.ASSETS_PATH + "/lottie/loader_white.json"
  );
  return result?.data;
};
