import dompurify from "dompurify";

export const printHTML = (label: string, sanitizerOptions = {}) => {
  return { __html: sanitize(label, sanitizerOptions) };
};

export const printHTMLIframe = (iframe, removeStyle = true) => {
  //remove style attribute
  if (removeStyle) {
    iframe = iframe.replace(/style="[^"]*"/, "");
  }

  return {
    __html: sanitize(iframe, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["allow", "allowfullscreen", "frameborder"],
    }),
  };
};

export const sanitize = (label: string, sanitizerOptions = {}): string => {
  const sanitizer = dompurify.sanitize;
  return sanitizer(label, sanitizerOptions);
};

export const removeHTMLtags = (label: string): string => {
  return sanitize(label, { USE_PROFILES: { html: false } });
};
