import { useEffect } from "react";

interface useFocusTrapParams {
  isOpen: boolean;
  onClose: () => void;
  selector: string;
  handleEscButton?: boolean;
  handleFocusTrap?: boolean;
}
export const useFocusTrap = ({
  isOpen = false,
  onClose = () => {},
  selector = "",
  handleEscButton = true,
  handleFocusTrap = true,
}: useFocusTrapParams): void => {
  useEffect(() => {
    if (handleEscButton) {
      //handle close modal at ESC key down
      const handleKeyPress = (e) => {
        if (e.key === "Escape" && isOpen) {
          onClose();
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    if (handleFocusTrap && isOpen) {
      //handle trap focus
      document.addEventListener("keydown", trapFocus);
    } else {
      document.removeEventListener("keydown", trapFocus);
    }

    return () => {
      document.removeEventListener("keydown", trapFocus);
    };
  }, [isOpen]);

  function trapFocus(event) {
    if (selector) {
      const focusableElements = document
        .querySelector(selector)
        ?.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
      const firstElement = focusableElements?.[0];
      const lastElement = focusableElements?.[focusableElements?.length - 1];

      if (event?.key === "Tab" && firstElement && lastElement) {
        if (event.shiftKey) {
          if (document.activeElement === firstElement) {
            lastElement.focus();
            event.preventDefault();
          }
        } else {
          if (document.activeElement === lastElement) {
            firstElement.focus();
            event.preventDefault();
          }
        }
      }
    }
  }

  return;
};
