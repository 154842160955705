import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getEndpoint, getHeaders, mockService } from "@utility/networkUtility";
import {
  cleanSessionStorage,
  sessionStorageIds,
} from "@utility/sessionStorageUtility";
import { disablePreventUserExit, isDeviceIos } from "@utility/utility";
import axios from "axios";
import { RootState } from "../Store";

export type LabelMapType = {
  [key: string]: string | LabelMapType;
};

export type StateUtility = {
  labelMap: LabelMapType;
  labelInfo: {
    language: string;
    isLoading: boolean;
    isLoaded: boolean;
  };
  firstTimeInStartPage1: boolean;
  showErrorPopup: boolean;
  showTimeoutPopup: boolean;
  labelErrorPopup: string;
  errorModalRetryCloseModal: boolean;
  analyticsInfoErrorPopup: any;
  cannotGoBackPopup: boolean;
  acuityEnd: boolean;
  historyStack: string[];
};

const initialState: StateUtility = {
  labelMap: {},
  labelInfo: {
    language: "",
    isLoading: false,
    isLoaded: false,
  },
  firstTimeInStartPage1: true,
  showErrorPopup: false,
  showTimeoutPopup: false,
  labelErrorPopup: "",
  errorModalRetryCloseModal: false,
  analyticsInfoErrorPopup: {},
  cannotGoBackPopup: false,
  acuityEnd: false,
  historyStack: [],
};

const utilitySlice = createSlice({
  name: "utility",
  initialState: initialState,
  reducers: {
    requestGetLabelMap: (state) => {
      state.labelInfo = {
        ...state.labelInfo,
        isLoading: true,
      };
    },
    successGetLabelMap: (
      state,
      { payload }: PayloadAction<{ labelMap: LabelMapType; language: string }>
    ) => {
      state.labelMap = payload.labelMap;
      state.labelInfo = {
        language: payload.language,
        isLoading: false,
        isLoaded: true,
      };
    },
    failGetLabelMap: (state) => {
      state.labelInfo = {
        ...state.labelInfo,
        isLoading: false,
        isLoaded: true,
      };
    },
    setShowErrorPopup: (
      state,
      {
        payload,
      }: PayloadAction<{
        showErrorPopup: boolean;
        label?: string;
        analyticsInfo?: any;
        retryCloseModal?: boolean;
      }>
    ) => {
      state.showErrorPopup = payload.showErrorPopup;
      state.labelErrorPopup = payload.showErrorPopup ? payload.label : "";
      state.errorModalRetryCloseModal = payload.showErrorPopup
        ? payload.retryCloseModal
        : false;
      state.analyticsInfoErrorPopup = payload.analyticsInfo || {};
    },
    setShowTimeoutPopup: (
      state,
      { payload }: PayloadAction<{ showTimeoutPopup: boolean }>
    ) => {
      state.showTimeoutPopup = payload.showTimeoutPopup;
    },
    setFirstTimeInStartPage1: (
      state,
      { payload }: PayloadAction<{ firstTimeInStartPage1: boolean }>
    ) => {
      state.firstTimeInStartPage1 = payload.firstTimeInStartPage1;
    },
    setCannotGoBackPopup: (
      state,
      { payload }: PayloadAction<{ cannotGoBackPopup: boolean }>
    ) => {
      state.cannotGoBackPopup = payload.cannotGoBackPopup;
    },
    setAcuityEnd: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.acuityEnd = payload.value;
    },
    addUrlToHistoryStack: (
      state,
      { payload }: PayloadAction<{ url: string; replace?: boolean }>
    ) => {
      if (payload.url === state.historyStack[state.historyStack.length - 1]) {
        return;
      } else if (
        payload.url === state.historyStack[state.historyStack.length - 2]
      ) {
        state.historyStack.pop();
      } else if (payload.replace) {
        state.historyStack[state.historyStack.length - 1] = payload.url;
      } else {
        state.historyStack.push(payload.url);
      }
    },
    reset: () => initialState,
  },
});

////ASYNC ACTIONS////
const handleGLorCLLabel = (labelMap, soldProducts) => {
  if (soldProducts && labelMap) {
    Object.keys(labelMap)?.forEach((key) => {
      const content = labelMap[key];

      if (typeof content === "object") {
        labelMap[key] = handleGLorCLLabel(content, soldProducts);
      } else {
        const soldProductsKey = key + "_" + soldProducts;
        if (labelMap[soldProductsKey]) {
          labelMap[key] = labelMap[soldProductsKey];
        }
      }
    });
  }

  return labelMap;
};

export const getLabelMap = createAsyncThunk(
  "utility/getLabelMap",
  async (
    params: { language: string; returnKeys?: boolean },
    { dispatch, getState }
  ) => {
    const { language: languageFromParam, returnKeys = false } = params || {};

    let userLang =
      languageFromParam || navigator.language || navigator.userLanguage || "";

    if (!userLang) {
      userLang = "en-US";
    }

    const mock = false;
    let labelMapTemp: LabelMapType = {};
    const url = getEndpoint("getLabels")?.replace("{locale}", userLang);

    dispatch(utilityActions.requestGetLabelMap());

    (mock
      ? mockService("/mock/labels/labels.default.json")
      : axios({
          url,
          method: "GET",
          headers: getHeaders("application/json", false),
        })
    )
      .then((response) => {
        if (response.data) {
          labelMapTemp = response.data;
        }

        if (returnKeys) {
          //to test which key is associated to a specific label
          Object.keys(labelMapTemp)?.forEach((key) => {
            labelMapTemp[key] = key;
          });
        }

        const state: RootState = getState() as RootState;
        const config = state.config.config.config;

        if (config.soldProducts) {
          labelMapTemp = handleGLorCLLabel(labelMapTemp, config.soldProducts);
        }

        dispatch(
          utilityActions.successGetLabelMap({
            labelMap: labelMapTemp,
            language: userLang,
          })
        );
      })
      .catch((error) => {
        const errorLabel = "Error getting labels";
        console.error(errorLabel, { error });
        dispatch(
          utilityActions.setShowErrorPopup({
            showErrorPopup: true,
            analyticsInfo: {
              Error_Details:
                errorLabel +
                "\n --- \n " +
                url +
                "\n --- \n" +
                JSON.stringify(error || {}),
            },
          })
        );
        dispatch(utilityActions.failGetLabelMap());
      });
  }
);

export const invokeEcommerceFunction = createAsyncThunk(
  "utility/invokeEcommerceFunction",
  async (
    params: {
      functionId:
        | "addNewPrescriptionToCart"
        | "writeToken"
        | "updateAssessmentStatus";
      finallyCallback?: () => void;
      connectToken?: string;
    },
    { dispatch, getState }
  ) => {
    if (!params.functionId) {
      console.error(
        "invokeEcommerceFunction - params.functionId not specified",
        { params }
      );
      if (params.finallyCallback) {
        params.finallyCallback();
      }
      return;
    }

    const state: RootState = getState() as RootState;
    const config = state.config.config.config;

    //params are described here https://luxotticaretail.atlassian.net/wiki/spaces/DO/pages/2483291061/eCommerce+integration#Callback-function-(RXRenewal-%3E-eComm)
    //current structure [connectID, patientID, userID, opthyEligibility]
    const connectToken: string =
      params.connectToken ||
      sessionStorage.getItem(sessionStorageIds.CONNECT) ||
      config.connectId ||
      "";
    const patientId: string =
      sessionStorage.getItem(sessionStorageIds.PATIENT_ID) ||
      config.patientId ||
      "";
    const userId: string =
      sessionStorage.getItem(sessionStorageIds.USER_ID) || config.userId || "";

    //param ophty eligibility is true if the user doesn't know its pupillary distance and is using an iOS device
    let opthyEligibility = false;
    if (isDeviceIos()) {
      const dontKnowPupillaryDistance =
        state.survey.TBTInfo?.payload?.eligibility_questionnarie?.find(
          (question) => question.q_pupillary_distance
        )?.q_pupillary_distance?.dontKnow;
      if (dontKnowPupillaryDistance) {
        opthyEligibility = true;
      }
    }

    const ecommerceFunctionParams: [string, string, string, boolean] = [
      connectToken,
      patientId,
      userId,
      opthyEligibility,
    ];

    switch (params.functionId) {
      case "addNewPrescriptionToCart":
        console.log("addNewPrescriptionToCart params", {
          ecommerceFunctionParams,
        });
        if (config.addNewPrescriptionToCart) {
          disablePreventUserExit();
          config.addNewPrescriptionToCart(ecommerceFunctionParams);
        } else {
          const errorLabel = "addNewPrescriptionToCart method not found";
          console.error(errorLabel, {
            config: config.config,
          });
          dispatch(
            utilityActions.setShowErrorPopup({
              showErrorPopup: true,
              analyticsInfo: {
                Error_Source: "Client",
                Error_Details:
                  errorLabel +
                  "\n --- \n" +
                  JSON.stringify(config.config || {}),
              },
            })
          );
        }
        break;
      case "writeToken":
        console.log("writeToken params", { ecommerceFunctionParams });
        if (config.writeToken) {
          config.writeToken(ecommerceFunctionParams);
        } else {
          const errorLabel = "writeToken method not found";
          console.error(errorLabel, {
            config: config.config,
          });
        }
        break;
      case "updateAssessmentStatus":
        console.log("updateAssessmentStatus params", {
          ecommerceFunctionParams,
        });

        //clean session storage because the user is leaving Rxr
        cleanSessionStorage();

        if (config.updateAssessmentStatus) {
          config.updateAssessmentStatus(ecommerceFunctionParams);
        } else {
          const errorLabel = "updateAssessmentStatus method not found";
          console.error(errorLabel, {
            config: config.config,
          });
        }
        break;
      default:
        console.error("invokeEcommerceFunction - function not found", {
          params,
          ecommerceFunctionParams,
        });
    }

    if (params.finallyCallback) {
      params.finallyCallback();
    }
  }
);

////SELECTORS////
export const selectLabelMap = (state: RootState) => {
  return state.utility.labelMap;
};
export const selectLabelInfo = (state: RootState) => {
  return state.utility.labelInfo;
};
export const selectShowErrorPopup = (state: RootState) => {
  return state.utility.showErrorPopup;
};
export const selectShowTimeoutPopup = (state: RootState) => {
  return state.utility.showTimeoutPopup;
};
export const selectLabelErrorPopup = (state: RootState) => {
  return state.utility.labelErrorPopup;
};
export const selectErrorModalRetryCloseModal = (state: RootState) => {
  return state.utility.errorModalRetryCloseModal;
};
export const selectAnalyticsInfoErrorPopup = (state: RootState) => {
  return state.utility.analyticsInfoErrorPopup;
};
export const selectFirstTimeInStartPage1 = (state: RootState) => {
  return state.utility.firstTimeInStartPage1;
};
export const selectCannotGoBackPopup = (state: RootState) => {
  return state.utility.cannotGoBackPopup;
};
export const selectAcuityEnd = (state: RootState) => {
  return state.utility.acuityEnd;
};
export const selectHistoryStack = (state: RootState) => {
  return state.utility.historyStack;
};

export const utilityReducer = utilitySlice.reducer;
export const utilityActions = utilitySlice.actions;
export const utilityAsyncActions = { getLabelMap, invokeEcommerceFunction };
