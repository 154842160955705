import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.scss";

type DottedIndex = {
  currentStepIndex: number;
  totalSteps: number;
};

export default function DottedIndex({
  currentStepIndex,
  totalSteps,
}: DottedIndex) {
  return (
    <div className={style.DottedIndexContainer}>
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={`DottedIndex-${index}`}
          className={`${style.dottetIndexStep} ${
            index === currentStepIndex ? style.indexActive : ""
          }`}
        />
      ))}
    </div>
  );
}
