import React from "react";
import style from "./style.module.scss";

type ProgressBarAcuityProps = {
  value: number;
  className?: string;
  revert?: boolean;
};

export default function ProgressBarAcuity({
  value = 0,
  className = "",
  revert = true,
}: ProgressBarAcuityProps) {
  return (
    <div
      className={`${style.progressBarAcuity} ${className} ${
        revert ? style.revert : ""
      }`}
    >
      <div
        className={style.progressBarValue}
        style={{ "--progressBarValue": value + "%" }}
      />
    </div>
  );
}
