export default class OnlineVisionTestConfig {
  config: IOnlineVisionTestConfig;

  constructor(config: IOnlineVisionTestConfig) {
    this.config = config;

    if (this.config) {
      if (!config?.supportEmail) {
        this.config.supportEmail = "support@contactsdirect.com";
      }

      if (
        !config?.soldProducts ||
        !["gl", "cl"].includes(config?.soldProducts?.toLowerCase())
      ) {
        //set default value
        this.config.soldProducts = "";
      } else {
        //be sure that GL and CL are in uppercase
        this.config.soldProducts = config.soldProducts?.toLowerCase();
      }
    }
  }
}

// https://luxotticaretail.atlassian.net/wiki/spaces/DO/pages/2483291061/eCommerce+integration
export type IOnlineVisionTestConfig = {
  myAccountLink: string;
  brand: string;
  selector: string;
  language: string;
  name: string;
  surname: string;
  mail: string;
  birthdayDate: string;
  headerBackLinkUrl: string;
  brandName: string;
  brandLogo: string;
  shopGlassesContactLensesLink: string;
  findDoctorLink: string;
  addNewPrescriptionToCart: IOnlineVisionTestConfigFunction["function"];
  prescriptionCost: string;
  env?: "local" | "dev" | "qa" | "prod";
  connectId?: string;
  patientId?: string; //mandatory only for the first initialization
  userId?: string; //mandatory only for the first initialization
  writeToken?: IOnlineVisionTestConfigFunction["function"];
  updateAssessmentStatus?: IOnlineVisionTestConfigFunction["function"];
  supportEmail?: string;
  soldProducts?: "" | "gl" | "cl";
};

export interface IOnlineVisionTestConfigFunction {
  function: (params: [string, string, string, boolean]) => void;
}
