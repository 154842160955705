import { useEffect, useState } from "react";
export const useExternalScript = (
  url: string,
  onLoad?: () => void,
  onError?: () => void
) => {
  let [state, setState] = useState(url ? "loading" : "idle");

  useEffect(() => {
    if (!url) {
      setState("idle");
      return;
    }
    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      const isLoaded = e.type === "load";
      setState(isLoaded ? "ready" : "error");

      if (isLoaded) {
        if (onLoad) {
          onLoad();
        }
      } else {
        if (onError) {
          onError();
        }
      }
    };

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);

      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);

      return () => {
        script.removeEventListener("load", handleScript);
        script.removeEventListener("error", handleScript);
      };
    } else {
      handleScript({ type: "load" });
    }
  }, [url]);

  return state;
};
