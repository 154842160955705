import stylePageCardMobile from "@components/PageCardMobile/style.module.scss";
import InfoBanner from "@components/common/InfoBanner/component";
import OrSeparator from "@components/common/OrSeparator/component";
import PageStandard from "@components/common/PageStandard/component";
import ResultSidebar from "@components/common/ResultSidebar/component";
import UploadButton from "@components/common/UploadButton/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import {
  CurrentPrescriptionType,
  acuityAsyncActions,
  selectCurrentPrescription,
  selectCurrentPrescriptionIsLoading,
} from "@slices/acuitySlice";
import { printHTML } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import { sessionStorageIds } from "@utility/sessionStorageUtility";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";

const formId = "currentPrescription";

type CurrentPrescriptionPageProps = {};

function CurrentPrescriptionPage(props: CurrentPrescriptionPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslation();
  const breakpoint = useBreakpointBoolean();

  const surveySubmittedHere =
    sessionStorage.getItem(sessionStorageIds.SURVEY_SUBMITTED_HERE) === "true"
      ? true
      : false;
  const currentPrescriptionIsLoading = useSelector(
    selectCurrentPrescriptionIsLoading
  );
  const currentPrescriptionStored = useSelector(selectCurrentPrescription);

  const formData = useForm<{ [formId]: CurrentPrescriptionType }>({
    mode: "onChange",
  });
  const currentPrescription = formData?.watch(formId);

  const onSubmit = () => {
    console.log("currentPrescription", { currentPrescription });
    if (currentPrescription) {
      dispatch(
        acuityAsyncActions.sendCurrentPrescription({
          currentPrescription,
          onSuccessCallback: () => {
            navigate(ROUTING.COMMENT.url);
          },
          onErrorCallback: () => {
            // formData.setValue(formId, undefined);
          },
        })
      );
    } else {
      console.error("Uploaded file not found", { currentPrescription });
    }
  };

  const handleStepChange = (stepIndex: number) => {
    if (stepIndex != undefined) {
      switch (stepIndex) {
        case 1:
          navigate(ROUTING.CURRENT_PRESCRIPTION.url);
          break;
        case 2:
          navigate(ROUTING.COMMENT.url);
          break;
      }
    }
  };

  return (
    <PageStandard
      className={`${style.Background} ${stylePageCardMobile.pageCardMobile}`}
      classNamePageBody={stylePageCardMobile.pageCardMobileBody}
      footerInfo={{
        showLeftButton: !breakpoint.isDesktopOrTabletLandscape,
        iconLeftButton: "leftArrow",
        classNameLeftButton: stylePageCardMobile.footerLeftButton,
        showRightButton: true,
        classNameRightButton: stylePageCardMobile.footerRightButton,
        labelRightButton: translate("footer.next"),
        actionRightButton: onSubmit,
        disabledRightButton:
          !formData?.formState?.isValid || currentPrescriptionIsLoading,
        backgroundWhite: true,
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div
        className={`${stylePageCardMobile.overPageContainer} ${style.overPageContainer}`}
      >
        <p className={stylePageCardMobile.overPageTitle}>
          {translate("current_prescription.over_title")}
        </p>

        <p
          className={stylePageCardMobile.overPageSteps}
          dangerouslySetInnerHTML={printHTML(
            translate("current_prescription.steps_recap")
              ?.replace(
                "{{step}}",
                surveySubmittedHere || breakpoint.isDesktopOrTabletLandscape
                  ? "1"
                  : "2"
              )
              .replace(
                "{{totalSteps}}",
                surveySubmittedHere || breakpoint.isDesktopOrTabletLandscape
                  ? "2"
                  : "3"
              )
          )}
        />
      </div>

      <div
        className={`${stylePageCardMobile.pageBodyCard} ${stylePageCardMobile.noPaddingBodyCard}`}
      >
        {breakpoint.isDesktopOrTabletLandscape && (
          <ResultSidebar
            currentStepIndex={1}
            totalSteps={2}
            changeStepFunction={handleStepChange}
          />
        )}
        <div className={style.pageBody}>
          <div className={style.pageBodyContent}>
            <p
              className={stylePageCardMobile.title}
              dangerouslySetInnerHTML={printHTML(
                translate("current_prescription.title")
              )}
            />
            <p
              className={`${style.description}`}
              dangerouslySetInnerHTML={printHTML(
                translate("current_prescription.description")
              )}
            />

            <InfoBanner
              className={style.infoBanner}
              label={translate("current_prescription.warning")}
              variant="info"
              role="alert"
              tabIndex={0}
            />

            <Controller
              control={formData.control}
              name={formId}
              rules={{
                required: true,
              }}
              render={({ field: { onChange: controllerOnChange } }) => {
                useEffect(() => {
                  if (currentPrescriptionStored) {
                    controllerOnChange(currentPrescriptionStored);
                  }
                }, []);

                const handleOnChange = (
                  kind: "photo" | "document",
                  file: File
                ) => {
                  if (!file || !kind) {
                    controllerOnChange(undefined);
                  } else {
                    controllerOnChange({
                      kind: kind,
                      file: file,
                    });
                  }
                };

                return (
                  <div className={style.uploadSection}>
                    <UploadButton
                      classNameContainer={style.uploadPhoto}
                      id="upload-image"
                      labelKey="current_prescription.upload_or_take_photo"
                      icon={window.rxrEnv?.ASSETS_PATH + "/icons/photo.svg"}
                      format="image/png, .jpg, image/heic"
                      formatKeyLabel="current_prescription.upload_or_take_photo_format"
                      errorSizeKeyLabel="current_prescription.upload_or_take_photo_error_size"
                      errorFormatKeyLabel="current_prescription.upload_or_take_photo_error_format"
                      onChange={(file) => handleOnChange("photo", file)}
                      value={
                        currentPrescription?.kind === "photo"
                          ? currentPrescription.file
                          : undefined
                      }
                    />

                    <OrSeparator
                      className={style.uploadOrSeparator}
                      label={translate("current_prescription.or")}
                    />

                    <UploadButton
                      classNameContainer={style.uploadDocument}
                      id="upload-document"
                      labelKey="current_prescription.upload_document"
                      icon={window.rxrEnv?.ASSETS_PATH + "/icons/upload.svg"}
                      format="application/pdf"
                      formatKeyLabel="current_prescription.upload_document_format"
                      errorSizeKeyLabel="current_prescription.upload_document_error_size"
                      errorFormatKeyLabel="current_prescription.upload_document_error_format"
                      onChange={(file) => handleOnChange("document", file)}
                      value={
                        currentPrescription?.kind === "document"
                          ? currentPrescription.file
                          : undefined
                      }
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </PageStandard>
  );
}

export default CurrentPrescriptionPage;
