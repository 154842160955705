import Button from "@components/common/Button/component";
import IconCircleModal from "@components/common/IconCircleModal/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import { useUtag } from "@hooks/useUtag";
import { selectConfig } from "@slices/configSlice";
import { getTBTPayload, selectTBTInfo } from "@slices/surveySlice";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";
import { printHTML } from "@utility/htmlUtility";
import {
  getCheckLottie,
  getIconExclamationMarkLottie,
  getWhiteLoaderLottie,
} from "@utility/lottie";
import { ROUTING } from "@utility/routingUtility";
import { cleanSessionStorage } from "@utility/sessionStorageUtility";
import { SELECTOR_RXR, clsx, exitRxr } from "@utility/utility";
import Lottie from "lottie-react";
import QRCode from "qrcode.react";
import React, { memo, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import { useFocusTrap } from "@hooks/useFocusTrap";
import { useAutoFocus } from "@hooks/useAutoFocus";

type ModalQrCodeSurveyProps = {
  className?: string;
  isOpen?: boolean;
  timeoutMm?: number;
  callbackAfterTimeout?: () => void;
  message?: string;
  messageReverse?: boolean;
  description?: string;
  subdescription?: string;
  messageLoading?: string;
  descriptionLoading?: string;
  qrCode?: {
    src: string;
    description: string;
  };
  closeAction?: () => void;
  icon?: "check" | "exclamation";
  ctaAction?: () => void;
  ctaLabel?: string;
  ctaPrimaryAction?: () => void;
  ctaPrimaryLabel?: string;
  ctaNoFooter?: boolean;
  isDesktopOrTabletLandscape?: boolean;
};

const ModalQrCodeSurvey = memo(
  ({
    className = "",
    isOpen = true,
    timeoutMm = 3000,
    callbackAfterTimeout,
    message = "",
    messageReverse = false,
    description = "",
    subdescription = "",
    messageLoading,
    descriptionLoading,
    closeAction,
    icon = "check",
    ctaAction,
    ctaLabel = "",
    ctaPrimaryAction,
    ctaPrimaryLabel = "",
    ctaNoFooter = false,
    qrCode,
    isDesktopOrTabletLandscape = false,
  }: ModalQrCodeSurveyProps) => {
    useFocusTrap({
      isOpen: true,
      onClose: () => {},
      selector: "." + style.modal,
    });
    useAutoFocus({
      selector: "." + style.modalQrCodeCompressButton,
    });

    const translate = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const breakpoint = useBreakpointBoolean();

    const TBTInfo = useSelector(selectTBTInfo);
    const config = useSelector(selectConfig);

    const [showModalQrCode, setShowModalQrCode] = useState(false);
    const [isCheckStatus, setIsCheckStatus] = useState(false);
    const [showContinueOnMobile, setShowContinueOnMobile] = useState(false);

    const [iconCheckLottie, setIconCheckLottie] = useState();
    const [iconExclamationMarkLottie, setIconExclamationMarkLottie] =
      useState();
    const [loaderLottie, setLoaderLottie] = useState();
    const { sendAnalytics } = useUtag({ sendAtEveryPage: false });

    useEffect(() => {
      sendAnalytics("ELIGIBILITY_OK");
    }, []);

    useEffect(() => {
      if (showContinueOnMobile && icon !== "check") {
        const useEffectLogic = async () => {
          setIconCheckLottie(await getCheckLottie());
        };
        useEffectLogic();
      }
    }, [showContinueOnMobile]);

    useEffect(() => {
      const useEffectLogic = async () => {
        switch (icon) {
          case "check":
            setIconCheckLottie(await getCheckLottie());
            break;
          case "exclamation":
            setIconExclamationMarkLottie(await getIconExclamationMarkLottie());
            break;
        }
      };
      useEffectLogic();
    }, [icon]);

    useEffect(() => {
      const useEffectLogic = async () => {
        if (messageLoading) {
          setLoaderLottie(await getWhiteLoaderLottie());
        }
      };
      useEffectLogic();
    }, [messageLoading]);

    useEffect(() => {
      setTimeout(() => {
        if (callbackAfterTimeout) {
          callbackAfterTimeout();
        }
      }, timeoutMm);
    }, []);

    // Get the payload of TBT when qrcode modal is opened
    useEffect(() => {
      if (isOpen && isDesktopOrTabletLandscape) {
        dispatch(getTBTPayload(TBTInfo.TBT));
      }
    }, [isOpen]);

    let pollingInterval;

    useEffect(() => {
      if (TBTInfo.status) {
        console.log(TBTInfo.status);
        switch (TBTInfo.status) {
          // Waiting with QRCode available to be scanned. Launching polling function
          case "SURVEY_COMPLETED":
            polling();
            break;
          // Wait with "Do not close window" modal opened
          case "ACUITY_START":
            setIsCheckStatus(true);
            polling();
            break;
          //the acuity test is ended and the user will continue on smartphone
          case "ACUITY_END":
            //on desktop show modal "you can continue the experience on your mobile device"
            setShowModalQrCode(false);
            setIsCheckStatus(false);
            setShowContinueOnMobile(true);
            break;
          //the acuity test is ended and the user will continue on desktop
          case "ACUITY_END_DESKTOP":
            navigate(ROUTING.CURRENT_PRESCRIPTION.url);
            break;
        }
      }

      // Cleanup the interval when the component is unmounted or when the CURRENT_PRESCRIPTION page is reached
      return () => {
        clearInterval(pollingInterval);
      };
    }, [TBTInfo.status]);

    // every 5 seconds try to get the last updated payload (and, so, the status)
    const polling = () => {
      pollingInterval = setInterval(() => {
        dispatch(getTBTPayload(TBTInfo.TBT));
      }, 5000);
    };

    const handleShowModalQrCode = (value) => {
      setShowModalQrCode(value);
      setTimeout(() => {
        document
          .querySelector(
            "." +
              style.modalQrCodeCompressButton +
              (value ? ".compress" : ".expand")
          )
          ?.focus();
      }, 250);
    };

    const ctaSection = (
      <>
        {(ctaAction || ctaPrimaryAction) && (
          <div
            style={
              ctaNoFooter
                ? {
                    width: "100%",
                    padding: "8px 24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                    marginTop: "25px",
                  }
                : {
                    position: "absolute",
                    bottom: 0,
                    background: "rgba(32, 35, 61, 0.5)", //$dark-blue-button-color #20233d
                    backdropFilter: "blur(10px)",
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                    width: "100%",
                    padding: "16px 24px 25px",
                    WebkitBackdropFilter: "blur(10px)",
                  }
            }
          >
            {ctaPrimaryAction && (
              <Button
                className={`${style.cta} ${style.ctaDark}`}
                variant="dark"
                onClick={ctaPrimaryAction}
                analyticsInfo={{
                  "data-analytics_available_call": 0,
                }}
              >
                {ctaPrimaryLabel}
              </Button>
            )}
            {ctaAction && (
              <Button
                className={style.cta}
                variant="lightBlue"
                onClick={ctaAction}
                analyticsInfo={{
                  "data-element-id": "RxRenewal_Eligibility-Next",
                  "data-description": formatAnalyticsInnerText(ctaLabel),
                  "data-analytics_available_call": 0,
                }}
              >
                {ctaLabel}
              </Button>
            )}
          </div>
        )}
      </>
    );

    const qrCodeExpanded = (
      <>
        <div className={style.modalQrCodeBody}>
          <Button
            className={`${style.modalQrCodeCompressButton} compress`}
            onClick={() => handleShowModalQrCode(false)}
            ariaLabel={"Collapse QR code"}
            icon="custom"
            iconInfo={{
              position: "right",
              path:
                window.rxrEnv?.ASSETS_PATH + "/icons/compress_two_arrow.svg",
              width: "20px",
              height: "20px",
            }}
          />

          <p
            className={style.modalQrCodeDescr}
            dangerouslySetInnerHTML={printHTML(qrCode?.description)}
          />

          <div className={style.modalQrCodeContainer}>
            <QRCode value={qrCode.src} size={327} />
          </div>
        </div>
      </>
    );

    const modalBody = (
      <>
        <IconCircleModal className={messageReverse ? style.reverse : null}>
          <>
            {icon === "check" && iconCheckLottie && (
              <Lottie
                animationData={iconCheckLottie}
                loop={false}
                width={"185px"}
                height={"185px"}
              />
            )}
            {icon === "exclamation" && iconExclamationMarkLottie && (
              <Lottie
                animationData={iconExclamationMarkLottie}
                loop={false}
                width={"185px"}
                height={"185px"}
              />
            )}
          </>
        </IconCircleModal>

        {message && (
          <p
            className={clsx(style.message, {
              [style.reverse]: messageReverse,
            })}
            role="alert"
          >
            {message}
          </p>
        )}

        {description && (
          <p
            className={clsx(style.description, {
              [style.reverse]: messageReverse,
            })}
            dangerouslySetInnerHTML={printHTML(description)}
            role="alert"
          />
        )}

        {subdescription && (
          <p
            className={clsx(style.subdescription, {
              [style.reverse]: messageReverse,
            })}
            dangerouslySetInnerHTML={printHTML(subdescription)}
          />
        )}

        {isDesktopOrTabletLandscape && qrCode && (
          <div className={style.qrCodeSection}>
            <div className={style.qrCodeBody}>
              <div className={style.qrCodeDescrContainer}>
                <p
                  className={style.qrCodeDescr}
                  dangerouslySetInnerHTML={printHTML(qrCode?.description)}
                />
              </div>
              <div className={style.qrCodeContainer}>
                <QRCode value={qrCode.src} size={152} />
              </div>
            </div>
            <div className={style.qrCodeExpandButton}>
              <Button
                className={`${style.modalQrCodeCompressButton} expand`}
                onClick={() => handleShowModalQrCode(true)}
                ariaLabel={translate("wcag.wcag_expand_qrcode")}
                icon="custom"
                iconInfo={{
                  position: "right",
                  path:
                    window.rxrEnv?.ASSETS_PATH + "/icons/expand_two_arrow.svg",
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          </div>
        )}

        {ctaNoFooter && ctaSection}
      </>
    );

    const modalBodyLoader = isDesktopOrTabletLandscape &&
      messageLoading &&
      descriptionLoading && (
        <>
          {loaderLottie && (
            <Lottie
              className={style.loader}
              animationData={loaderLottie}
              loop={true}
            />
          )}
          <p
            className={clsx(style.message, {
              [style.reverse]: messageReverse,
            })}
            dangerouslySetInnerHTML={printHTML(messageLoading)}
          />
          <p
            className={clsx(style.description, {
              [style.reverse]: messageReverse,
            })}
            dangerouslySetInnerHTML={printHTML(descriptionLoading)}
          />
        </>
      );

    const modalBodyContinueMobile = isDesktopOrTabletLandscape &&
      showContinueOnMobile && (
        <>
          <IconCircleModal className={messageReverse ? style.reverse : null}>
            {iconCheckLottie && (
              <Lottie
                className={style.loader}
                animationData={iconCheckLottie}
                loop={false}
              />
            )}
          </IconCircleModal>
          <p
            className={clsx(style.message, {
              [style.reverse]: messageReverse,
            })}
          >
            {translate("do_not_close_browser.continue_title")}
          </p>

          <div className={style.ctaContainer}>
            <Button
              className={`${style.cta}`}
              variant="lightBlue"
              onClick={() => {
                cleanSessionStorage();
                exitRxr(config.config?.headerBackLinkUrl);
              }}
              analyticsInfo={{
                "data-element-id": "RxRenewal_Eligibility-GoBackToHomepage",
                "data-description": formatAnalyticsInnerText(
                  translate("do_not_close_browser.continue_cta")
                ),
                "data-analytics_available_call": 0,
              }}
            >
              {translate("do_not_close_browser.continue_cta")}
            </Button>
          </div>
        </>
      );

    return isOpen
      ? createPortal(
          <div
            className={`${style.modal} ${className}`}
            data-analytics_available_call={
              breakpoint.isDesktopOrTabletLandscape ? 0 : undefined
            }
          >
            {isDesktopOrTabletLandscape && showModalQrCode && qrCode ? (
              qrCodeExpanded
            ) : (
              <div className={`${style.modalBody}`}>
                {showContinueOnMobile
                  ? modalBodyContinueMobile
                  : isCheckStatus
                  ? modalBodyLoader
                  : modalBody}
              </div>
            )}

            {ctaSection}
          </div>,
          document.querySelector(SELECTOR_RXR)
        )
      : null;
  }
);

export default ModalQrCodeSurvey;
