import { useTranslation } from "@hooks/useTranslation";
import { selectSendAnswersInfo, selectSurvey } from "@slices/surveySlice";
import { printHTML } from "@utility/htmlUtility";
import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SurveyStepButton from "../SurveyStepButton/component";
import style from "./style.module.scss";
import { UseFormReturn } from "react-hook-form";

type Props = {
  currentStepIndex: number;
  totalSteps: number;
  changeStepFunction: (value: number) => void;
  children?: ReactNode;
  stepsTicks: any[];
};

export default function Sidebar(props: Props) {
  const translate = useTranslation();
  const survey = useSelector(selectSurvey);
  const sendAnswersInfo = useSelector(selectSendAnswersInfo);
  const lastSurveyStepIndex = survey?.steps?.length - 1;
  const prescriptionStepIndex = lastSurveyStepIndex + 1;
  const recapPrescriptionDataIndex = prescriptionStepIndex + 1;

  const [currentStepIndex, setCurrentStepIndex] = useState(
    props.currentStepIndex
  );
  const [isSurveyReady, setIsSurveyReady] = useState(false);

  useEffect(() => {
    if (props.currentStepIndex != undefined) {
      setCurrentStepIndex(props.currentStepIndex);
    }
  }, [props.currentStepIndex]);

  useEffect(() => {
    if (survey) {
      setIsSurveyReady(true);
    }
  }, [survey]);

  const handleClick = (index: number) => {
    setCurrentStepIndex(index);
    props.changeStepFunction(index);
  };

  return (
    <div className={style.SurveySteps}>
      <div className={style.SurveyStepsContent}>
        <div className={style.TitleAndSteps}>
          {translate("survey.eligibility_check")}
          <div
            className={style.StepRecap}
            dangerouslySetInnerHTML={printHTML(
              translate("survey.steps_recap")
                .replace("{{step}}", (currentStepIndex + 1).toString())
                .replace("{{totalSteps}}", props.totalSteps?.toString())
            )}
          ></div>
        </div>
        {isSurveyReady && (
          <>
            {survey?.steps?.map((step, index) => (
              <SurveyStepButton
                tick={props.stepsTicks?.[step.idFE] || null}
                key={step.idFE}
                // onClick={() => handleClick(index)}
                stepIndex={index}
                isOnFocus={currentStepIndex == index}
                disabled={currentStepIndex < index || sendAnswersInfo.isLoading}
              >
                {step.stepLabel.label}
              </SurveyStepButton>
            ))}
            {/*Prescription and Recap buttons here*/}
            <SurveyStepButton
              tick={props.stepsTicks?.["prescription_step"] || null}
              key={"recap_personal_data"}
              onClick={() => handleClick(prescriptionStepIndex)}
              stepIndex={prescriptionStepIndex}
              isOnFocus={currentStepIndex == prescriptionStepIndex}
              disabled={
                currentStepIndex < prescriptionStepIndex ||
                sendAnswersInfo.isLoading
              }
            >
              {translate("survey.q9_step")}
            </SurveyStepButton>
            <SurveyStepButton
              key={"recap_prescription_data"}
              onClick={() => handleClick(recapPrescriptionDataIndex)}
              stepIndex={recapPrescriptionDataIndex}
              isOnFocus={currentStepIndex == recapPrescriptionDataIndex}
              disabled={
                currentStepIndex < recapPrescriptionDataIndex ||
                sendAnswersInfo.isLoading
              }
            >
              {translate("survey.q10_step")}
            </SurveyStepButton>
          </>
        )}
      </div>
      <div className={style.hiddenRightSection} />
      <div className={style.rightTransaction} />
    </div>
  );
}
