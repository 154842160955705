import React, { ChangeEvent, MouseEvent, TouchEvent, useRef } from "react";
import style from "./style.module.scss";
import { clsx, handleOnEnterKeyPress } from "@utility/utility";

type Props = {
  id: string;
  name: string;
  checked: boolean;
  value?: string;
  label?: string;
  useContainer?: boolean;
  className?: string;
  border?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  onClick?: (e: MouseEvent) => void;
  onTouchEnd?: (e: TouchEvent<HTMLInputElement>) => void;
  onKeyDown?: () => void;
};

export default function RadioButton(props: Props) {
  const inputRef = useRef(null);

  const handleDivClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const Content = (
    <>
      <input
        className={style.radio}
        type="radio"
        id={props.id}
        name={props.name}
        value={props.value || props.id}
        checked={props.checked}
        onChange={props.onChange}
        aria-label={props.ariaLabel ? props.ariaLabel : "radio button"}
        readOnly={props.readOnly}
        disabled={props.disabled}
        ref={inputRef}
        onClick={props.onClick}
        onTouchEnd={props.onTouchEnd}
        onKeyDown={(e) => {
          if (props.onKeyDown) {
            handleOnEnterKeyPress(e, props.onKeyDown, true);
          }
        }}
      />
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
    </>
  );

  return props.useContainer ? (
    <div
      className={clsx(style.container, {
        [props.className]: !!props.className,
        [style.containerBorder]: props.border,
        [style.checked]: props.checked,
      })}
      onClick={handleDivClick}
    >
      {Content}
    </div>
  ) : (
    Content
  );
}
