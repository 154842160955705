import React, { memo } from "react";
import style from "./style.module.scss";
import { clsx } from "@utility/utility";

type ChipProps = {
  label: string;
  noWrap?: boolean;
  ariaLabel?: string;
};

function Chip({ label, noWrap = false, ariaLabel = "" }: ChipProps) {
  return (
    <div
      className={clsx(style.chip, {
        [style.noWrap]: noWrap,
      })}
      aria-label={ariaLabel}
    >
      <span className={style.label} aria-hidden={!!ariaLabel}>
        {label}
      </span>
    </div>
  );
}

export default memo(Chip);
