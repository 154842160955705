import React, { useState } from "react";
import style from "./style.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import { printHTML, removeHTMLtags } from "@utility/htmlUtility";
import LazyImage from "@components/common/LazyImage/LazyImage";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { isDeviceIos } from "@utility/utility";

type Props = {
  indexSetterFunction?: (number) => void;
};

function YouWillNeedComponent(props: Props) {
  const translate = useTranslation();
  const breakpoint = useBreakpointBoolean();

  const retrieveCurrentAboutTitle = (index: number, removeHtml = false) => {
    let title = translate(
      "start_page2.my_prescription_renewal_3_element_" + index
    );

    if (removeHtml) {
      title = removeHTMLtags(title);
    }
    return title;
  };

  const retrieveCurrentAboutContent = (index: number) => {
    return translate("start_page2.about_element_" + index + "_content");
  };

  return (
    <>
      <div className={style.YouWillNeedContainer}>
        <div
          className={style.YouWillNeedTitle}
          dangerouslySetInnerHTML={printHTML(
            translate("start_page2.my_prescription_renewal_3_title")
          )}
        />
        {Array.from(
          { length: 7 },
          (item, index) =>
            (breakpoint.isDesktopOrTabletLandscape || index != 0) && (
              <React.Fragment key={index}>
                {isDeviceIos() && index === 4 ? null : (
                  <div>
                    <div
                      className={`${style.YouWillNeedElement} ${
                        !!props.indexSetterFunction &&
                        retrieveCurrentAboutContent(index) !== ""
                          ? style.hasAboutSection
                          : ""
                      }`}
                      onClick={
                        retrieveCurrentAboutContent(index) !== "" &&
                        props.indexSetterFunction
                          ? () => props.indexSetterFunction(index)
                          : undefined
                      }
                    >
                      <div
                        className={style.ElementText}
                        dangerouslySetInnerHTML={printHTML(
                          retrieveCurrentAboutTitle(index)
                        )}
                      />
                      {retrieveCurrentAboutContent(index) !== "" &&
                        props.indexSetterFunction && (
                          <button
                            className={style.aboutIcon}
                            onClick={
                              props.indexSetterFunction
                                ? () => props.indexSetterFunction(index)
                                : undefined
                            }
                            id={"question_mark_" + index}
                          >
                            <LazyImage
                              src={
                                window.rxrEnv?.ASSETS_PATH +
                                "/icons/round-question-mark.svg"
                              }
                              width={"auto"}
                              height={"auto"}
                              alt={retrieveCurrentAboutTitle(index, true)}
                            />
                          </button>
                        )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )
        )}
        {/*<OrSeparator className={style.separator} label={""}></OrSeparator>*/}
      </div>
    </>
  );
}

export default YouWillNeedComponent;
