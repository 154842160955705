import React, { useEffect, useState } from "react";
// import { singletonHook } from "react-singleton-hook";

// https://github.com/streamich/react-use/blob/master/docs/createBreakpoint.md

export enum defaultBreakpoints {
  desktop = "desktop",
  tablet = "tablet",
  phone = "phone",
}

const createBreakpoint =
  (
    breakpoints: { [name: string]: number } = {
      laptopL: 1440,
      laptop: 1024,
      tablet: 768,
    }
  ) =>
  () => {
    const getActualViewport = (): defaultBreakpoints => {
      const sortedBreakpoints = Object.entries(breakpoints).sort((a, b) =>
        a[1] >= b[1] ? 1 : -1
      );

      let screenTemp = screen;
      if (screenTemp <= 0) {
        screenTemp = window.innerWidth;
      }

      const result = sortedBreakpoints.reduce((acc, [name, width]) => {
        if (screenTemp >= width) {
          return name;
        } else {
          return acc;
        }
      }, sortedBreakpoints[0][0]);

      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      let viewportTemp = defaultBreakpoints.phone;
      switch (result) {
        case "L":
          viewportTemp = defaultBreakpoints.desktop;
          break;
        case "M":
          viewportTemp = defaultBreakpoints.tablet;
          break;
        case "S":
          viewportTemp = isPortrait
            ? defaultBreakpoints.tablet
            : defaultBreakpoints.phone;
          break;
        case "XS":
        default:
          viewportTemp = defaultBreakpoints.phone;
          break;
      }
      return viewportTemp;
    };

    const [screen, setScreen] = useState(0);
    const [viewport, setViewport] = useState<defaultBreakpoints>(
      getActualViewport()
    );

    useEffect(() => {
      const setSideScreen = (): void => {
        setScreen(window.innerWidth);
      };
      setSideScreen();
      window.addEventListener("resize", setSideScreen);
      return () => {
        window.removeEventListener("resize", setSideScreen);
      };
    }, []);

    useEffect(() => {
      const viewportTemp = getActualViewport();
      setViewport(viewportTemp);
    }, [breakpoints, screen]);

    return viewport;
  };

export const useBreakpoint = createBreakpoint({
  L: 1200,
  M: 992,
  S: 768,
  XS: 0,
});

class useBreakpointBooleanResult {
  isDesktop: boolean;
  isDesktopOrTabletLandscape: boolean;
  isTablet: boolean;
  isPhoneOrTabletPortrait: boolean;
  isPhone: boolean;
  isPortrait: boolean;
}
export const useBreakpointBoolean = (): useBreakpointBooleanResult => {
  const breakpoint = useBreakpoint();
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  return {
    isDesktop: breakpoint === defaultBreakpoints.desktop,
    isDesktopOrTabletLandscape:
      breakpoint === defaultBreakpoints.desktop ||
      (!isPortrait && breakpoint === defaultBreakpoints.tablet),
    isTablet: breakpoint === defaultBreakpoints.tablet,
    isPhoneOrTabletPortrait:
      breakpoint === defaultBreakpoints.phone ||
      (isPortrait && breakpoint === defaultBreakpoints.tablet),
    isPhone: breakpoint === defaultBreakpoints.phone,
    isPortrait: isPortrait,
  };
};

export const useBreakpointBooleanRef = () => {
  const breakpoint = useBreakpoint();
  const [breakpointState, _setBreakpointState] = useState(null);
  const breakpointRef = React.useRef<{
    isDesktop: boolean;
    isTablet: boolean;
    isPhone: boolean;
  }>(breakpointState);

  const setBreakpoint = (data) => {
    breakpointRef.current = data;
    _setBreakpointState(data);
  };

  useEffect(() => {
    const breakpointTemp = {
      isDesktop: breakpoint === defaultBreakpoints.desktop,
      isTablet: breakpoint === defaultBreakpoints.tablet,
      isPhone: breakpoint === defaultBreakpoints.phone,
    };
    setBreakpoint(breakpointTemp);
  }, [breakpoint]);

  return breakpointRef;
};
