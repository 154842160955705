import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import PageStandard from "@components/common/PageStandard/component";
import { useTranslation } from "@hooks/useTranslation";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ModalDetectDevice from "@components/common/ModalDetectDevice/component";
import { acuityActions, selectAcuityTestResults } from "@slices/acuitySlice";
import ModalCountdown from "@components/common/ModalCountdown/component";
import LandoltCRing, {
  COrientationsType,
} from "@components/LandoltCRing/component";
import ModalCheck from "@components/common/ModalCheck/component";
import ProgressBarAcuity from "@components/ProgressBarAcuity/component";
import { useGetSetupVideos } from "@hooks/useGetSetupVideos";

type COrientationsMap = {
  4?: COrientationsType[];
  6?: COrientationsType[];
  8?: COrientationsType[];
  10?: COrientationsType[];
};

type ResultsType = {
  4?: boolean[];
  6?: boolean[];
  8?: boolean[];
  10?: boolean[];
};

type AcuityTestPageProps = {};

function AcuityTestPage(props: AcuityTestPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslation();
  const acuityTestResults = useSelector(selectAcuityTestResults);

  let eyeParam = useParams()?.eyeParam;

  const orientations: COrientationsType[] = [
    "right-right",
    "bottom-right",
    "bottom-bottom",
    "bottom-left",
    "left-left",
    "top-left",
    "top-top",
    "top-right",
  ];
  const orientationsClasses = {
    "right-right": style.orientationRightRight,
    "bottom-right": style.orientationBottomRight,
    "bottom-bottom": style.orientationBottomBottom,
    "bottom-left": style.orientationBottomLeft,
    "left-left": style.orientationLeftLeft,
    "top-left": style.orientationTopLeft,
    "top-top": style.orientationTopTop,
    "top-right": style.orientationTopRight,
  };

  const [showCountdown, setShowCountdown] = useState(false);
  const [checkModalInfo, setCheckModalInfo] = useState<{
    message: string;
    audio?: string;
    onEndedAudio?: () => void;
  }>({ message: "" });

  const [cOrientations, setCOrientations] = useState<COrientationsMap>();
  const [results, setResults] = useState<ResultsType>({});
  const [currentCSizeVa, setCurrentCSizeVa] = useState<4 | 6 | 8 | 10>(4);
  const [currentTestStep, setCurrentTestStep] = useState<number>(0);
  const [timeoutClickedDot, setTimeoutClickedDot] = useState<NodeJS.Timeout>();
  const [clickedDot, setClickedDot] = useState("");
  const [progressPercentage, setProgressPercentage] = useState(0);

  const setupVideosSx = useGetSetupVideos("acuity-sx");
  const setupVideosDx = useGetSetupVideos("acuity-dx");

  console.log("AcuityTestPage", {
    cOrientations,
    results,
    currentCSizeVa,
    currentTestStep,
    orientation:
      orientationsClasses[cOrientations?.[currentCSizeVa]?.[currentTestStep]],
  });

  useEffect(() => {
    setResults({});

    switch (eyeParam) {
      case "right-eye-tutorial":
        setCOrientations(getOrientationMap(true));
        setCurrentCSizeVa(4);
        break;
      case "right-eye":
        setCOrientations(getOrientationMap(false));
        setCurrentCSizeVa(6);
        break;
      case "left-eye-tutorial":
        if (!acuityTestResults.right) {
          console.error(
            "user is trying to make the acuity test for the left eye skipping the right one",
            { eyeParam }
          );
          navigate(ROUTING.ACUITY_START.url);
          return;
        }
        setCOrientations(getOrientationMap(true));
        setCurrentCSizeVa(4);
        break;
      case "left-eye":
        if (!acuityTestResults.right) {
          console.error(
            "user is trying to make the acuity test for the left eye skipping the right one",
            { eyeParam }
          );
          navigate(ROUTING.ACUITY_START.url);
          return;
        }
        setCOrientations(getOrientationMap(false));
        setCurrentCSizeVa(6);
        break;
      default:
        console.error("eyeParam is not valid", { eyeParam });
        navigate(ROUTING.ACUITY_START.url);
        break;
    }
  }, [eyeParam]);

  const goToNextCSize = (newResults: ResultsType) => {
    //reset step, results and percentage
    setCurrentTestStep(0);
    setProgressPercentage(0);

    switch (currentCSizeVa) {
      case 4:
        const nextAction = () => {
          //hide check modal
          setCheckModalInfo({ message: "" });
          //show countdown
          setShowCountdown(true);

          setTimeout(() => {
            //hide countdown after 3 seconds
            setShowCountdown(false);
            //go to left or right eye acuity test
            navigate(
              ROUTING.ACUITY_TEST.url.replace(
                ":eyeParam",
                eyeParam === "left-eye-tutorial" ? "left-eye" : "right-eye"
              )
            );
          }, 3000);
        };
        setCheckModalInfo({
          message: "acuity.great_after_first_test",
          audio: setupVideosDx?.[2]?.uri,
          onEndedAudio: nextAction,
        });
        break;
      case 6:
        setCheckModalInfo({
          message: "acuity.great_next_sequence",
          audio: setupVideosDx?.[3]?.uri,
          onEndedAudio: () => {
            setCheckModalInfo({ message: "" });
            setCurrentCSizeVa(8);
          },
        });
        break;
      case 8:
        setCheckModalInfo({
          message: "acuity.great_next_sequence",
          audio: setupVideosDx?.[3]?.uri,
          onEndedAudio: () => {
            setCheckModalInfo({ message: "" });
            setCurrentCSizeVa(10);
          },
        });
        break;
      case 10:
        testEyeFinished(newResults);
        break;
    }
  };

  const testEyeFinished = (newResults: ResultsType) => {
    if (eyeParam === "right-eye") {
      dispatch(
        acuityActions.setAcuityTestResults({ eye: "right", result: newResults })
      );
      const nextAction = () => {
        navigate(
          ROUTING.ACUITY_VIDEO.url.replace(":videoId?", "acuity-left-eye")
        );
      };
      setCheckModalInfo({
        message: "acuity.great_after_right_eye",
        audio: setupVideosDx?.[4]?.uri,
        onEndedAudio: nextAction,
      });
    } else if (eyeParam === "left-eye") {
      const nextAction = () => {
        navigate(
          ROUTING.ACUITY_VIDEO.url.replace(
            ":videoId?",
            "acuity-flip-back-phone-end"
          )
        );
      };
      setCheckModalInfo({
        message: "acuity.completed_vision_test",
        audio: setupVideosSx?.[1]?.uri,
        onEndedAudio: nextAction,
      });
      dispatch(
        acuityActions.setAcuityTestResults({ eye: "left", result: newResults })
      );
    }
  };

  const getOrientationMap = (isTutorial: boolean = false): COrientationsMap => {
    if (isTutorial) {
      return {
        4: getOrientationList(1),
      };
    }

    return {
      6: getOrientationList(5),
      8: getOrientationList(5),
      10: getOrientationList(5),
    };
  };

  const getOrientationList = (listLength: number = 5): COrientationsType[] => {
    const list = [];

    for (let i = 0; i < listLength; i++) {
      list.push(getRandomOrientation(i, list));
    }

    return list;
  };

  const getRandomOrientation = (
    step: number,
    oldOrientations: string[]
  ): COrientationsType => {
    if (step > 4) {
      step = Math.floor(step % 4);
    }

    switch (step) {
      case 0: // 1st call : vertical or horizontal
        return orientations[getRandomNumber()];
      case 1: // 2nd call : diagonal
        return orientations[getRandomNumber(true)];
      case 2: // 3rd call : vertical or horizontal but not as first call
        let randomNumberStep2 = -1;
        while (
          randomNumberStep2 < 0 ||
          orientations[randomNumberStep2] === oldOrientations[0]
        ) {
          randomNumberStep2 = getRandomNumber();
        }
        return orientations[randomNumberStep2];
      case 3: // 4th call : vertical or horizontal but not as first call and 3rd call
        let randomNumberStep3 = -1;
        while (
          randomNumberStep3 < 0 ||
          [oldOrientations[0], oldOrientations[2]].includes(
            orientations[randomNumberStep3]
          )
        ) {
          randomNumberStep3 = getRandomNumber();
        }
        return orientations[randomNumberStep3];
      case 4: // 5th call : diagonal but not 2nd call
        let randomNumberStep4 = -1;
        while (
          randomNumberStep4 < 0 ||
          orientations[randomNumberStep4] === oldOrientations[1]
        ) {
          randomNumberStep4 = getRandomNumber(true);
        }
        return orientations[randomNumberStep4];
      default:
        break;
    }
  };

  const getRandomNumber = (odd: boolean = false): number => {
    return Math.floor(Math.random() * 4) * 2 + (odd ? 1 : 0);
  };

  const onConfirmDotSelection = (id: string) => {
    const correctDot = cOrientations[currentCSizeVa]?.[currentTestStep];
    const responseUser = id === correctDot;

    const cSizeLength = cOrientations[currentCSizeVa]?.length || 1;
    const halfCSizeLength = Math.ceil(cSizeLength / 2);
    const currentCSizeResults = results[currentCSizeVa] || [];

    const resultsTemp = [];
    let wrongNumber = 0;
    let correctNumber = 0;
    //for every step
    for (let i = 0; i < cSizeLength; i++) {
      let stepAnswer = null;

      if (currentCSizeResults.length > i) {
        //old responses
        stepAnswer = currentCSizeResults[i];
        resultsTemp.push(stepAnswer);
      } else if (currentCSizeResults.length === i) {
        //actual response
        stepAnswer = responseUser;
        resultsTemp.push(stepAnswer);
      }

      if (stepAnswer !== null) {
        //calculate wrong and correct answers
        if (stepAnswer) {
          correctNumber++;
        } else {
          wrongNumber++;
        }
      }
    }

    //progress for the currect C size
    let progressPercentageTemp =
      ((wrongNumber + correctNumber) / cSizeLength) * 100;

    const newResults = {
      ...results,
      [currentCSizeVa]: resultsTemp,
    };
    setResults(newResults);

    let callback = () => {};
    if (currentCSizeVa === 4 && !responseUser) {
      callback = () =>
        navigate(
          ROUTING.ACUITY_VIDEO.url.replace(
            ":videoId?",
            eyeParam === "left-eye-tutorial"
              ? "acuity-flip-back-phone-left"
              : "acuity-flip-back-phone-right"
          )
        );
    } else if (wrongNumber >= halfCSizeLength) {
      //interrupt the test skipping the next C sizes
      callback = () => testEyeFinished(newResults);
      progressPercentageTemp = 100;
    } else if (correctNumber >= halfCSizeLength) {
      //go directly to the next C size
      callback = () => goToNextCSize(newResults);
      progressPercentageTemp = 100;
    } else if (currentTestStep < cSizeLength) {
      //if the user has not completed the test for the current C size --> go ahead
      callback = () => setCurrentTestStep((current) => current + 1);
    } else {
      //if the user has completed the test for the current C size --> go to the next C size
      callback = () => goToNextCSize(newResults);
    }

    if (progressPercentageTemp !== progressPercentage) {
      setProgressPercentage(progressPercentageTemp);
    }
    //the timeout is to show the progress percentage animation before the callback
    setTimeout(() => {
      callback();
      //clean dot selection
      setClickedDot("");
    }, 1000);
  };

  const onClickDot = (dot: string) => {
    if (timeoutClickedDot) {
      clearTimeout(timeoutClickedDot);
    }

    const timeout = setTimeout(() => {
      onConfirmDotSelection(dot);
      setTimeoutClickedDot(null);
    }, 3000);

    const cSizeLength = cOrientations[currentCSizeVa]?.length || 1;
    const answersLength =
      (results[currentCSizeVa]?.filter((_) => _ || _ === false)?.length || 0) +
      1;
    const progressPercentageTemp = (answersLength / cSizeLength) * 100;
    if (progressPercentageTemp !== progressPercentage) {
      setProgressPercentage(progressPercentageTemp);
    }
    setTimeoutClickedDot(timeout);
    setClickedDot(dot);
  };

  const dotsConfiguration = useMemo(() => {
    let dotConfTemp = {
      "right-right": {},
      "bottom-right": {},
      "bottom-bottom": {},
      "bottom-left": {},
      "left-left": {},
      "top-left": {},
      "top-top": {},
      "top-right": {},
    };

    Object.keys(dotConfTemp).forEach((dotKey) => {
      //get and set classes
      const classes = [];
      classes.push(style.dot);
      if (clickedDot) {
        if (clickedDot === dotKey) {
          classes.push(style.dotExpandHighlighted);
        } else {
          classes.push(style.dotExpand);
        }
      }

      dotConfTemp[dotKey].className = classes.join(" ");

      //set onclick event
      if (clickedDot === dotKey) {
        dotConfTemp[dotKey].onClick = () => {};
      } else {
        dotConfTemp[dotKey].onClick = onClickDot;
      }
    });

    return dotConfTemp;
  }, [clickedDot, onClickDot]);

  return (
    <PageStandard
      className={style.acuityTest}
      classNamePage={style.acuityTestPage}
      showHeader={false}
      footerInfo={{
        className: style.footer,
        showLeftButton: true,
        iconLeftButton: "leftArrow",
        actionLeftButton: () => navigate(-1),
        analyticsInfoLeftButton: {
          "data-element-id": "RxRenewal_Test-Back",
          "data-analytics_available_call": 0,
        },
        backgroundWhite: true,
      }}
    >
      <ProgressBarAcuity
        className={style.progressBar}
        value={progressPercentage}
        revert={true}
      />

      <LandoltCRing
        cSizeVa={currentCSizeVa}
        className={style.landoltCRing}
        classNameDots={style.dots}
        dotsConfiguration={dotsConfiguration}
        classNameLandoltC={
          orientationsClasses[
            cOrientations?.[currentCSizeVa]?.[currentTestStep]
          ]
        }
        key={clickedDot}
      />

      {!!checkModalInfo.message && (
        <ModalCheck
          message={translate(checkModalInfo.message)}
          messageReverse={true}
          inAcuityTestFlow={true}
          audio={checkModalInfo.audio}
          onEndedAudio={checkModalInfo.onEndedAudio}
        />
      )}
      {showCountdown && <ModalCountdown />}

      <ModalDetectDevice />
    </PageStandard>
  );
}

export default AcuityTestPage;
