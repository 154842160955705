import React, { useEffect, useState } from "react";
import PageStandard from "@components/common/PageStandard/component";
import Textarea from "@components/common/Textarea/component";
import { useTranslation } from "@hooks/useTranslation";
import style from "./style.module.scss";
import stylePageCardMobile from "@components/PageCardMobile/style.module.scss";
import { printHTML } from "@utility/htmlUtility";
import { useDispatch, useSelector } from "react-redux";
import { selectComment, selectCommentIsLoading } from "@slices/acuitySlice";
import { selectConfig } from "@slices/configSlice";
import ModalCheck from "@components/common/ModalCheck/component";
import { utilityAsyncActions } from "@slices/utilitySlice";
import ResultSidebar from "@components/common/ResultSidebar/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useNavigate } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import { selectTBTInfo, updateTBTPayload } from "@slices/surveySlice";
import {
  cleanSessionStorage,
  sessionStorageIds,
} from "@utility/sessionStorageUtility";
import { useUtag } from "@hooks/useUtag";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type CommentPageProps = {};

function CommentPage(props: CommentPageProps) {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const navigate = useNavigate();
  const breakpoint = useBreakpointBoolean();

  const surveySubmittedHere =
    sessionStorage.getItem(sessionStorageIds.SURVEY_SUBMITTED_HERE) === "true"
      ? true
      : false;
  const commentIsLoading = useSelector(selectCommentIsLoading);
  const commentStored = useSelector(selectComment);
  const config = useSelector(selectConfig);
  const TBTInfo = useSelector(selectTBTInfo);
  const { sendAnalytics } = useUtag({ sendAtEveryPage: false });

  const [comment, setComment] = useState(commentStored);
  const [showModalDone, setShowModalDone] = useState(false);
  const [modalCtaDisabled, setModalCtaDisabled] = useState(false);

  useEffect(() => {
    if (showModalDone) {
      sendAnalytics("RESULT_VALIDATION_OK");
    }
  }, [showModalDone]);

  const nextAction = () => {
    //adding comment in TBT payload
    const newPayload = {
      ...TBTInfo.payload,
      eligibility_questionnarie: [...TBTInfo.payload.eligibility_questionnarie],
    };

    //check if an existing note already exists
    const existingCommentIndex =
      TBTInfo.payload.eligibility_questionnarie.findIndex(
        (question) => question.step_id === 99 && question.q_id === 99
      );

    //overwrite existing comment if it exists
    //otherwise add new comment element
    if (existingCommentIndex !== -1) {
      newPayload.eligibility_questionnarie[existingCommentIndex] = {
        step_id: 99,
        q_id: 99,
        q_label: translate("comment.connect_label"),
        q_answer: [comment || ""],
        answer_id: [],
        q_prescription: null,
        q_pupillary_distance: null,
        labelConnect: [comment || ""],
      };
    } else {
      newPayload.eligibility_questionnarie.push({
        step_id: 99,
        q_id: 99,
        q_label: translate("comment.connect_label"),
        q_answer: [comment || ""],
        answer_id: [],
        q_prescription: null,
        q_pupillary_distance: null,
        labelConnect: [comment || ""],
      });
    }

    dispatch(
      updateTBTPayload({
        tokenId: TBTInfo.TBT,
        payload: newPayload,
        onSuccessCallback: () => setShowModalDone(true),
        updateTbts: false,
      })
    );
  };

  const handleStepChange = (stepIndex: number) => {
    if (stepIndex != undefined) {
      switch (stepIndex) {
        case 1:
          navigate(ROUTING.CURRENT_PRESCRIPTION.url);
          break;
        case 2:
          navigate(ROUTING.COMMENT.url);
          break;
      }
    }
  };

  return (
    <PageStandard
      className={`${style.Background} ${stylePageCardMobile.pageCardMobile}`}
      classNamePageBody={stylePageCardMobile.pageCardMobileBody}
      footerInfo={{
        showLeftButton: true,
        iconLeftButton: "leftArrow",
        classNameLeftButton: stylePageCardMobile.footerLeftButton,
        showRightButton: true,
        classNameRightButton: stylePageCardMobile.footerRightButton,
        labelRightButton: translate("footer.next"),
        actionRightButton: nextAction,
        disabledRightButton: commentIsLoading,
        backgroundWhite: true,
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div
        className={`${stylePageCardMobile.overPageContainer} ${style.overPageContainer}`}
      >
        <p className={stylePageCardMobile.overPageTitle}>
          {translate("current_prescription.over_title")}
        </p>

        <p
          className={stylePageCardMobile.overPageSteps}
          dangerouslySetInnerHTML={printHTML(
            translate("current_prescription.steps_recap")
              ?.replace(
                "{{step}}",
                surveySubmittedHere || breakpoint.isDesktopOrTabletLandscape
                  ? "2"
                  : "3"
              )
              .replace(
                "{{totalSteps}}",
                surveySubmittedHere || breakpoint.isDesktopOrTabletLandscape
                  ? "2"
                  : "3"
              )
          )}
        />
      </div>

      <div
        className={`${stylePageCardMobile.pageBodyCard} ${stylePageCardMobile.noPaddingBodyCard}`}
      >
        {breakpoint.isDesktopOrTabletLandscape && (
          <ResultSidebar
            currentStepIndex={2}
            totalSteps={2}
            changeStepFunction={handleStepChange}
          />
        )}
        <div className={style.pageBody}>
          <p
            className={stylePageCardMobile.title}
            dangerouslySetInnerHTML={printHTML(translate("comment.title"))}
          />
          <p
            className={` ${style.description}`}
            dangerouslySetInnerHTML={printHTML(
              translate("comment.description")
            )}
          />

          <Textarea
            className={style.textareaContainer}
            placeholderKey="comment.textarea_placeholder"
            value={comment}
            onChange={setComment}
          />
        </div>
      </div>

      {showModalDone && (
        <ModalCheck
          isOpen={showModalDone}
          icon="check"
          message={translate("comment.modal_done_title")}
          description={translate("comment.modal_done_description")}
          ctaLabel={translate("comment.modal_done_cta")}
          ctaDisabled={modalCtaDisabled}
          ctaAnalyticsInfo={{
            "data-element-id": "RxRenewal_ResultValidation-FinishAndGoToCart",
            "data-description": formatAnalyticsInnerText(
              translate("comment.modal_done_cta")
            ),
            "data-analytics_available_call": 1,
          }}
          ctaAction={() => {
            setModalCtaDisabled(true);
            dispatch(
              utilityAsyncActions.invokeEcommerceFunction({
                functionId: "addNewPrescriptionToCart",
              })
            );

            //cleaning tokens
            cleanSessionStorage();
          }}
        />
      )}
    </PageStandard>
  );
}

export default CommentPage;
