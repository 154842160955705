import React, { useEffect, useState } from "react";
import { QuestionType } from "@model/SurveyModel";
import { Controller } from "react-hook-form";
import style from "./style.module.scss";
import SelectCustom from "@components/common/SelectCustom/component";
import { transformIntoArray } from "@utility/utility";
import { useTranslation } from "@hooks/useTranslation";
import { useSelector } from "react-redux";
import { selectCountryList } from "@slices/surveySlice";

type SelectStateProps = {
  question: QuestionType;
  formData: any;
  optional?: boolean;
  onClick?: () => void;
};
const SelectState = ({
  question,
  formData,
  optional = false,
  onClick,
}: SelectStateProps) => {
  const translate = useTranslation();
  const [options, setOptions] = useState([]);
  const value = formData?.watch(question?.idFE);

  const countryList = useSelector(selectCountryList);

  useEffect(() => {
    let optionsTemp = [];

    //set country list into the options list
    countryList?.forEach((country, index) => {
      optionsTemp.push({
        value: country.code,
        id: country.code,
        label: country.label,
        description: "",
        logo: "",
        exclude: "",
        labelAfterSelection: country.label,
        group: 1,
        order: index,
      });
    });

    //handle answer groups
    if (optionsTemp?.length > 0) {
      let groupTemp = optionsTemp[0].group;
      optionsTemp.forEach((optionTemp) => {
        if (groupTemp != optionTemp.group) {
          //use separator at group change
          optionTemp.separator = true;
          groupTemp = optionTemp.group;
        }
      });
    }

    setOptions(optionsTemp);
  }, [question, countryList]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Controller
      control={formData.control}
      name={question.idFE}
      rules={{
        required: !optional,
      }}
      render={({ field: { onChange: controllerOnChange } }) => {
        return (
          <SelectCustom
            onChange={(selectedValue) => {
              controllerOnChange(selectedValue);
            }}
            onClick={handleClick}
            className={style.selectState}
            id={question.idFE}
            options={options}
            selectedOptions={transformIntoArray(value)}
            icon={
              question.placeholder?.iconPath
                ? {
                    src: question.placeholder?.iconPath,
                    alt: translate("wcag.wcag_placeholder_icon")?.replace(
                      "{{question}}",
                      question.placeholder?.label || ""
                    ),
                  }
                : null
            }
            placeholder={question.placeholder?.label || ""}
            isError={!!formData.formState?.errors?.[question.idFE]?.message}
            isMultiSelect={false}
            // errorMessage="The prescription values entered do no match the vision aid that you have selected before."
          />
        );
      }}
    />
  );
};

export default SelectState;
