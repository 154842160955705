import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import {
  clsx,
  handleOnEnterKeyPress,
  transformIntoArray,
} from "@utility/utility";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import Button from "../Button/component";
import ButtonSurvey from "../ButtonSurvey/component";
import Checkbox from "../Checkbox/component";
import LazyImage from "../LazyImage/LazyImage";
import ModalSurvey from "../ModalSurvey/component";
import RadioButton from "../RadioButton/component";
import style from "./style.module.scss";
import { SelectOptionProps, Option } from "./Option";

type SelectIconProps = {
  src: string;
  alt: string;
};

export type RadioInsideSelectProps = {
  name: string;
  selected: string;
  onChange?: (selected: string) => void;
  hidden?: boolean;
};

type DisabledMapType = {
  [key: string]: boolean;
};

export type SelectProps = {
  id: string;
  options: SelectOptionProps[];
  isMultiSelect: boolean;
  selectedOptions: SelectOptionProps[];
  onChange: (val: SelectOptionProps | SelectOptionProps[]) => void;
  placeholder?: string;
  onClick?: () => void;
  className?: string;
  icon?: SelectIconProps;
  radioInsideSelect?: RadioInsideSelectProps;
  isError?: boolean;
  errorMessage?: string;
  isInsideModal?: boolean;
  hideSelectContainer?: boolean;
  //used to manage forced menu closing by selecting an option in an or rule step
  forcedMenuClosingInfo?: {
    forcedMenuClosing: boolean;
    isOrSelect: boolean;
    fallbackCloseAllMenus?: () => void;
  };
};

const SelectCustom = (props: SelectProps) => {
  const translate = useTranslation();
  const breakpoint = useBreakpointBoolean();
  const [disabledMap, setDisabledMap] = useState<DisabledMapType>({});
  const [isMenuOpen, _setIsMenuOpen] = useState(props.isInsideModal);
  const setIsMenuOpen = (value) => {
    if (props.isInsideModal && !value) {
      return;
    }
    _setIsMenuOpen(value);
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedElements, _setSelectedElements] = useState<
    SelectOptionProps[]
  >(props.selectedOptions ? [...props.selectedOptions] : []);
  const setSelectedElements = (value) => {
    _setSelectedElements(transformIntoArray(value));
  };
  const [filterText, setFilterText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(props.options);

  useEffect(() => {
    if (props.forcedMenuClosingInfo?.forcedMenuClosing) {
      setIsMenuOpen(false);
    }
  }, [props.forcedMenuClosingInfo?.forcedMenuClosing]);

  useEffect(() => {
    setSelectedElements(
      props.selectedOptions ? [...props.selectedOptions] : []
    );
  }, [props.selectedOptions]);

  useEffect(() => {
    if (!isMenuOpen) {
      //clean filter
      setFilterText("");

      //when menu is closed --> reset selected elements
      setSelectedElements(
        props.selectedOptions ? [...props.selectedOptions] : []
      );
    } else {
      // scroll when menu is open
      document
        .querySelector(`#${props.id} .${style.selectMenu}`)
        ?.scrollIntoView({ block: "nearest" });
    }
  }, [isMenuOpen]);

  useEffect(() => {
    //if in modal, "confirm" button must be bypassed forcing handleChange
    if (props.isInsideModal && props.onChange) {
      props.onChange(selectedElements);
    }
  }, [selectedElements]);

  useEffect(() => {
    if (
      props.selectedOptions?.length > 0 &&
      !props.radioInsideSelect?.selected &&
      props.radioInsideSelect?.onChange
    ) {
      //if the select has some initial values --> select its own radio button
      props.radioInsideSelect.onChange(props.id);
    }

    const closeMenuClickingOutside = () => {
      const menu = document.querySelector(`#${props.id} .${style.selectMenu}`);
      if (menu) {
        setIsMenuOpen(false);
      }
    };
    //close menu on click outside
    document.addEventListener("click", closeMenuClickingOutside);

    return () => {
      document.removeEventListener("click", closeMenuClickingOutside);
    };
  }, []);

  const refScroll = useRef();

  const handleScroll = (e) => {
    try {
      let element = e?.target;
      if (!element) {
        return;
      }
      if (!element.scrollHeight) {
        element = element.scrollingElement;
      }

      const scrollHeight = element.scrollHeight;
      const clientHeight = element.clientHeight;
      const scrollTop = element.scrollTop;

      const scrollbarPresent = scrollHeight - 1 > clientHeight;
      const arrivedToBottom =
        Math.round((100 * scrollTop) / (scrollHeight - clientHeight)) || 0;

      const scrollingPosition =
        scrollHeight <= clientHeight
          ? style.unscrollable
          : scrollTop === 0
          ? style.topPosition
          : scrollbarPresent && arrivedToBottom < 97
          ? style.middlePosition
          : style.bottomPosition;

      if (refScroll?.current) {
        refScroll.current.className = style.selectMenuList;
        refScroll.current.classList.add(scrollingPosition);
      }
    } catch (e) {
      // console.error("error during shadow scroll handling in ModalSurvey", e);
    }
  };

  useEffect(() => {
    //triggerHandleScroll for the first time to set menu shadows
    handleScroll({ target: refScroll.current });
  }, [handleScroll]);

  const onClickTrigger = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const handleClick = (e) => {
    onClickTrigger();

    //if mobile, open the modal, otherwise open just the menu
    if (breakpoint.isPhoneOrTabletPortrait && !props.isInsideModal) {
      setShowModal(true);
    } else {
      setIsMenuOpen(!isMenuOpen);
    }

    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (props.isMultiSelect && !!selectedElements) {
      let disabledMapTemp = {};

      selectedElements.forEach((selectedOption) => {
        if (selectedOption.exclude?.length > 0) {
          //exclude options
          selectedOption.exclude.forEach((excludedOptionId) => {
            disabledMapTemp[excludedOptionId] = true;
          });
        }
      });

      //just if they are different --> update state
      if (!shallowEqual(disabledMap, disabledMapTemp)) {
        setDisabledMap(disabledMapTemp);
      }
    }
  }, [JSON.stringify(selectedElements || "")]);

  const confirmChange = (val: SelectOptionProps | SelectOptionProps[]) => {
    //if user has selected an option in a select, select its radio button
    if (
      props.radioInsideSelect?.selected !== props.id &&
      props.radioInsideSelect?.onChange
    ) {
      props.radioInsideSelect.onChange(props.id);
    }

    if (props.onChange) {
      props.onChange(val);
    }
  };

  useEffect(() => {
    if (props.isMultiSelect && !!selectedElements) {
      let disabledMapTemp = {};

      selectedElements.forEach((selectedOption) => {
        if (selectedOption.exclude?.length > 0) {
          //exclude options
          selectedOption.exclude.forEach((excludedOptionId) => {
            disabledMapTemp[excludedOptionId] = true;
          });
        }
      });

      //just if they are different --> update state
      if (!shallowEqual(disabledMap, disabledMapTemp)) {
        setDisabledMap(disabledMapTemp);
      }
    }
  }, [JSON.stringify(selectedElements || "")]);

  useEffect(() => {
    // Filter options based on the input text
    const filtered = props.options.filter((option) =>
      option.label.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [filterText, props.options]);

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget) && isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      <ButtonSurvey
        displayNone={props.hideSelectContainer}
        onClick={() => {
          setShowModal(true);
          onClickTrigger();
        }}
        icon={props.icon.src}
        translatedLabel={props.placeholder}
        values={props.selectedOptions
          ?.sort((a, b) => a.order - b.order)
          .map((selection) => selection.labelAfterSelection || selection.label)}
      />
      {(!showModal || props.isInsideModal) && (
        <>
          {(breakpoint.isDesktopOrTabletLandscape ||
            props.hideSelectContainer) && (
            <div
              className={clsx(style.selectContainer, {
                [style.selectContainerWithValueSelected]:
                  props.selectedOptions?.length > 0,
                [style.selectError]: props.isError,
                [style.selectErrorWithMessage]: props.errorMessage,
                [style.isInsideModal]: props.isInsideModal,
              })}
              id={props.id}
              onClick={(e) => e.stopPropagation()}
              onBlur={handleBlur}
              tabIndex={0}
              aria-label={"Select options for " + props.placeholder}
              onKeyDown={(e) =>
                handleOnEnterKeyPress(
                  e,
                  () => {
                    setIsMenuOpen(true);
                    // setTimeout(() => {
                    //   //focus the first element
                    //   document
                    //     .querySelector("." + style.selectMenu + " input")
                    //     ?.focus();
                    // }, 0);
                  },
                  true,
                  true
                )
              }
            >
              <div className={style.select}>
                <div
                  className={`${style.selectControl}
                    ${isMenuOpen ? style.selectOpen : ""}`}
                  onClick={handleClick}
                >
                  <div className={style.selectControlLeft}>
                    {props.radioInsideSelect?.name && !props.isInsideModal && (
                      <RadioButton
                        id={props.id}
                        className={`${style.radioInsideSelect} ${
                          !!props.className && props.className
                        } ${
                          props.radioInsideSelect.hidden
                            ? style.radioInsideSelectHidden
                            : ""
                        }`}
                        name={props.radioInsideSelect.name}
                        checked={props.radioInsideSelect.selected === props.id}
                        onChange={(e) => {
                          if (props.radioInsideSelect?.onChange) {
                            props.radioInsideSelect.onChange(e.target.value);
                          }
                        }}
                        useContainer={true}
                        ariaLabel={props.placeholder}
                        onTouchEnd={(e) => {
                          if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                          if (props.radioInsideSelect?.onChange) {
                            props.radioInsideSelect.onChange(props.id);
                          }
                        }}
                      />
                    )}

                    {props.icon.src && (
                      <LazyImage
                        src={props.icon.src}
                        alt={props.icon.alt}
                        width="24px"
                        height="24px"
                        className={style.selectImage}
                      />
                    )}
                    <input
                      className={style.selectInput}
                      type="text"
                      placeholder={props.placeholder}
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                      disabled={
                        breakpoint.isPhoneOrTabletPortrait ||
                        props.isInsideModal
                      }
                      // onFocus={() => setIsMenuOpen(true)}
                      tabIndex={-1}
                    />
                  </div>
                  <div className={style.selectIndicator}>
                    <LazyImage
                      className={`${style.addIcon} ${
                        isMenuOpen ? style.closeIcon : ""
                      }`}
                      src={
                        window.rxrEnv?.ASSETS_PATH +
                        "/icons/" +
                        (isMenuOpen
                          ? "close.svg"
                          : props.selectedOptions?.length > 0
                          ? "edit.svg"
                          : "plus-icon.svg")
                      }
                      alt={translate(
                        isMenuOpen
                          ? "wcag.wcag_x_icon"
                          : props.selectedOptions?.length > 0
                          ? "wcag.wcag_edit"
                          : "wcag.wcag_plus_icon"
                      )}
                      width="20px"
                      height="20px"
                    />
                  </div>
                </div>
                <div
                  aria-live="polite"
                  style={{
                    position: "absolute",
                    width: "1px",
                    height: "1px",
                    margin: "-1px",
                    border: "0",
                    padding: "0",
                    overflow: "hidden",
                    clip: "rect(0, 0, 0, 0)",
                    clipPath: "inset(50%)",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Selected options: " +
                    props.selectedOptions
                      .map((option) => option.label)
                      .join(", ")}
                </div>
                {(props.selectedOptions?.length > 0 || props.errorMessage) && (
                  <div
                    className={style.selectedElementsContainer}
                    onClick={handleClick}
                  >
                    <div className={style.selectedElementsSeparator} />

                    {/* if multiselect, props.selectProps.value is an array. Otherwise it is a single object */}
                    {props.selectedOptions?.length > 0 &&
                      props.selectedOptions
                        ?.sort((a, b) => a.order - b.order)
                        .map((selection) => (
                          <div
                            key={selection.value}
                            className={style.selectedElement}
                          >
                            <span className={style.selectedChip}>
                              {selection.labelAfterSelection || selection.label}
                            </span>
                          </div>
                        ))}

                    {props.errorMessage && (
                      <div className={style.errorMessageContainer}>
                        <LazyImage
                          className={style.errorMessageIcon}
                          src={
                            window.rxrEnv?.ASSETS_PATH +
                            "/icons/select-danger.svg"
                          }
                          width="16px"
                          height="16px"
                          alt={translate("wcag.wcag_danger_icon")}
                        />
                        <p>{props.errorMessage}</p>
                      </div>
                    )}
                  </div>
                )}
                {isMenuOpen && (
                  <div
                    className={style.selectMenu}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className={style.selectMenuList}
                      ref={refScroll}
                      onScroll={handleScroll}
                    >
                      {filteredOptions.map((option) => (
                        <Option
                          optionCallbackActions={{
                            selectedElements: selectedElements,
                            setSelectedElements: setSelectedElements,
                            openMenuSetterFunction: setIsMenuOpen,
                            confirmChange: confirmChange,
                          }}
                          selectProps={props}
                          key={option.id}
                          option={option}
                          isDisabled={disabledMap[option.id]}
                          isSelected={
                            selectedElements.findIndex(
                              (a) => a.id === option.id
                            ) >= 0
                          }
                        />
                      ))}
                    </div>

                    <div
                      className={`${style.ConfirmButtonContainer}
                      ${props.isInsideModal ? style.none : ""}`}
                    >
                      <Button
                        variant="dark"
                        className={style.ConfirmButton}
                        disabled={selectedElements.length === 0}
                        onClick={() => {
                          confirmChange(selectedElements);
                          setIsMenuOpen(false);
                          props.forcedMenuClosingInfo?.isOrSelect &&
                            props.forcedMenuClosingInfo?.fallbackCloseAllMenus();
                        }}
                        onKeyDown={(e) =>
                          handleOnEnterKeyPress(
                            e,
                            () => {
                              confirmChange(selectedElements);
                              setIsMenuOpen(false);
                            },
                            true,
                            true
                          )
                        }
                      >
                        {translate("survey.confirm")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {showModal && !props.isInsideModal && (
        <>
          <ModalSurvey
            id={props.id}
            className={showModal ? "" : style.none}
            isOpen={showModal}
            htmlTitle={props.placeholder}
            onClose={() => setShowModal(false)}
            icon={props.icon.src}
            footerCtaAction={() => {
              setShowModal(false);
              confirmChange(selectedElements);
            }}
            footerCtaLabel={translate("survey.confirm")}
            footerCtaDisabled={selectedElements.length === 0}
            footerTopElement={null}
          >
            <SelectCustom
              {...props}
              id={props.id}
              isInsideModal={true}
              hideSelectContainer={true}
              onChange={setSelectedElements}
              selectedOptions={props.selectedOptions}
            />
          </ModalSurvey>
        </>
      )}
    </>
  );
};

export default SelectCustom;
