import React, { ChangeEvent, ReactNode } from "react";
import style from "./style.module.scss";
import { clsx } from "@utility/utility";

type Props = {
  id: string;
  name: string;
  checked: boolean;
  label?: string;
  children?: ReactNode;
  className?: string;
  classNameCheckmark?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  showMinusIconOnDisable?: boolean;
  ariaLabelledBy?: string;
  onKeyDown?: () => void;
  isInsideSelect?: boolean;
};

export default function Checkbox(props: Props) {
  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation(); // Prevents click from bubbling up to the label
    if (!props.readOnly && !props.disabled) {
      // Toggle checked state
      const newCheckedState = !props.checked;

      // If onChange is provided, call it with the new state
      if (props.onChange) {
        // Create a synthetic ChangeEvent to match the expected type
        const syntheticEvent = {
          target: {
            name: props.name,
            value: newCheckedState,
            checked: newCheckedState,
          },
        } as unknown as ChangeEvent<HTMLInputElement>;

        props.onChange(syntheticEvent);
      }
    }
  };

  return (
    <label
      htmlFor={props.id}
      className={`${style.container} ${props.className}`}
    >
      <input
        type="checkbox"
        id={props.id}
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        readOnly={props.readOnly}
        disabled={props.disabled}
        aria-labelledby={props.ariaLabelledBy}
      />
      <span
        className={clsx(style.checkmark, {
          [props.classNameCheckmark]: !!props.classNameCheckmark,
          [style.showMinusIconOnDisable]: props.showMinusIconOnDisable,
        })}
        onClick={props.isInsideSelect ? handleClick : undefined}
      >
        {props.checked && (
          <img
            src={
              window.rxrEnv?.ASSETS_PATH + "/icons/checkbox/checkbox-check.svg"
            }
          />
        )}
        {props.showMinusIconOnDisable && props.disabled && !props.checked && (
          <img
            src={
              window.rxrEnv?.ASSETS_PATH +
              "/icons/checkbox/checkbox-disabled.svg"
            }
          />
        )}
      </span>
      {!!props.label && props.label}
      {props.children}
    </label>
  );
}
