import { useAutoFocus } from "@hooks/useAutoFocus";
import { useFocusTrap } from "@hooks/useFocusTrap";
import { printHTML, removeHTMLtags } from "@utility/htmlUtility";
import { SELECTOR_RXR } from "@utility/utility";
import React, { memo, useState } from "react";
import { createPortal } from "react-dom";
import Button from "../Button/component";
import style from "./style.module.scss";
import { useTranslation } from "@hooks/useTranslation";

type ModalLegalProps = {
  isOpen?: boolean;
  className?: string;
  htmlTitle?: string;
  htmlDescription?: string;
  children?: React.ReactNode;
  onClose: () => void;
};

const ModalLegal = memo(
  ({
    isOpen = true,
    className = "",
    htmlTitle = "",
    htmlDescription = "",
    children,
    onClose = () => {},
  }: ModalLegalProps) => {
    useFocusTrap({ isOpen, onClose, selector: "." + style.modal });
    useAutoFocus({ selector: "." + style.closeIcon });
    const [isFadingOut, setIsFadingOut] = useState(false);

    const translate = useTranslation();

    const handleCloseModal = () => {
      setIsFadingOut(true);

      setTimeout(() => {
        setIsFadingOut(false);
        onClose();
      }, 200);
    };

    return isOpen
      ? createPortal(
          <div
            className={`${style.modal} ${className} ${
              isFadingOut && style.fadingOut
            }`}
            aria-live="polite"
            aria-modal="true"
            aria-labelledby={removeHTMLtags(htmlTitle)}
            tabIndex={0}
          >
            <div className={`${style.modalBody}`}>
              <div className={`${style.titleRow}`}>
                <p
                  className={style.htmlTitle}
                  dangerouslySetInnerHTML={printHTML(htmlTitle)}
                />
                <Button
                  ariaLabel={translate("wcag.wcag_close_modal")}
                  className={style.closeIcon}
                  variant="underline"
                  icon="close"
                  onClick={handleCloseModal}
                  analyticsInfo={{
                    "data-analytics_available_call": 0,
                  }}
                />
              </div>
              <div className={style.descriptionSection}>
                <p
                  className={style.description}
                  dangerouslySetInnerHTML={printHTML(htmlDescription)}
                />
                {children}
              </div>
            </div>
          </div>,
          document.querySelector(SELECTOR_RXR)
        )
      : null;
  }
);

export default ModalLegal;
