import React, { useEffect, useState } from "react";
import { useTranslation } from "@hooks/useTranslation";
import { QuestionType } from "@model/SurveyModel";
import SelectCustom, {
  RadioInsideSelectProps,
} from "@components/common/SelectCustom/component";
import { Controller } from "react-hook-form";
import { transformIntoArray } from "@utility/utility";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";

type SelectSurveyProps = {
  isMulti?: boolean;
  question: QuestionType;
  formData: any;
  optional?: boolean;
  radioInsideSelect?: RadioInsideSelectProps;
  forcedMenuClosingInfo?: {
    forcedMenuClosing: boolean;
    isOrSelect: boolean;
    fallbackCloseAllMenus?: () => void;
  };
};
const SelectSurvey = ({
  isMulti = false,
  question,
  formData,
  optional = false,
  radioInsideSelect = undefined,
  forcedMenuClosingInfo,
}: SelectSurveyProps) => {
  const translate = useTranslation();
  const breakpoint = useBreakpointBoolean();
  const [options, setOptions] = useState([]);
  const value = formData?.watch(question?.idFE);

  useEffect(() => {
    let optionsTemp = [];

    //convert survey answer structure into select option structure
    question.answers.forEach((answer, index) => {
      let optionTemp = {
        value: answer.idFE,
        id: answer.id,
        label: answer.title,
        description: answer.description,
        logo: answer.imagePath,
        exclude: answer.excludes,
        labelAfterSelection: answer.titleRecap,
        group: answer.group,
        order: index,
      };

      optionsTemp.push(optionTemp);
    });

    //handle answer groups
    if (optionsTemp?.length > 0) {
      let groupTemp = optionsTemp[0].group;
      optionsTemp.forEach((optionTemp) => {
        if (groupTemp != optionTemp.group) {
          //use separator at group change
          optionTemp.separator = true;
          groupTemp = optionTemp.group;
        }
      });
    }

    setOptions(optionsTemp);
  }, [question]);

  useEffect(() => {
    //trigger to change form controller rules
    formData?.trigger();
  }, [radioInsideSelect?.selected === question?.idFE]);

  // set to null the value of SelectSurvey if not selected (radioInsideSelect.selected)
  useEffect(() => {
    if (
      radioInsideSelect?.selected &&
      radioInsideSelect?.selected !== question?.idFE
    ) {
      formData.setValue(question?.idFE, null);
    }
  }, [radioInsideSelect?.selected]);

  return (
    <Controller
      control={formData.control}
      name={question.idFE}
      rules={{
        required: !optional || radioInsideSelect?.selected === question.idFE,
      }}
      render={({ field: { onChange: controllerOnChange } }) => (
        <SelectCustom
          onChange={controllerOnChange}
          id={question.idFE}
          options={options}
          isMultiSelect={isMulti}
          forcedMenuClosingInfo={forcedMenuClosingInfo}
          selectedOptions={transformIntoArray(value)}
          icon={
            question.placeholder?.iconPath
              ? {
                  src: question.placeholder?.iconPath,
                  alt: translate("wcag.wcag_placeholder_icon")?.replace(
                    "{{question}}",
                    question.placeholder?.label || ""
                  ),
                }
              : null
          }
          placeholder={question.placeholder?.label || ""}
          radioInsideSelect={radioInsideSelect}
          isError={
            !!formData.formState?.errors?.[question.idFE] &&
            formData.formState?.dirtyFields?.[question.idFE]
          }
        />
      )}
    />
  );
};

export default SelectSurvey;
