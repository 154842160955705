import React, { memo, useEffect, useState } from "react";
import { QuestionType } from "@model/SurveyModel";
import style from "./style.module.scss";
import Button from "@components/common/Button/component";
import TextError from "@components/common/TextError/component";
import { useTranslation } from "@hooks/useTranslation";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectCountryList } from "@slices/surveySlice";

type GeolocationButtonProps = {
  question: QuestionType;
  stepQuestions: QuestionType[];
  formData: any;
  isThisButtonSelected: boolean;
  onClick?: () => void;
  setForcedStateSelectionFromGeo?: (country_code) => void;
};
function GeolocationButton({
  question,
  stepQuestions,
  formData,
  isThisButtonSelected,
  onClick,
  setForcedStateSelectionFromGeo,
}: GeolocationButtonProps) {
  const translate = useTranslation();
  const countryList = useSelector(selectCountryList);

  const [selectStateId, setSelectStateId] = useState<string>("");
  const [isGeolocating, setIsGeolocating] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string>("");

  useEffect(() => {
    if (stepQuestions?.length > 0) {
      //get a select-state question
      const selectStateQuestion = stepQuestions.find(
        (question) => question.type === "select-state"
      );

      setSelectStateId(selectStateQuestion.idFE);
    }
  }, [stepQuestions]);

  useEffect(() => {
    formData.trigger(selectStateId);
  }, [formData.getValues(selectStateId)]);

  const forceSelectionHandler = (country_code: string) => {
    if (setForcedStateSelectionFromGeo) {
      setForcedStateSelectionFromGeo(null);
      setTimeout(() => setForcedStateSelectionFromGeo(country_code), 0);
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    setIsGeolocating(true);
    if (errorKey) {
      setErrorKey("");
    }

    //geolocalization API documentation --> https://ipapi.com/documentation
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        if (response?.data) {
          let country = response.data.country_code || "";
          let state = response.data.region_code || response.data.region || "";
          state = (country + "-" + state)?.toUpperCase();
          console.log("state", state);

          const countryFromList = countryList?.find(
            (country) => country.code === state
          );
          if (state && selectStateId && countryFromList) {
            //change data for the select state input
            formData?.setValue(selectStateId, {
              value: countryFromList.code,
              id: countryFromList.code,
              label: countryFromList.label,
              description: "",
              logo: "",
              exclude: "",
              labelAfterSelection: countryFromList.label,
              group: 1,
              order: 0,
            });
            //force 'select state' selection from geolocation
            forceSelectionHandler(country.code);
          } else {
            formData?.setValue(selectStateId, {
              value: state,
              id: state,
              label: response.data.country_name,
              description: "",
              logo: "",
              exclude: "",
              labelAfterSelection: response.data.country_name,
              group: 1,
              order: 0,
            });
            // setErrorKey("survey.geolocalitation_state_not_valid");
            forceSelectionHandler(state);
          }
        }
      })
      .catch((error) => {
        formData?.setValue(selectStateId, null);
        console.error("Error during geolocation request", error);
        setErrorKey("geolocalitation-error");
        forceSelectionHandler(null);
      })
      .finally(() => {
        setIsGeolocating(false);
      });
  };

  return (
    <div className={style.geolocationButtonContainer}>
      <Button
        className={style.button}
        variant="lightBlue"
        onClick={handleClick}
        disabled={isGeolocating}
        icon="custom"
        iconInfo={{
          position: "left",
          path: question?.placeholder?.iconPath,
          width: "16px",
          height: "16px",
          alt: translate("wcag.wcag_geolocalization_icon"),
        }}
      >
        {question?.placeholder?.label || ""}
      </Button>

      {errorKey && isThisButtonSelected && (
        <TextError
          className={style.errorText}
          htmlLabel={translate(errorKey)}
          useMarginTop={true}
        />
      )}
    </div>
  );
}

export default memo(GeolocationButton);
