import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import stylePageCardMobile from "@components/PageCardMobile/style.module.scss";
import Button from "@components/common/Button/component";
import { useTranslation } from "@hooks/useTranslation";
import { printHTML } from "@utility/htmlUtility";
import { useNavigate } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import PageStandard from "@components/common/PageStandard/component";
import ModalLegal from "@components/common/ModalLegal/component";
import { selectConfig } from "@slices/configSlice";
import { useDispatch, useSelector } from "react-redux";
import YouWillNeedComponent from "@components/YouWillNeedComponent/component";
import {
  selectFirstTimeInStartPage1,
  utilityActions,
} from "@slices/utilitySlice";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type Props = {};

function StartPage_1(props: Props) {
  const translate = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const breakpoint = useBreakpointBoolean();
  const config = useSelector(selectConfig).config;

  const [animateContentText, setAnimateContentText] = useState(false);
  const _firstTimeInStartPage1 = useSelector(selectFirstTimeInStartPage1);
  const [firstTimeInStartPage1, setFirstTimeInStartPage1] = useState(
    _firstTimeInStartPage1
  );

  useEffect(() => {
    //set this state to remember we already visited this page once, in order not to see again intro video and "i understand..." div
    dispatch(
      utilityActions.setFirstTimeInStartPage1({ firstTimeInStartPage1: false })
    );
  }, []);

  const panelsInfo = {
    accessibility: {
      title: "start_page1.accessibility",
      description: "start_page1.accessibility_panel_content",
    },
    about: {
      title: "start_page1.about",
      description: "start_page1.about_panel_content",
    },
    privacypolicy: {
      title: "start_page1.privacy_policy",
      description: "start_page1.privacy_policy_panel_content",
    },
    privacynotice: {
      title: "start_page1.privacy_notice",
      description: "start_page1.privacy_notice_panel_content",
    },
    privacypractices: {
      title: "start_page1.privacy_practices",
      description: "start_page1.privacy_practices_panel_content",
    },
    termsofuse: {
      title: "start_page1.terms_of_use",
      description: "start_page1.terms_of_use_panel_content",
    },
    serviceagreement: {
      title: "start_page1.service_agreement",
      description: "start_page1.service_agreement_panel_content",
    },
  };

  const [showAccessibilityPanel, setShowAccessibilityPanel] = useState<
    | "accessibility"
    | "about"
    | "privacypolicy"
    | "privacynotice"
    | "privacypractices"
    | "termsofuse"
    | "serviceagreement"
    | ""
  >("");

  useEffect(() => {
    if (translate("start_page1.policy_banner")) {
      const handlePrivacyPolicyClick = () => {
        setShowAccessibilityPanel((value) =>
          value === "privacypolicy" ? "" : "privacypolicy"
        );
      };
      const handlePrivacyNoticeClick = () => {
        setShowAccessibilityPanel((value) =>
          value === "privacynotice" ? "" : "privacynotice"
        );
      };
      const handlePrivacyPracticesClick = () => {
        setShowAccessibilityPanel((value) =>
          value === "privacypractices" ? "" : "privacypractices"
        );
      };
      const handleTermsOfUseClick = () => {
        setShowAccessibilityPanel((value) =>
          value === "termsofuse" ? "" : "termsofuse"
        );
      };
      const handleServiceAgreementClick = () => {
        setShowAccessibilityPanel((value) =>
          value === "serviceagreement" ? "" : "serviceagreement"
        );
      };

      const addAccessibilityEventListener = (id, handler) => {
        const element = document.getElementById(id);
        if (element) {
          element.addEventListener("click", handler);
          element.addEventListener("keydown", (event) => {
            if (event.key === "Enter" || event.key === " ") {
              event.preventDefault(); // Prevenire l'eventuale scroll della pagina nel caso di Space
              handler();
            }
          });
        }
      };

      setTimeout(() => {
        addAccessibilityEventListener(
          "privacypolicy",
          handlePrivacyPolicyClick
        );
        addAccessibilityEventListener(
          "privacynotice",
          handlePrivacyNoticeClick
        );
        addAccessibilityEventListener(
          "privacypractices",
          handlePrivacyPracticesClick
        );
        addAccessibilityEventListener("termsofuse", handleTermsOfUseClick);
        addAccessibilityEventListener(
          "serviceagreement",
          handleServiceAgreementClick
        );
      }, 1000);

      return () => {
        const removeAccessibilityEventListener = (id, handler) => {
          const element = document.getElementById(id);
          if (element) {
            element.removeEventListener("click", handler);
            element.removeEventListener("keydown", handler);
          }
        };

        removeAccessibilityEventListener(
          "privacypolicy",
          handlePrivacyPolicyClick
        );
        removeAccessibilityEventListener(
          "privacynotice",
          handlePrivacyNoticeClick
        );
        removeAccessibilityEventListener(
          "privacypractices",
          handlePrivacyPracticesClick
        );
        removeAccessibilityEventListener("termsofuse", handleTermsOfUseClick);
        removeAccessibilityEventListener(
          "serviceagreement",
          handleServiceAgreementClick
        );
      };
    }
  }, [translate("start_page1.policy_banner")]);

  //callback useful to activate the animations
  const afterIntroCallback = () => {
    const timeoutContentText = setTimeout(() => {
      setAnimateContentText(true);
    }, 1000);
  };

  return (
    <>
      <PageStandard
        classNamePage={style.StartPage1}
        classNamePageBody={`${stylePageCardMobile.pageCardMobileBody} ${style.StartPage1Body}`}
        showHeader={true}
        showIntro={
          !breakpoint.isDesktopOrTabletLandscape && firstTimeInStartPage1
        }
        afterIntroCallback={afterIntroCallback}
        showBackgroundVideo={true}
        headerProps={{
          hidePhoneBackButton: true,
          animate: true,
        }}
      >
        <div className={style.StartPage1BodyContent}>
          <div
            className={`${style.StartPage1HeaderContent} ${
              animateContentText && style.show
            }`}
          >
            <div className={style.HiUserMessage}>
              {translate("start_page1.hi_user_welcome").replace(
                "{{user}}",
                config?.name
              )}
            </div>
            <div
              className={style.MyPrescriptionRenewalTitle}
              dangerouslySetInnerHTML={printHTML(
                translate("start_page1.my_prescription_renewal_1_title")
              )}
            />
          </div>
          <div
            className={`${style.MyPrescriptionRenewalContent} ${
              animateContentText && style.show
            }`}
            dangerouslySetInnerHTML={printHTML(
              translate("start_page1.my_prescription_renewal_1_content_1")
            )}
          />
          <Button
            className={`${style.MyPrescriptionRenewalButton} ${
              animateContentText && style.show
            }`}
            onClick={() => navigate(ROUTING.START_2.url)}
            variant="dark"
            analyticsInfo={{
              "data-element-id": "RxRenewal_Landing-RenewYourPrescription",
              "data-description": formatAnalyticsInnerText(
                translate("footer.renewal_your_prescription")
              ),
              "data-analytics_available_call": 1,
            }}
          >
            {translate("footer.renewal_your_prescription")}
          </Button>
          {breakpoint.isDesktopOrTabletLandscape && (
            <div
              className={`${style.fakeCard} ${
                animateContentText && style.show
              }`}
            >
              <div className={`${style.fakeCard2}`}>
                <div className={`${style.StartPage2Preview}`}>
                  <YouWillNeedComponent />
                </div>
              </div>
            </div>
          )}
        </div>
      </PageStandard>
      {showAccessibilityPanel && (
        <ModalLegal
          htmlTitle={translate(panelsInfo[showAccessibilityPanel]?.title)}
          htmlDescription={translate(
            panelsInfo[showAccessibilityPanel]?.description
          )}
          onClose={() => setShowAccessibilityPanel("")}
        />
      )}
      {firstTimeInStartPage1 && (
        <div
          className={`${style.BeforeYouStartPopUp} ${
            animateContentText && style.show
          }`}
        >
          <div
            className={style.BeforeYouStartContent}
            dangerouslySetInnerHTML={printHTML(
              translate("start_page1.policy_banner")
                .replace(
                  "{{PrivacyPolicy}}",
                  `<a tabIndex="0" id="privacypolicy">${translate(
                    "start_page1.privacy_policy"
                  )}</a>`
                )
                .replace(
                  "{{PrivacyNotice}}",
                  `<a tabIndex="0" id="privacynotice">${translate(
                    "start_page1.privacy_notice"
                  )}</a>`
                )
                .replace(
                  "{{PrivacyPractices}}",
                  `<a tabIndex="0" id="privacypractices">${translate(
                    "start_page1.privacy_practices"
                  )}</a>`
                )
                .replace(
                  "{{TermsOfUse}}",
                  `<a tabIndex="0" id="termsofuse">${translate(
                    "start_page1.terms_of_use"
                  )}</a>`
                )
            )}
          />
        </div>
      )}
    </>
  );
}

export default StartPage_1;
