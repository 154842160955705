import { selectConfig } from "@slices/configSlice";
import { selectTBTInfo } from "@slices/surveySlice";
import { selectLabelInfo } from "@slices/utilitySlice";
import { getUtagStaticInfoPage } from "@utility/analyticsUtility";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

type useTagParams = {
  sendAtEveryPage?: boolean;
  listDynamicFields?: string[];
  customUtagInfo?: any;
};

export const useUtag = ({
  sendAtEveryPage = true,
  listDynamicFields,
  customUtagInfo = {},
}: useTagParams) => {
  const location = useLocation();
  const config = useSelector(selectConfig);
  const labelInfo = useSelector(selectLabelInfo);
  const TBTInfo = useSelector(selectTBTInfo);
  const [lastLocation, setLastLocation] = useState("");

  useEffect(() => {
    if (
      sendAtEveryPage &&
      location.pathname === window.location.pathname &&
      location.pathname !== lastLocation
    ) {
      setLastLocation(location.pathname);
      sendAnalytics("", listDynamicFields, customUtagInfo);
    }
  }, [location]);

  const sendAnalytics = (
    analyticsKey?: string,
    listDynamicFields?: string[],
    customUtagInfo?: any
  ) => {
    if (!window.tealium_data2track) {
      return;
    }

    //retrieve info set by the ecommerce
    const utagData = window.utag_data || {};

    //retrieve info for the actual page
    const utagStaticPageInfo = getUtagStaticInfoPage("", analyticsKey || "");
    if (!utagStaticPageInfo || Object.keys(utagStaticPageInfo).length === 0) {
      //actual routing doesn't have analytics page view
      return;
    }

    let utagDynamicPageInfo = {
      //CONTEXT: COMMON/CORE
      Page_Brand: utagData?.Page_Brand || getPageBrand(),
      Page_Country: utagData?.Page_Country || getPageCountry(),
      Page_Language: getPageLanguage(),
      Page_Platform: utagData?.Page_Platform || "WHITELABEL_RXR",
      Order_Currency: utagData?.Order_Currency,
      Page_DeviceType: utagData?.Page_DeviceType || getPageDeviceType(),
      Page_Server: utagData?.Page_Server, //set by the ecommerce through utag_data or not set (TBD which solution to use)

      //CONTEXT: USER
      User_LoginStatus: utagData?.User_LoginStatus || "Guest",
      User_LoginType: utagData?.User_LoginType || "Standard",
      User_Email_MD5: utagData?.User_Email_MD5, //set by the ecommerce through utag_data
      User_Email_SHA256: utagData?.User_Email_SHA256, //set by the ecommerce through utag_data
      User_Country: getUserCountry(),
      User_Id: utagData?.User_Id || getUserId(),
      User_Segments: utagData?.User_Segments,

      //CONTEXT: RX
      Rx_Renewal_Release: process?.env?.VERSION || "",
    };

    let utagDynamicPageInfoFiltered = {};
    if (Array.isArray(listDynamicFields)) {
      listDynamicFields.forEach((field) => {
        utagDynamicPageInfoFiltered[field] = utagDynamicPageInfo?.[field];
      });
    } else {
      utagDynamicPageInfoFiltered = utagDynamicPageInfo;
    }

    //send page view
    const data2trackPayload = {
      ...(utagStaticPageInfo || {}),
      ...(utagDynamicPageInfoFiltered || {}),
      ...(customUtagInfo || {}),
    };
    console.log("Sending analytics", {
      payload: { ...data2trackPayload },
      utagStaticPageInfo,
      utagDynamicPageInfoFiltered,
      utag_data: utagData,
      customUtagInfo,
    });
    window.tealium_data2track.push(data2trackPayload);
  };

  const getPageBrand = (): string => {
    return config?.config?.brand || "";
  };
  const getPageCountry = (): string => {
    //two-letter uppercase or "INT","LAT","MEA","ROE", "EU"
    return "US";
  };
  const getPageLanguage = (): string => {
    //two-letter uppercase
    return labelInfo?.language?.slice(0, 2).toUpperCase() || "";
  };
  const getPageDeviceType = (): string => {
    //"M","D","T","X","iPAD","WALL43"
    //website Mobile, Desktop, Tablet or X for responsive version or SmartShopper devices
    return "X";
  };
  const getUserCountry = (): string => {
    // If Page_Country is INT, LAT, MEA, ROE, EU this is the country selected by the user ISO 3166-1. E.g. "LT", "RO".

    let country = "";

    if (TBTInfo?.payload) {
      country = TBTInfo.payload.eligibility_questionnarie.find(
        (question) => question.q_id === 4
      )?.q_answer?.[0];

      if (country) {
        country = country.split("-")?.[1] || country;
      }
    }

    return country;
  };
  const getUserId = (): string => {
    return config?.config?.userId || "";
  };

  return { sendAnalytics };
};
