import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import OnlineVisionTestConfig from "src/engine/OnlineVisionTestConfig";
import { RootState } from "../Store";

type TInsurancePackages = {
  packages: any[];
  clearPrice?: boolean;
  frame: any;
};

export type ConfigState = {
  imageSrcsetTemplate?: string;
  baseURLs?: Record<string, string>;
  lensPackages?: any;
  lensesData?: any;
  config?: OnlineVisionTestConfig | null;
};

const initialState: ConfigState = {
  imageSrcsetTemplate: null,
  baseURLs: null,
  lensPackages: null,
  lensesData: null,
  config: null,
};

const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    changeOptions: (state, { payload }: PayloadAction<ConfigState>) => {
      const s = { ...state, ...payload };
      return s;
    },
    changeLensPackages: (state, { payload }: PayloadAction<any>) => {
      return { ...state, lensPackages: payload };
    },
    setInsurancePackage: (
      state,
      { payload }: PayloadAction<TInsurancePackages>
    ) => {
      let copyPackages = payload.packages.map((pkg) => {
        return JSON.parse(JSON.stringify(pkg));
      });

      let mappedPayload = copyPackages.map((pkg) => {
        if (
          !payload.clearPrice &&
          !pkg.lensPackage.insPrice &&
          pkg.lensPackage.insPrice !== 0
        ) {
          pkg.lensPackage.insPrice = parseFloat(pkg.lensPackage.listPrice);
        }
        if (!pkg.frame.insPrice && pkg.frame.insPrice !== 0) {
          pkg.frame.insPrice = parseFloat(payload.frame.listPrice);
        }

        return pkg;
      });

      if (state.lensesData && state.lensesData.packages) {
        state.lensesData.packages = state.lensesData.packages.map((p) => {
          const findedByUpc = mappedPayload.find(
            (insPackage) =>
              insPackage.lensPackage &&
              insPackage.lensPackage.upc === p.lensPackage.upc
          );
          if (findedByUpc) return findedByUpc;
          return p;
        });
      }
    },
    setConfig: (state, action: PayloadAction<OnlineVisionTestConfig>) => {
      state.config = action.payload;
    },
    resetState: () => initialState,
  },
});

export const configReducer = configSlice.reducer;
export const configActions = configSlice.actions;

export const selectConfig = (
  state: RootState
): OnlineVisionTestConfig | null => {
  return state.config.config;
};
