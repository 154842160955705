import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import { printHTML } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import { useNavigate } from "react-router-dom";
import PageStandard from "@components/common/PageStandard/component";
import Checkbox from "@components/common/Checkbox/component";
import Button from "@components/common/Button/component";
import { selectConfig } from "@slices/configSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectTBTInfo, updateTBTPayload } from "@slices/surveySlice";
import LazyImage from "@components/common/LazyImage/LazyImage";

type Props = {};

function LegalDisclaimerPage(props: Props) {
  const translate = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const TBTInfo = useSelector(selectTBTInfo);
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [wearGlasses, setWearGlasses] = useState<boolean>(false);

  const [checkboxValues, setcheckboxValues] = useState<boolean[]>([
    false,
    false,
    false,
  ]);

  //when TBT and payload is retrieved, change status to ACUITY_START
  useEffect(() => {
    if (TBTInfo.payload && TBTInfo.TBT) {
      let newPayload = { ...TBTInfo.payload };
      newPayload.status = "ACUITY_START";
      dispatch(updateTBTPayload({ tokenId: TBTInfo.TBT, payload: newPayload }));
    }
  }, [!!TBTInfo.payload, !!TBTInfo.TBT]);

  useEffect(() => {
    let dateOfBirthTemp = "";
    let wearGlassesTemp = false;
    if (TBTInfo.payload) {
      dateOfBirthTemp = TBTInfo.payload.eligibility_questionnarie.find(
        (question) => question.q_id === 5
      )?.q_answer?.[0];

      wearGlassesTemp =
        TBTInfo.payload.eligibility_questionnarie.find(
          (question) => question.q_id === 6
        )?.answer_id?.length > 0;
    }
    setDateOfBirth(dateOfBirthTemp);
    setWearGlasses(wearGlassesTemp);
  }, [!!TBTInfo.payload]);

  const recapCheckbox = (
    <>
      <Checkbox
        className={style.checkboxRow}
        id={"legal_disclaimer_checkbox_1"}
        name={"legal_disclaimer_checkbox_1"}
        checked={checkboxValues[0]}
        onChange={(e) => {
          setcheckboxValues((prevValues) => [
            e?.target?.checked,
            prevValues[1],
            prevValues[2],
          ]);
        }}
      >
        {translate("legal_disclaimer.checkbox_1")}
      </Checkbox>

      <Checkbox
        className={style.checkboxRow}
        id={"legal_disclaimer_checkbox_2"}
        name={"legal_disclaimer_checkbox_2"}
        checked={checkboxValues[1]}
        onChange={(e) =>
          setcheckboxValues((prevValues) => [
            prevValues[0],
            e?.target?.checked,
            prevValues[2],
          ])
        }
      >
        {translate("legal_disclaimer.checkbox_2")}
      </Checkbox>

      <Checkbox
        className={style.checkboxRow}
        id={"legal_disclaimer_checkbox_3"}
        name={"legal_disclaimer_checkbox_3"}
        checked={checkboxValues[2]}
        onChange={(e) =>
          setcheckboxValues((prevValues) => [
            prevValues[0],
            prevValues[1],
            e?.target?.checked,
          ])
        }
      >
        {translate("legal_disclaimer.checkbox_3")
          .replace(
            "{{name}}",
            `${config.config?.name ?? ""} ${config.config?.surname ?? ""}`
          )
          .replace("{{date}}", dateOfBirth || "")
          .replace(
            "{{type}}",
            translate(
              wearGlasses
                ? "legal_disclaimer.checkbox_3_glasses"
                : "legal_disclaimer.checkbox_3_contactlenses"
            ) || ""
          )}
      </Checkbox>
    </>
  );

  return (
    <PageStandard
      className={style.LegalDisclaimer}
      classNamePageBody={style.LegalDisclaimerBody}
    >
      <>
        <p
          className={`${style.title} ${style.center}`}
          dangerouslySetInnerHTML={printHTML(
            translate("legal_disclaimer.title")
          )}
        />

        <p
          className={style.descriptionText}
          dangerouslySetInnerHTML={printHTML(
            translate("legal_disclaimer.description_intro")
          )}
        />

        <p className={`${style.sectionTitle} ${style.center}`}>
          {translate("legal_disclaimer.title_exclusions")}
        </p>

        <p
          className={style.descriptionText}
          dangerouslySetInnerHTML={printHTML(
            translate("legal_disclaimer.description_exclusions")
          )}
        />

        <p className={`${style.sectionTitle} ${style.center}`}>
          {translate("legal_disclaimer.title_checkbox")}
        </p>

        {recapCheckbox}

        <p
          className={style.descriptionText}
          dangerouslySetInnerHTML={printHTML(
            translate("legal_disclaimer.checkbox_confirm_description")
          )}
        />

        <Button
          className={style.confirmButton}
          disabled={!checkboxValues.every((value) => value === true)}
          variant="dark"
          onClick={() => navigate(ROUTING.SETUP_START.url)}
        >
          {translate("legal_disclaimer.continue_cta")}
        </Button>

        <p
          className={style.complianceStatements}
          dangerouslySetInnerHTML={printHTML(
            translate("legal_disclaimer.compliance_statements_text")
          )}
        />
        <p className={style.complianceEssilor}>
          <LazyImage
            src={window.rxrEnv?.ASSETS_PATH + "/images/factory.jpg"}
            alt={"Factory"}
            width={"16px"}
            height={"15px"}
          />
          {translate("legal_disclaimer.compliance_essilor_signature")}
        </p>
      </>
    </PageStandard>
  );
}

export default LegalDisclaimerPage;
