import React, { ReactNode } from "react";
import style from "./style.module.scss";
import LazyImage from "../LazyImage/LazyImage";
import { QuestionType } from "@model/SurveyModel";

type Props = {
  onClick?: () => void;
  children?: ReactNode;
  iconSrc?: string;
  iconAlt?: string;
  isOnFocus?: boolean;
  stepIndex: number;
  disabled?: boolean;
  tick?: "red" | "green";
};

export default function SurveyStepButton(props: Props) {
  const checkTick = () => {};

  return (
    <div
      className={`${style.SurveyStepButtonContainer} ${
        props.isOnFocus && !props.disabled ? style.isOnFocus : ""
      }`}
    >
      <div
        className={`${style.SurveyStepButtonNotch} ${
          props.isOnFocus && !props.disabled ? style.isOnFocus : ""
        }`}
      />
      <button
        tabIndex={-1}
        className={`${style.SurveyStepButton}
        ${props.disabled ? style.disabled : ""}
        ${props.isOnFocus && !props.disabled ? style.isOnFocus : ""}`}
        onClick={() =>
          props.onClick &&
          !props.disabled &&
          !props.isOnFocus &&
          props.onClick()
        }
        data-analytics_available_call={0}
      >
        <div className={style.leftContent}>{props.children}</div>
        {!!props.tick && (
          <LazyImage
            className={`${style.Icon}`}
            src={
              props.tick === "green"
                ? window.rxrEnv?.ASSETS_PATH +
                  "/icons/survey_steps_icons/green_tick.svg"
                : window.rxrEnv?.ASSETS_PATH +
                  "/icons/survey_steps_icons/warning.svg"
            }
            width={"20px"}
            height={"20px"}
            alt={props.iconAlt}
          />
        )}
      </button>
    </div>
  );
}
