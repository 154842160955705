import ButtonSurvey from "@components/common/ButtonSurvey/component";
import Checkbox from "@components/common/Checkbox/component";
import ModalSurvey from "@components/common/ModalSurvey/component";
import SelectSimple from "@components/common/SelectSimple/component";
import { getLabelFromLabelMap, useTranslation } from "@hooks/useTranslation";
import { PrescriptionType, QuestionType } from "@model/SurveyModel";
import { LabelMapType, selectLabelMap } from "@slices/utilitySlice";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import style from "./style.module.scss";

type SelectStateProps = {
  question: QuestionType;
  formData: any;
  prescriptionType: PrescriptionType;
  optional?: boolean;
};
const SelectPD = ({
  question,
  formData,
  prescriptionType,
  optional = false,
}: SelectStateProps) => {
  const translate = useTranslation();
  const labelMap = useSelector(selectLabelMap);

  const singlePdValues = Array.from(
    { length: 77 },
    (_, index) => index * 0.5 + 40
  );
  const doublePdValues = Array.from(
    { length: (38.5 - 25) / 0.5 + 1 },
    (_, index) => index * 0.5 + 25
  );

  const [showModal, setShowModal] = useState(false);

  const iHaveTwoPDs = formData.watch(question?.idFE + "_iHaveTwoPDs");
  const rightPD = formData.watch(question?.idFE + "_rightPD");
  const leftPD = formData.watch(question?.idFE + "_leftPD");
  const iDontKnowMyPD = formData.watch(question?.idFE + "_dontknowPD");
  const [answerChips, setAnswerChips] = useState<string[]>([]);

  const [oldFormValues, setOldFormValues] = useState(null);
  useEffect(() => {
    if (showModal) {
      //store old pupillary distance values
      setOldFormValues(formData.getValues());
    } else {
      formData.trigger();
    }
  }, [showModal]);

  useEffect(() => {
    //set chips in case there are already saved values in formData
    if (iDontKnowMyPD || rightPD || leftPD) {
      calculatePupillaryDistanceChips({
        iHaveTwoPDs: iHaveTwoPDs,
        iDontKnowMyPD: iDontKnowMyPD,
        leftPD: leftPD,
        rightPD: rightPD,
      });
    }
  }, []);

  useEffect(() => {
    if (showModal) {
      formData.setValue(question?.idFE + "_rightPD", "");
      formData.setValue(question?.idFE + "_leftPD", "");
    }
    formData.trigger();
  }, [iHaveTwoPDs]);

  useEffect(() => {
    formData.trigger();
  }, [iDontKnowMyPD]);

  const onModalConfirm = () => {
    setShowModal(false);

    if (!iHaveTwoPDs) {
      formData.setValue(question?.idFE + "_leftPD", "");
    }

    const rightPDValue = rightPD;
    const leftPDValue = leftPD;

    calculatePupillaryDistanceChips({
      iHaveTwoPDs: iHaveTwoPDs,
      iDontKnowMyPD: iDontKnowMyPD,
      leftPD: leftPDValue,
      rightPD: rightPDValue,
    });
  };

  const calculatePupillaryDistanceChips = (data: PupillaryDistanceDataType) => {
    const answerChipsTemp = getPupillaryDistanceChips(labelMap, data);
    setAnswerChips(answerChipsTemp);
  };

  return (
    prescriptionType.isEyeglasses && (
      <>
        <ButtonSurvey
          onClick={() => setShowModal(true)}
          icon={
            window.rxrEnv?.ASSETS_PATH +
            "/icons/survey/q9/pupillary-distance.svg"
          }
          labelKey="survey.q9_a2_title"
          values={answerChips}
          showOnDesktop={true}
        />

        <ModalSurvey
          id="PD"
          key={showModal?.toString()} //force re-render to clean data (because of X button that cleans the form)
          isOpen={true}
          hide={!showModal}
          className={`pd-modal`}
          htmlTitle={translate("survey.q9_a2_title")}
          onClose={() => {
            if (oldFormValues) {
              //restore old prescription values
              [
                question.idFE + "_iHaveTwoPDs",
                question.idFE + "_rightPD",
                question.idFE + "_leftPD",
                question.idFE + "_dontknowPD",
              ]?.forEach((key) => {
                formData.setValue(key, oldFormValues[key]);
              });
            }
            setShowModal(false);
          }}
          icon={
            window.rxrEnv?.ASSETS_PATH +
            "/icons/survey/q9/pupillary-distance.svg"
          }
          footerCtaAction={onModalConfirm}
          footerCtaLabel={translate("survey.confirm")}
          // footerCtaDisabled={
          //   !(
          //     iDontKnowMyPD ||
          //     (iHaveTwoPDs && rightPD && leftPD) ||
          //     (!iHaveTwoPDs && rightPD)
          //   )
          // }
          footerTopElement={null}
        >
          <Controller
            control={formData.control}
            name={question.idFE + "_iHaveTwoPDs"}
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                disabled={iDontKnowMyPD}
                onChange={(value) => field.onChange(value)}
                id={"iHaveTwoPDs_checkbox"}
                name={"iHaveTwoPDs_checkbox"}
                className={`${style.TwoPDsCheckbox} ${
                  iDontKnowMyPD ? style.DontKnowMyPDCase : ""
                }`}
                showMinusIconOnDisable={true}
              >
                {translate("survey.i_have_two_pds")}
              </Checkbox>
            )}
          />

          <div
            className={`${style.SelectPDController} ${
              iDontKnowMyPD ? style.DontKnowMyPDCase : ""
            }`}
          >
            {!iHaveTwoPDs && (
              <Controller
                control={formData.control}
                defaultValue={""}
                name={question.idFE + "_rightPD"}
                rules={{
                  required: !iHaveTwoPDs && !iDontKnowMyPD && !optional,
                }}
                render={({ field }) => (
                  <>
                    <p className={style.SelectPDSubTitle}>
                      {translate("survey.pupillary_distance_subtitle")}
                    </p>
                    <SelectSimple
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      className={style.SelectPD}
                      ariaLabel={"Pupillary distance"}
                      options={singlePdValues.map((answer) => ({
                        value: answer.toString(),
                        title: answer.toString(),
                      }))}
                      selection={field.value}
                      disabled={iDontKnowMyPD}
                      middleValueMenu={singlePdValues?.[0]?.toString()} //placeholder "-" as first element
                      placeholder={translate("survey.placeholder")}
                      keepPlaceholder={true}
                    />
                  </>
                )}
              />
            )}
            {iHaveTwoPDs && (
              <>
                <div className={style.twoPDsLabels}>
                  <div
                    className={`${style.SelectPDSubTitle} ${style.rightPDLabel}`}
                  >
                    {translate("survey.right_eye")}
                  </div>
                  <div
                    className={`${style.SelectPDSubTitle} ${style.leftPDLabel}`}
                  >
                    {translate("survey.left_eye")}
                  </div>
                </div>
                <div className={style.twoPDsInputs}>
                  <Controller
                    control={formData.control}
                    defaultValue={""}
                    name={question.idFE + "_rightPD"}
                    rules={{
                      required: iHaveTwoPDs && !iDontKnowMyPD && !optional,
                    }}
                    render={({ field }) => (
                      <SelectSimple
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        className={style.SelectPD}
                        options={doublePdValues.map((answer) => ({
                          value: answer.toString(),
                          title: answer.toString(),
                        }))}
                        selection={field.value}
                        middleValueMenu={doublePdValues?.[0]?.toString()} //placeholder "-" as first element
                        placeholder={translate("survey.placeholder")}
                        keepPlaceholder={true}
                      />
                    )}
                  />
                  <Controller
                    control={formData.control}
                    defaultValue={""}
                    name={question.idFE + "_leftPD"}
                    rules={{
                      required: iHaveTwoPDs && !iDontKnowMyPD && !optional,
                    }}
                    render={({ field }) => (
                      <SelectSimple
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        className={style.SelectPD}
                        options={doublePdValues.map((answer) => ({
                          value: answer.toString(),
                          title: answer.toString(),
                        }))}
                        selection={field.value}
                        middleValueMenu={doublePdValues?.[0]?.toString()} //placeholder "-" as first element
                        placeholder={translate("survey.placeholder")}
                        keepPlaceholder={true}
                      />
                    )}
                  />
                </div>
              </>
            )}
          </div>

          <hr />

          <Controller
            control={formData.control}
            name={question.idFE + "_dontknowPD"}
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onChange={(value) => field.onChange(value)}
                id={"iDontKnowMyPD_checkbox"}
                name={"iDontKnowMyPD_checkbox"}
                className={style.TwoPDsCheckbox}
                showMinusIconOnDisable={true}
              >
                {translate("survey.i_dont_know_my_pd")}
              </Checkbox>
            )}
          />
        </ModalSurvey>
      </>
    )
  );
};

export type PupillaryDistanceDataType = {
  iHaveTwoPDs: boolean;
  rightPD: string;
  leftPD: string;
  iDontKnowMyPD: boolean;
};

export const getPupillaryDistanceFinalData = (
  data: PupillaryDistanceDataType
): PupillaryDistanceDataType => {
  let newData: PupillaryDistanceDataType = data;

  if (data) {
    if (data.iDontKnowMyPD || (!data.leftPD && !data.rightPD)) {
      newData.leftPD = "";
      newData.rightPD = "";
      newData.iDontKnowMyPD = true;
    } else {
      newData.rightPD = data.rightPD;
      newData.leftPD = data.iHaveTwoPDs ? data.leftPD : "";
    }
  }
  return newData;
};

export const getPupillaryDistanceChips = (
  labelMap: LabelMapType,
  data: PupillaryDistanceDataType
): string[] => {
  const answerChipsTemp = [];

  if (Object.keys(labelMap || {})?.length > 0 && data) {
    const pdData = getPupillaryDistanceFinalData(data);

    if (pdData.iDontKnowMyPD) {
      answerChipsTemp.push(
        getLabelFromLabelMap(labelMap, "survey.pd_recap_dont_know")
      );
    } else {
      if (!data.iHaveTwoPDs && pdData.rightPD) {
        answerChipsTemp.push(
          getLabelFromLabelMap(labelMap, "survey.pd_recap_single_pd")?.replace(
            "{{value}}",
            pdData.rightPD
          )
        );
      } else {
        if (pdData.rightPD) {
          answerChipsTemp.push(
            getLabelFromLabelMap(
              labelMap,
              "survey.pd_recap_right_eye"
            )?.replace("{{value}}", pdData.rightPD)
          );
        }
        if (pdData.leftPD) {
          answerChipsTemp.push(
            getLabelFromLabelMap(labelMap, "survey.pd_recap_left_eye")?.replace(
              "{{value}}",
              pdData.leftPD
            )
          );
        }
      }
    }
  }

  return answerChipsTemp;
};

export default SelectPD;
