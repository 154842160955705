import { useTranslation } from "@hooks/useTranslation";
import { clsx } from "@utility/utility";
import React, { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import LazyImage from "../LazyImage/LazyImage";
import style from "./style.module.scss";

type SelectSimpleOption = {
  value: string;
  title: string;
};

type SelectSimpleProps = {
  options: SelectSimpleOption[];
  selection?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  ariaLabel?: string;
  className?: string;
  placeholder?: string;
  pattern?: string;
  type?: string;
  isError?: boolean;
  ariaErrormessage?: string;
  isWarning?: boolean;
  icon?: string;
  onChange?: (value: string, isDefault: boolean) => void;
  middleValueMenu?: string;
  keepPlaceholder?: boolean;
};

function SelectSimple({
  options = [],
  selection,
  defaultValue = "",
  disabled,
  id,
  name,
  ariaLabel = "",
  className = "",
  placeholder = "",
  pattern,
  type,
  isError = false,
  ariaErrormessage = "",
  isWarning = false,
  icon,
  onChange,
  middleValueMenu,
  keepPlaceholder = false,
  ...rest
}: SelectSimpleProps) {
  const translate = useTranslation();

  const [showingPlaceholder, setShowingPlaceholder] = useState<boolean>(true);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const checkboxRef = useRef();

  useEffect(() => {
    if ((selection || defaultValue) && showingPlaceholder) {
      setShowingPlaceholder(false);
    }
  }, [selection, defaultValue]);

  // set default value, if it is defined
  useEffect(() => {
    if (defaultValue && !selection) {
      setDefault(defaultValue);
    }
  }, []);

  const setDefault = (value: string) => {
    if (showingPlaceholder) {
      setShowingPlaceholder(false);
    }
    if (onChange) {
      onChange(value, true);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event?.target?.value;
    if (value && showingPlaceholder) {
      setShowingPlaceholder(false);
    }

    if (onChange) {
      onChange(value, false);
    }
  };

  const handleOnClick = () => {
    setTimeout(() => {
      if (document.activeElement === checkboxRef.current) {
        setIsMenuOpen((value) => !value);
      }
    }, 0);
  };

  const handleOnBlur = () => {
    setIsMenuOpen((value) => {
      if (value) {
        return false;
      }
    });
  };

  return (
    <div
      className={clsx(style.container, {
        [className]: !!className,
        [style.error]: isError,
        [style.warning]: isWarning,
        [style.showingPlaceholder]: showingPlaceholder,
        [style.isMenuOpen]: isMenuOpen,
        [style.middleValueMenu]: middleValueMenu && !selection,
      })}
      onClick={handleOnClick}
      onBlur={handleOnBlur}
    >
      {!!icon && (
        <LazyImage
          className={style.icon}
          src={icon}
          alt={translate("wcag.wcag_input_icon")?.replace(
            "{{placeholder}}",
            placeholder || ""
          )}
          width={"24px"}
          height={"24px"}
        />
      )}

      <select
        {...rest}
        id={id}
        className={clsx(style.select, {
          [style.inputWithIcon]: !!icon,
        })}
        placeholder={placeholder}
        onChange={handleOnChange}
        name={name}
        aria-label={ariaLabel}
        value={selection || ""} //middleValueMenu
        disabled={disabled}
        ref={checkboxRef}
        aria-invalid={isError}
        aria-errormessage={isError ? ariaErrormessage : ""}
      >
        {(showingPlaceholder || keepPlaceholder) && !middleValueMenu && (
          <option value={""} className={style.placeholder}>
            {!!placeholder ? placeholder : ""}
          </option>
        )}
        {options?.map((answer) => (
          <>
            {(showingPlaceholder || keepPlaceholder) &&
              middleValueMenu === answer.value && (
                <option value={""}>{!!placeholder ? placeholder : ""}</option>
              )}
            <option key={answer.value} value={answer.value}>
              {answer.title}
            </option>
          </>
        ))}
      </select>

      <LazyImage
        className={style.dropdownArrow}
        src={window.rxrEnv?.ASSETS_PATH + "/icons/select-dropdown-arrow.svg"}
        alt={translate("wcag.wcag_dropdown_arrow_icon")}
        width={"16px"}
        height={"16px"}
      />
    </div>
  );
}

export default memo(SelectSimple);
