import { useTranslation } from "@hooks/useTranslation";
import { clsx } from "@utility/utility";
import React, {
  CSSProperties,
  MouseEvent,
  ReactNode,
  Ref,
  forwardRef,
} from "react";
import LazyImage from "../LazyImage/LazyImage";
import style from "./style.module.scss";

/*
variant: choose the color (background and text color)
icon: choose if an icon is needed (can be void)
border: choose if a border is needed
shadow: choose if a shadow is needed
disabled: disable a button
*/

export type ButtonIconProps = "close" | "leftArrow" | "rightArrow" | "custom";
export type ButtonIconInfoProps = {
  position: "left" | "right";
  path: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  alt?: string;
};
export type ButtonVariantProps =
  | "dark"
  | "light"
  | "transparent"
  | "blur"
  | "expand"
  | "underline"
  | "lightBlue";

type ButtonProps = {
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  stretchTo330px?: boolean;
  children?: ReactNode;
  variant?: ButtonVariantProps;
  icon?: ButtonIconProps;
  iconInfo?: ButtonIconInfoProps;
  border?: boolean;
  shadow?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  form?: string;
  className?: string;
  ariaLabel?: string;
  style?: CSSProperties;
  onTouchStart?: (e) => void;
  analyticsInfo?: any;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref?: Ref<HTMLButtonElement>) => {
    const translate = useTranslation();

    const retrieveIcon = (props) => {
      if (!!props.icon) {
        let className = `${style.IconButton} ${
          props.variant == "dark" ? style.darkIcon : ""
        }`;
        let src = "";
        let width: number | string = "auto";
        let height: number | string = "11px";
        let alt = "";

        switch (props.icon) {
          case "close":
            src = window.rxrEnv?.ASSETS_PATH + "/icons/close.svg";
            height = 14;
            alt = translate("wcag.wcag_close_icon");
            break;
          case "leftArrow":
            src = window.rxrEnv?.ASSETS_PATH + "/icons/left-arrow.svg";
            alt = translate("faq.go_back");
            break;
          case "rightArrow":
            src = window.rxrEnv?.ASSETS_PATH + "/icons/right-arrow.svg";
            alt = translate("wcag.wcag_right_arrow_icon");
            break;
          case "custom":
            if (props.iconInfo) {
              src = props.iconInfo.path || "";
              if (props.iconInfo.width) {
                width = props.iconInfo.width;
              }
              if (props.iconInfo.height) {
                height = props.iconInfo.height;
              }
              if (props.iconInfo.alt) {
                alt = props.iconInfo.alt;
              }
              if (props.iconInfo.className) {
                className += " " + props.iconInfo.className;
              }
            }
            break;
        }

        if (!src) {
          return null;
        }

        return (
          <LazyImage
            className={className}
            src={src}
            width={width}
            height={height}
            alt={alt}
          />
        );
      }
    };

    return (
      <button
        className={clsx(style.Button, {
          [props.className]: !!props.className,
          [style.stretchTo330px]: !!props.stretchTo330px,
          [style[props.variant]]: [
            "dark",
            "light",
            "transparent",
            "blur",
            "expand",
            "lightBlue",
          ].includes(props.variant),
          [style.default]: ![
            "dark",
            "light",
            "transparent",
            "blur",
            "expand",
            "lightBlue",
          ].includes(props.variant),
          [style.with_border]: !!props.border,
          [style.with_shadow]: !!props.shadow,
        })}
        onClick={(e) => props.onClick && !props.disabled && props.onClick(e)}
        onKeyDown={(e) =>
          props.onKeyDown && !props.disabled && props.onKeyDown(e)
        }
        type={props.type || "button"}
        form={!!props.form ? props.form : undefined}
        disabled={props.disabled}
        aria-label={props.ariaLabel}
        style={props.style}
        onTouchStart={props.onTouchStart}
        ref={ref}
        {...props.analyticsInfo}
      >
        {props.icon == "leftArrow" ||
        props.icon == "close" ||
        (props.icon == "custom" && props.iconInfo?.position === "left")
          ? retrieveIcon(props)
          : null}
        {props.children}
        {props.icon == "rightArrow" ||
        (props.icon == "custom" && props.iconInfo?.position === "right")
          ? retrieveIcon(props)
          : null}
      </button>
    );
  }
);

export default Button;
