import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import PageStandard from "@components/common/PageStandard/component";
import { useTranslation } from "@hooks/useTranslation";
import style from "./style.module.scss";
import stylePageCardMobile from "@components/PageCardMobile/style.module.scss";
import { printHTML } from "@utility/htmlUtility";
import { useDispatch } from "react-redux";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import ModalDetectDevice from "@components/common/ModalDetectDevice/component";
import RadioButton from "@components/common/RadioButton/component";
import { acuityActions } from "@slices/acuitySlice";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type AcuityWrongPageProps = {};

function AcuityWrongPage(props: AcuityWrongPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslation();

  let eyeParam = useParams()?.eyeParam;
  let isLeftEye = eyeParam === "left";

  const formData = useForm<any>({ mode: "onChange" });
  const valueChoice = formData?.watch("userChoice");

  const onSubmit = () => {
    switch (valueChoice) {
      case "try_again":
        navigate(
          ROUTING.ACUITY_TEST.url.replace(
            ":eyeParam",
            isLeftEye ? "left-eye-tutorial" : "right-eye-tutorial"
          )
        );
        break;
      case "not_understand":
        navigate(ROUTING.ACUITY_TUTORIAL_START.url);
        break;
      case "not_see_gap":
        if (isLeftEye) {
          dispatch(
            acuityActions.setAcuityTestResults({
              eye: "left",
              result: { 10: [], 8: [], 6: [] },
            })
          );
          //wait the time to update redux
          setTimeout(() => {
            navigate(ROUTING.ACUITY_END.url);
          }, 500);
        } else {
          dispatch(
            acuityActions.setAcuityTestResults({
              eye: "right",
              result: { 10: [], 8: [], 6: [] },
            })
          );
          //wait the time to update redux
          setTimeout(() => {
            navigate(
              ROUTING.ACUITY_VIDEO.url.replace(
                ":videoId?",
                "acuity-left-eye-wrong"
              )
            );
          }, 500);
        }
        break;
      default:
        console.error("choice not recognized", valueChoice);
        break;
    }
  };

  const choices = [
    {
      id: "try_again",
      label: "acuity_wrong.choice_try_again",
    },
    {
      id: "not_understand",
      label: "acuity_wrong.choice_not_understand",
    },
    {
      id: "not_see_gap",
      label: "acuity_wrong.choice_no_see_gap",
    },
  ];

  return (
    <PageStandard
      className={`${style.acuityWrong} ${style.pageCardMobile} ${stylePageCardMobile.pageCardMobile}`}
      classNamePageBody={stylePageCardMobile.pageCardMobileBody}
      footerInfo={{
        showLeftButton: false,
        showRightButton: true,
        classNameRightButton: stylePageCardMobile.footerRightButton,
        labelRightButton: translate("footer.next"),
        actionRightButton: onSubmit,
        disabledRightButton: !valueChoice,
        analyticsInfoRightButton: {
          "data-element-id": "RxRenewal_Test-Next",
          "data-description": formatAnalyticsInnerText(
            translate("footer.next")
          ),
          "data-analytics_available_call": 0,
        },
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div
        className={`${stylePageCardMobile.overPageContainer} ${style.overPageContainer}`}
      >
        <p className={stylePageCardMobile.overPageTitle}>
          {translate(
            isLeftEye
              ? "acuity_wrong.over_title_left"
              : "acuity_wrong.over_title_right"
          )}
        </p>
      </div>

      <div className={stylePageCardMobile.pageBodyCard}>
        <p
          className={stylePageCardMobile.title}
          dangerouslySetInnerHTML={printHTML(translate("acuity_wrong.title"))}
        />
        <p
          className={`${stylePageCardMobile.description} ${style.description}`}
          dangerouslySetInnerHTML={printHTML(
            translate("acuity_wrong.description")
          )}
        />

        <Controller
          control={formData.control}
          name={"userChoice"}
          rules={{
            required: true,
          }}
          render={({ field: { onChange: controllerOnChange } }) => (
            <div className={style.radioContainer}>
              {choices.map((choice) => (
                <RadioButton
                  className={style.radioButton}
                  key={choice.id}
                  id={choice.id}
                  name={choice.id}
                  checked={valueChoice === choice.id}
                  onChange={controllerOnChange}
                  useContainer={true}
                  ariaLabel={translate(choice.label)}
                  label={translate(choice.label)}
                />
              ))}
            </div>
          )}
        />
      </div>

      <ModalDetectDevice />
    </PageStandard>
  );
}

export default AcuityWrongPage;
