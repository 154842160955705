import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import styleHeader from "../Header/style.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import LazyImage from "@components/common/LazyImage/LazyImage";
import TextError from "@components/common/TextError/component";
import Chip from "@components/common/Chip/component";
import { clsx, handleOnEnterKeyPress } from "@utility/utility";
import Button from "@components/common/Button/component";

type UploadButtonProps = {
  id: string;
  className?: string;
  classNameContainer?: string;
  labelKey: string;
  icon?: string;
  value;
  format: string;
  sizeMB?: number;
  errorSizeKeyLabel?: string;
  errorFormatKeyLabel?: string;
  onChange?: (file: File) => void;
  formatKeyLabel?: string;
};
const UploadButton = ({
  id,
  className = "",
  classNameContainer = "",
  labelKey = "",
  icon = "",
  value,
  format = "",
  sizeMB = 20,
  errorSizeKeyLabel = "",
  errorFormatKeyLabel = "",
  onChange = () => {},
  formatKeyLabel = "",
}: UploadButtonProps) => {
  const translate = useTranslation();
  const [errorKeyLabel, setErrorKeyLabel] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    let fileNameTemp = "";
    if (value) {
      fileNameTemp = value.name;
    }
    if (fileNameTemp !== fileName) {
      setFileName(fileNameTemp);
      if (!fileNameTemp) {
        const inputRef = document.getElementById(id) as HTMLInputElement;
        if (inputRef) {
          inputRef.value = "";
        }
      }
    }
  }, [value]);

  const handleOnChange = (e) => {
    console.log("e?.target?.files?.[0]", e?.target?.files?.[0]);
    //1048576 is 1MB in bytes
    if (
      sizeMB &&
      errorSizeKeyLabel &&
      e?.target?.files?.[0]?.size > sizeMB * 1048576
    ) {
      //file is too large
      setErrorKeyLabel(errorSizeKeyLabel);
    } else if (
      format &&
      !format.includes(e?.target?.files?.[0]?.type) &&
      !format.includes(e?.target?.files?.[0]?.name?.split(".").pop())
    ) {
      //file format is not correct
      setErrorKeyLabel(errorFormatKeyLabel);
    } else {
      if (onChange) {
        onChange(e?.target?.files?.[0]);

        document.querySelector("." + styleHeader.BrandImageButton).focus();
        console.log(styleHeader.BrandImageButton);
        setTimeout(() => {
          document.querySelector(`label[for="${id}"]`).focus();
        }, 0);
      }

      if (errorKeyLabel) {
        setErrorKeyLabel("");
      }
    }
  };

  const handleRemoveFile = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    setFileName("");
    onChange(null);

    const inputRef = document.getElementById(id) as HTMLInputElement;
    if (inputRef) {
      inputRef.value = "";
    }
  };

  return (
    <div className={`${classNameContainer}`}>
      {/* actual upload which is hidden */}
      <input
        type="file"
        id={id}
        hidden
        onChange={handleOnChange}
        accept={format}
        //In React, an <input type="file" /> is always an uncontrolled component because its value can only be set by a user, and not programmatically.
        //https://legacy.reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
        // value={value}
      />

      {/* our custom upload button */}
      <label
        htmlFor={id}
        className={clsx(style.uploadButton, {
          [className]: !!className,
          [style.selected]: !!value,
        })}
        tabIndex={0}
        aria-label={`${translate(labelKey)} - ${
          fileName ? `File ${fileName} is ready to be submitted` : ""
        }`}
        role="button"
        onKeyDown={(e) =>
          handleOnEnterKeyPress(
            e,
            () => {
              document.getElementById(id)?.click();
            },
            true,
            true
          )
        }
      >
        <div className={style.uploadButtonRow}>
          <div className={style.placeholderSection}>
            {icon && (
              <LazyImage
                className={style.placeholderIcon}
                src={icon}
                alt={translate(labelKey)}
                width="20px"
                height="20px"
              />
            )}
            <p className={style.placeholder}>{translate(labelKey)}</p>
          </div>

          <LazyImage
            className={style.addIcon}
            src={
              window.rxrEnv?.ASSETS_PATH +
              "/icons/" +
              (value ? "edit.svg" : "plus-icon.svg")
            }
            alt={translate(value ? "wcag.wcag_edit" : "wcag.wcag_plus_icon")}
            width="20px"
            height="20px"
          />
        </div>

        {fileName && (
          <div className={style.selectedFileContainer}>
            <Chip
              label={fileName}
              noWrap={true}
              ariaLabel={`File ${fileName} is ready to be submitted`}
            />
          </div>
        )}

        {fileName && (
          <Button
            className={style.deleteFileButton}
            variant="transparent"
            onClick={handleRemoveFile}
            onKeyDown={(e) =>
              handleOnEnterKeyPress(e, handleRemoveFile, true, true)
            }
          >
            <LazyImage
              className={style.deleteFileIcon}
              src={window.rxrEnv?.ASSETS_PATH + "/icons/delete-file.svg"}
              alt={"current_prescription.delete-file"}
              width="20px"
              height="20px"
            />

            {translate("current_prescription.delete_file")}
          </Button>
        )}
      </label>

      {formatKeyLabel && !value && !errorKeyLabel && (
        <span className={style.formatLabel}>
          {translate(formatKeyLabel)?.replace("{{size}}", sizeMB.toString())}
        </span>
      )}

      {errorKeyLabel && (
        <TextError
          className={style.error}
          htmlLabel={translate(errorKeyLabel)?.replace(
            "{{size}}",
            sizeMB.toString()
          )}
          useMarginTop={true}
        />
      )}
    </div>
  );
};

export default UploadButton;
