import React from "react";
import { QuestionType } from "@model/SurveyModel";
import { Controller } from "react-hook-form";
import style from "./style.module.scss";
import RadioButton from "@components/common/RadioButton/component";

type SelectListProps = {
  question: QuestionType;
  formData: any;
  optional?: boolean;
  initialValue?: string;
  border?: boolean;
};
function SelectList({
  question,
  formData,
  optional = false,
  border = false,
}: SelectListProps) {
  const value = formData?.watch(question?.idFE);

  return (
    <div className={style.SelectList}>
      <Controller
        control={formData.control}
        name={question.idFE}
        rules={{
          required: !optional,
        }}
        render={({ field: { onChange: controllerOnChange } }) => (
          <>
            {question?.answers?.map((answer) => (
              <RadioButton
                className={style.SelectListRadioButton}
                key={answer.idFE}
                id={answer.idFE}
                name={question.idFE}
                checked={value === answer.idFE}
                onChange={controllerOnChange}
                useContainer={true}
                ariaLabel={answer.title}
                label={answer.title}
                border={border}
              />
            ))}
            <div className={style.hiddenLabel}>test to download bold font</div>
          </>
        )}
      />
    </div>
  );
}

export default SelectList;
