import FAQsModal from "@components/common/FAQsModal/component";
import ModalCheck from "@components/common/ModalCheck/component";
import ModalLegal from "@components/common/ModalLegal/component";
import { useFocusTrap } from "@hooks/useFocusTrap";
import { useTranslation } from "@hooks/useTranslation";
import { selectConfig } from "@slices/configSlice";
import { utilityAsyncActions } from "@slices/utilitySlice";
import { SELECTOR_RXR, exitRxr } from "@utility/utility";
import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/component";
import LazyImage from "../LazyImage/LazyImage";
import style from "./style.module.scss";
import { useNavigate } from "react-router-dom";

export type HeaderProps = {
  onBackLabel?: string;
  animate?: boolean;
  onBackClick?: () => void;
  hidePhoneBackButton?: boolean;
  actionPhoneBackButton?: () => void;
};

export default memo(function Header({ animate = false }: HeaderProps) {
  const translate = useTranslation();
  const navigate = useNavigate();
  const config = useSelector(selectConfig);
  const dispatch = useDispatch();

  const [showMoreInfoPanel, setShowMoreInfoPanel] = useState(false);
  const [modalLegalInfo, setModalLegalInfo] = useState<{
    title: string;
    description: string;
  }>(null);
  const [showExitModal, setShowExitModal] = useState(false);
  const [animateHeader, setAnimateHeader] = useState(false);
  const [isOpenFAQsModal, setOpenFAQsModal] = useState(false);

  const openShowMoreInfoPanel = () => {
    setShowMoreInfoPanel(true);
  };
  const closeShowMoreInfoPanel = () => {
    setShowMoreInfoPanel(false);
    focusMoreInfoIcon();
  };

  useFocusTrap({
    isOpen: showMoreInfoPanel,
    onClose: closeShowMoreInfoPanel,
    selector: ".parent-modal-container",
  });

  const focusMoreInfoIcon = () => {
    document.querySelector("." + style.moreInfoIcon)?.focus();
  };
  const focusCloseMoreInfoPanel = () => {
    document
      .querySelector(".parent-modal-container ." + style.moreInfoCloseIcon)
      ?.focus();
  };

  useLayoutEffect(() => {
    if (showMoreInfoPanel) {
      focusCloseMoreInfoPanel();
    }
  }, [showMoreInfoPanel]);

  const confirmExitOnlineVisionTest = () => {
    dispatch(
      utilityAsyncActions.invokeEcommerceFunction({
        functionId: "updateAssessmentStatus",
        finallyCallback: () => {
          exitRxr(config.config?.headerBackLinkUrl);
        },
      })
    );
  };

  const handleBackgroundHeaderClick = (event) => {
    if (event?.target?.classList?.contains("parent-modal-container"))
      closeShowMoreInfoPanel();
  };

  const moreInfoButtons = [
    {
      id: "about",
      labelKey: "start_page1.about",
      labelDescriptionKey: "start_page1.about_panel_content",
    },
    {
      id: "accessibility",
      labelKey: "start_page1.accessibility",
      labelDescriptionKey: "start_page1.accessibility_panel_content",
    },
    {
      id: "privacy_policy",
      labelKey: "start_page1.privacy_policy",
      labelDescriptionKey: "start_page1.privacy_policy_panel_content",
    },
    {
      id: "privacy_notice",
      labelKey: "start_page1.privacy_notice",
      labelDescriptionKey: "start_page1.privacy_notice_panel_content",
    },
    {
      id: "privacy_practices",
      labelKey: "start_page1.privacy_practices",
      labelDescriptionKey: "start_page1.privacy_practices_panel_content",
    },
    {
      id: "terms_of_use",
      labelKey: "start_page1.terms_of_use",
      labelDescriptionKey: "start_page1.terms_of_use_panel_content",
    },
  ];

  useEffect(() => {
    const timeoutHeader =
      animate &&
      setTimeout(() => {
        setAnimateHeader(true);
      }, 1000);

    return () => {
      timeoutHeader && clearTimeout(timeoutHeader);
    };
  }, []);

  const handleLeaveModalNoAction = () => {
    setShowExitModal(false);
    closeShowMoreInfoPanel();
  };

  const [keyLabelMode, setKeyLabelMode] = useState(false);

  useEffect(() => {
    setKeyLabelMode(sessionStorage.getItem("keyLabelMode") === "true");
  }, []);

  const handleKeyLabelModeVisualization = (turn: "on" | "off") => {
    if (turn === "on") {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("keyLabelMode", "true");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      navigate(newUrl);
    }
    if (turn === "off") {
      sessionStorage.removeItem("keyLabelMode");
    }

    window.location.reload();
  };

  return (
    <div
      className={`${style.HeaderContainer} ${
        animate && animateHeader ? style.show : animate && style.hide
      }`}
    >
      <div className={style.Header}>
        <div className={style.leftElement}>
          {/* user icon deleted by https://luxotticaretail.atlassian.net/browse/RXRENEWAL-101 */}
        </div>

        {config?.config?.brandLogo && (
          <button
            className={style.BrandImageButton}
            onClick={() => setShowExitModal(true)}
          >
            <LazyImage
              className={style.BrandImage}
              src={config?.config?.brandLogo}
              width={"auto"}
              height={24}
              alt={config?.config?.brandName}
            />
          </button>
        )}

        <div className={style.rightElement}>
          <Button
            className={style.moreInfoIcon}
            variant="underline"
            icon="custom"
            iconInfo={{
              position: "left",
              path:
                window.rxrEnv?.ASSETS_PATH + "/icons/header/more-vertical.svg",
              width: "20px",
              height: "20px",
              alt: translate("wcag.wcag_header_more_info"),
            }}
            onClick={openShowMoreInfoPanel}
            analyticsInfo={{
              "data-element-id": "RxRenewal_Landing-Menu",
              "data-description": "Menu",
              "data-analytics_available_call": 1,
            }}
            ariaLabel={translate("wcag.wcag_header_more_info")}
          />
        </div>
      </div>

      {showMoreInfoPanel &&
        createPortal(
          <div
            className={`${style.moreInfoModal} parent-modal-container`}
            onClick={(e) => handleBackgroundHeaderClick(e)}
          >
            <Button
              className={style.moreInfoCloseIcon}
              variant="light"
              icon="close"
              onClick={closeShowMoreInfoPanel}
              analyticsInfo={{
                "data-analytics_available_call": 0,
              }}
              ariaLabel={
                translate("wcag.wcag_close_icon") +
                " " +
                translate("wcag.wcag_header_more_info")
              }
            />

            <div className={style.moreInfoModalPanel}>
              {moreInfoButtons.map((moreInfoButton) => (
                <Button
                  key={moreInfoButton.id}
                  className={style.moreInfoButton}
                  variant="underline"
                  onClick={() =>
                    setModalLegalInfo({
                      title: moreInfoButton.labelKey,
                      description: moreInfoButton.labelDescriptionKey,
                    })
                  }
                  analyticsInfo={{
                    "data-analytics_available_call": 0,
                  }}
                >
                  {translate(moreInfoButton.labelKey)}
                </Button>
              ))}

              {/*Enter Key Label Visualization button*/}

              {(window.location.host.includes(
                "onlinevisiontest.luxottica.com"
              ) ||
                window.location.host.includes(
                  "fe-rxrenewal.luxdeepblue.com"
                )) && (
                <Button
                  key={"keyLabelVisualization"}
                  className={style.moreInfoButton}
                  variant="underline"
                  onClick={() =>
                    handleKeyLabelModeVisualization(keyLabelMode ? "off" : "on")
                  }
                >
                  {keyLabelMode
                    ? "Quit key label visualization"
                    : "Enter in key label visualization"}
                </Button>
              )}
              {/*FAQs button*/}
              <Button
                key={"FAQs"}
                className={style.moreInfoButton}
                variant="underline"
                onClick={() => {
                  setOpenFAQsModal(true);
                }}
                analyticsInfo={{
                  "data-analytics_available_call": 0,
                }}
              >
                {translate("start_page1.FAQs")}
              </Button>

              <div className={style.moreInfoModalDivider} />

              <Button
                className={style.moreInfoButton}
                variant="underline"
                onClick={() => setShowExitModal(true)}
                analyticsInfo={{
                  "data-analytics_available_call": 0,
                }}
              >
                {translate("header.header_go_back")?.replace(
                  "{{brand}}",
                  config?.config?.brandName || ""
                )}

                <LazyImage
                  className={style.moreInfoButtonIcon}
                  src={window.rxrEnv?.ASSETS_PATH + "/icons/header/exit.svg"}
                  width={"20px"}
                  height={"20px"}
                  alt={""}
                />
              </Button>
            </div>
          </div>,
          document.querySelector(SELECTOR_RXR)
        )}

      {modalLegalInfo && (
        <ModalLegal
          htmlTitle={translate(modalLegalInfo.title)}
          htmlDescription={translate(modalLegalInfo.description)}
          onClose={() => {
            setModalLegalInfo(null);
            focusCloseMoreInfoPanel();
          }}
        />
      )}

      {isOpenFAQsModal && (
        <FAQsModal
          onClose={() => {
            setOpenFAQsModal(false);
            focusCloseMoreInfoPanel();
          }}
        />
      )}

      {showExitModal && (
        <ModalCheck
          isOpen={showExitModal}
          message={translate("header.leave_modal_title")}
          description={translate("header.leave_modal_description")}
          icon={"exclamation"}
          ctaPrimaryAction={confirmExitOnlineVisionTest}
          ctaPrimaryLabel={translate("header.leave_modal_cta_yes")}
          ctaAction={handleLeaveModalNoAction}
          escAction={handleLeaveModalNoAction}
          ctaLabel={translate("header.leave_modal_cta_no")}
          ctaNoFooter={true}
        />
      )}
    </div>
  );
});
