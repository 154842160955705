import React, { memo, useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";
import { createPortal } from "react-dom";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import LazyImage from "@components/common/LazyImage/LazyImage";
import IconCircleModal from "@components/common/IconCircleModal/component";
import { SELECTOR_RXR } from "@utility/utility";

type ModalDetectDeviceProps = {
  className?: string;
};

const minPixels = 350;

const ModalDetectDevice = memo(({ className = "" }: ModalDetectDeviceProps) => {
  const translate = useTranslation();
  const breakpoint = useBreakpointBoolean();

  const [errorLandscape, setErrorLandscape] = useState(false);
  const [errorOverSmartphone, setErrorOverSmartphone] = useState(false);
  const [errorSmallSmartphone, setErrorSmallSmartphone] = useState(false);

  useEffect(() => {
    if (breakpoint && !breakpoint.isPortrait && !errorLandscape) {
      setErrorLandscape(true);
    } else if (breakpoint?.isPortrait && errorLandscape) {
      setErrorLandscape(false);
    }
  }, [breakpoint?.isPortrait]);

  useEffect(() => {
    if (breakpoint && !breakpoint.isPhone && !errorOverSmartphone) {
      setErrorOverSmartphone(true);
    } else if (breakpoint?.isPortrait && errorOverSmartphone) {
      setErrorOverSmartphone(false);
    }
  }, [breakpoint?.isPhone]);

  useEffect(() => {
    if (window.innerWidth < minPixels && !errorSmallSmartphone) {
      setErrorSmallSmartphone(true);
    } else if (window.innerWidth >= minPixels && errorSmallSmartphone) {
      setErrorSmallSmartphone(false);
    }
  }, [window.innerWidth]);

  const message = useMemo(() => {
    if (errorOverSmartphone) {
      return "device_modal.over_smartphone_error";
    } else if (errorLandscape) {
      return "device_modal.landscape_error";
    } else if (errorSmallSmartphone) {
      return "device_modal.small_smartphone_error";
    }
    return "";
  }, [errorLandscape, errorOverSmartphone, errorSmallSmartphone]);

  return message
    ? createPortal(
        <div className={`${style.modal} ${className}`}>
          {message === "device_modal.landscape_error" && (
            <IconCircleModal
              className={style.iconCircleRing}
              classNameCircle={style.iconCircleCircle}
            >
              <LazyImage
                className={style.rotateDeviceIcon}
                src={window.rxrEnv?.ASSETS_PATH + "/icons/rotate-device.svg"}
                alt={"Success"}
                width={"64px"}
                height={"64px"}
              />
            </IconCircleModal>
          )}

          <p className={style.title}>{translate("device_modal.title")}</p>
          <p className={style.message}>{translate(message)}</p>
        </div>,
        document.querySelector(SELECTOR_RXR)
      )
    : null;
});

export default ModalDetectDevice;
