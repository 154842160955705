import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getEndpoint, getHeaders, mockService } from "@utility/networkUtility";
import axios from "axios";
import { RootState } from "../Store";
import {
  CountryType,
  QuestionnarieAnswer,
  SendAnswersPayload,
  SendAnswersResponse,
  SendAnswersResponseError,
  SurveyType,
  TimeBoundTokenServicePayload,
} from "@model/SurveyModel";
import { getLabelFromLabelMap } from "@hooks/useTranslation";
import { utilityActions, utilityAsyncActions } from "./utilitySlice";
import {
  getAgeFromDateString,
  transformTBTSPayloadIntoConnect,
} from "@utility/utility";
import { sessionStorageIds } from "@utility/sessionStorageUtility";

export type StateSurvey = {
  survey: SurveyType | null;
  surveyInfo: {
    isLoading: boolean;
    isLoaded: boolean;
  };
  sendAnswersInfo: {
    isLoading: boolean;
    stepId: number;
    error: string;
    answer: string;
  };
  TBTInfo: {
    isLoading: boolean;
    TBT: string;
    connectToken: string;
    payload: TimeBoundTokenServicePayload | null;
    status:
      | "SURVEY_COMPLETED"
      | "ACUITY_START"
      | "ACUITY_END"
      | "ACUITY_END_DESKTOP";
  };
  userAnswersForm;
  countryListInfo: {
    isLoading: boolean;
    isLoaded: boolean;
    countryList: CountryType[];
  };
};

const initialState: StateSurvey = {
  survey: null,
  surveyInfo: {
    isLoading: false,
    isLoaded: false,
  },
  sendAnswersInfo: {
    isLoading: false,
    stepId: -1,
    error: "",
    answer: "",
  },
  userAnswersForm: null,
  countryListInfo: {
    isLoading: false,
    isLoaded: false,
    countryList: [],
  },
  TBTInfo: {
    isLoading: false,
    TBT: null,
    connectToken: null,
    payload: null,
    status: null,
  },
};

const surveySlice = createSlice({
  name: "survey",
  initialState: initialState,
  reducers: {
    requestGetSurvey: (state) => {
      state.surveyInfo = {
        ...state.surveyInfo,
        isLoading: true,
      };
    },
    successGetSurvey: (
      state,
      { payload }: PayloadAction<{ survey: SurveyType }>
    ) => {
      state.survey = payload.survey;
      state.surveyInfo = {
        isLoading: false,
        isLoaded: true,
      };
    },
    failGetSurvey: (state) => {
      state.surveyInfo = {
        ...state.surveyInfo,
        isLoading: false,
        isLoaded: true,
      };
    },
    requestSendAnswers: (
      state,
      { payload }: PayloadAction<{ stepId: number }>
    ) => {
      state.sendAnswersInfo = {
        isLoading: true,
        stepId: payload.stepId,
        error: "",
        answer: "",
      };
    },
    successSendAnswers: (state) => {
      state.sendAnswersInfo = {
        ...state.sendAnswersInfo,
        isLoading: false,
      };
    },
    cantProceedSendAnswers: (
      state,
      { payload }: PayloadAction<{ error: string; answer: string }>
    ) => {
      state.sendAnswersInfo = {
        ...state.sendAnswersInfo,
        isLoading: false,
        error: payload.error,
        answer: payload.answer,
      };
    },
    failSendAnswers: (state) => {
      state.sendAnswersInfo = {
        ...state.sendAnswersInfo,
        isLoading: false,
      };
    },
    requestGetTBTPayload: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: true,
      };
    },
    successGetTBTPayload: (
      state,
      { payload }: PayloadAction<{ TBTPayload: TimeBoundTokenServicePayload }>
    ) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        payload: { ...payload.TBTPayload },
        status: payload.TBTPayload.status,
        isLoading: false,
      };
    },
    failGetTBTPayload: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: false,
      };
    },
    requestSaveTBTSData: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: true,
      };
    },
    successSaveTBTSData: (
      state,
      {
        payload,
      }: PayloadAction<{
        TBT: string;
        TBTPayload: TimeBoundTokenServicePayload;
      }>
    ) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: false,
        TBT: payload.TBT,
        payload: payload.TBTPayload,
      };
    },
    failSaveTBTSData: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: false,
      };
    },
    requestSaveConnectData: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: true,
      };
    },
    successSaveConnectData: (
      state,
      { payload }: PayloadAction<{ connectToken: string }>
    ) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: false,
        connectToken: payload.connectToken,
      };
    },
    failSaveConnectData: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: false,
      };
    },
    setTBTandConnectToken: (
      state,
      { payload }: PayloadAction<{ TBT: string; connectToken: string }>
    ) => {
      console.log(
        "Setting TBT and Connect token for this session:",
        payload.TBT,
        payload.connectToken
      );
      state.TBTInfo = {
        ...state.TBTInfo,
        TBT: payload.TBT,
        connectToken: payload.connectToken,
      };
    },
    requestUpdateTBTPayload: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: true,
      };
    },
    successUpdateTBTPayload: (
      state,
      { payload }: PayloadAction<{ TBTPayload: TimeBoundTokenServicePayload }>
    ) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        payload: payload.TBTPayload,
        status: payload.TBTPayload.status,
        isLoading: false,
      };
    },
    successNoUpdateTBTPayload: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: false,
      };
    },
    failUpdateTBTPayload: (state) => {
      state.TBTInfo = {
        ...state.TBTInfo,
        isLoading: false,
      };
    },
    setUserAnswersForm: (
      state,
      { payload }: PayloadAction<{ userAnswersForm }>
    ) => {
      state.userAnswersForm = payload.userAnswersForm;
    },
    requestGetCountryList: (state) => {
      state.countryListInfo = {
        ...state.countryListInfo,
        isLoading: true,
      };
    },
    successGetCountryList: (
      state,
      { payload }: PayloadAction<{ countryList: CountryType[] }>
    ) => {
      state.countryListInfo = {
        countryList: payload.countryList,
        isLoading: false,
        isLoaded: true,
      };
    },
    failGetCountryList: (state) => {
      state.countryListInfo = {
        ...state.countryListInfo,
        isLoading: false,
        isLoaded: true,
      };
    },
    reset: () => initialState,
  },
});

////ASYNC ACTIONS////
export const getSurvey = createAsyncThunk(
  "survey/getSurvey",
  async (_, { dispatch, getState }) => {
    const mock = false;
    const url = getEndpoint("getSurvey");

    dispatch(surveyActions.requestGetSurvey());

    (mock
      ? mockService("/mock/survey/survey.json", 1000)
      : axios({
          url,
          method: "GET",
          headers: getHeaders(),
          withCredentials: true,
        })
    )
      .then((response) => {
        let surveyTemp: SurveyType | null = null;
        if (response.data) {
          surveyTemp = response.data;

          const soldProducts = (getState() as RootState)?.config?.config?.config
            ?.soldProducts;
          if (soldProducts) {
            surveyTemp.steps?.forEach((step) => {
              step.questions = step.questions?.filter((question) => {
                if (question.id === 6 && soldProducts === "cl") {
                  //filter out the eyeglasses question if the e-commerce sold only CL
                  return false;
                }
                if (question.id === 7 && soldProducts === "gl") {
                  //filter out the contact lenses question if the e-commerce sold only GL
                  return false;
                }
                return true;
              });
            });
          }
        }

        dispatch(
          surveyActions.successGetSurvey({
            survey: surveyTemp,
          })
        );
      })
      .catch((error) => {
        const errorLabel = "Error getting survey";
        console.error(errorLabel, { error });
        dispatch(
          utilityActions.setShowErrorPopup({
            showErrorPopup: true,
            analyticsInfo: {
              Error_Details:
                errorLabel + "\n --- \n" + JSON.stringify(error || {}),
            },
          })
        );
        dispatch(surveyActions.failGetSurvey());
      });
  }
);

type sendAnswersParams = {
  questionnarie: QuestionnarieAnswer[];
  callbackSuccess: () => void;
  callbackCantProceed: () => void;
  stepId: number;
  isLastStep?: boolean;
};
export const sendAnswers = createAsyncThunk(
  "survey/sendAnswers",
  async (
    {
      questionnarie,
      callbackSuccess,
      callbackCantProceed,
      stepId,
      isLastStep = false,
    }: sendAnswersParams,
    { getState, dispatch }
  ) => {
    const mock = false;
    const mockError = false;

    dispatch(surveyActions.requestSendAnswers({ stepId }));

    const state: RootState = getState() as RootState;
    const labelMap = state.utility.labelMap;
    const survey = state.survey;
    const userFirstName = state.config.config.config.name;
    const userLastName = state.config.config.config.surname;
    const userMail = state.config.config.config.mail;
    const userAge = questionnarie?.find((question) => question.step_id === 2)
      ?.q_answer[0]
      ? getAgeFromDateString(
          questionnarie?.find((question) => question.step_id === 2)?.q_answer[0]
        )
      : undefined;
    const userLanguage = state.utility.labelInfo?.language;

    let payload: SendAnswersPayload = {
      survey_id: survey.survey.id,
      completed: !!isLastStep,
      ok_callback_url: "",
      fail_callback_url: "",
      flow_mode: "paid",
      // subscriptionKey
      first_name: userFirstName,
      last_name: userLastName,
      email: userMail,
      age: userAge,
      language: userLanguage,
      product_type:
        questionnarie.find(
          (question) => question.step_id === 4 && question.answer_id.length != 0
        )?.q_label === "Eyeglasses"
          ? "LENSES"
          : "CONTACT",
      eligibility_questionnarie: questionnarie,
    };

    const url = getEndpoint("sendAnswers");
    console.log("sendAnswers", { payload });

    const mockUrl = mockError
      ? "/mock/survey/sendAnswersError.json"
      : "/mock/survey/sendAnswers.json";
    const mockStatus = mockError ? 401 : null;

    (mock
      ? mockService(mockUrl, 1000, mockStatus)
      : axios({
          url,
          method: "POST",
          headers: getHeaders(),
          withCredentials: true,
          data: payload,
        })
    )
      .then((response: { data: SendAnswersResponse }) => {
        //response status = 200 OK --> answers are ok
        //response status = 201 CREATED --> returns token

        dispatch(surveyActions.successSendAnswers());

        //run callback
        if (callbackSuccess) {
          callbackSuccess();
        }
      })
      .catch(
        (error: {
          response: {
            status: number;
            data: SendAnswersResponse | SendAnswersResponseError;
          };
        }) => {
          //response status = 417 --> user can't proceed because of his answers
          if (error.response?.status === 417) {
            const data = error.response?.data as SendAnswersResponse;
            //retrieve wrong answer(s)
            const stepId = data?.step;
            const questionId = data?.questionId;
            let answerLabel = "";
            if (stepId && questionId) {
              //get wrong step
              const step = survey?.survey?.steps?.find(
                (step) => step.id === stepId
              );
              //get wrong question
              const question = step.questions?.find(
                (step) => step.id === questionId
              );
              //get user answers
              const userAnswers = questionnarie.filter(
                (answer) => answer.q_id === questionId
              );

              userAnswers.forEach((answer) => {
                if (answer.answer_id?.length > 0) {
                  //search in predefined answers
                  answerLabel = answer.answer_id
                    .map((answerId) => {
                      if (step) {
                        //get the answer key
                        const answer = question.answers?.find(
                          (a) => a.id === +answerId
                        );
                        if (answer) {
                          //get the answer label
                          return getLabelFromLabelMap(labelMap, answer.title);
                        }
                      }

                      return "";
                    })
                    .filter((a) => !!a) //remove empty labels
                    .join(", "); //join multiple answers with a comma
                } else if (answer.q_answer?.length > 0) {
                  //search in free answers
                  //get what the user has written
                  answerLabel = answer.q_answer?.join(", ");
                }
              });
            }

            //store label key for error and wrong answer
            dispatch(
              surveyActions.cantProceedSendAnswers({
                error: data?.errorLaKey,
                answer: answerLabel,
              })
            );

            //run callback
            if (callbackCantProceed) {
              callbackCantProceed();
            }
          } else {
            //GENERIC NETWORK ERROR
            const data = error.response?.data as SendAnswersResponseError;
            const errorLabel = "Error sending answers";
            console.error(errorLabel, { error, data });
            dispatch(
              utilityActions.setShowErrorPopup({
                showErrorPopup: true,
                label: error.response?.status === 400 ? "survey.error400" : "",
                analyticsInfo: {
                  Error_Details:
                    errorLabel +
                    "\n --- \n" +
                    JSON.stringify(error || {}) +
                    "\n --- \n" +
                    JSON.stringify(data || {}),
                },
              })
            );
            dispatch(surveyActions.failSendAnswers());
          }
        }
      );
  }
);

export const saveSurveyForConnectAndTBTS = createAsyncThunk(
  "/saveSurveyForConnectAndTBTS",
  async (questionnarie: QuestionnarieAnswer[], { getState, dispatch }) => {
    //creating payload
    const state: RootState = getState() as RootState;
    const labelMap = state.utility.labelMap;
    const survey = state.survey;
    const userFirstName = state.config.config.config.name;
    const userLastName = state.config.config.config.surname;
    const userMail = state.config.config.config.mail;
    const userAge = getAgeFromDateString(
      questionnarie?.find((question) => question.step_id === 2)?.q_answer[0]
    );
    const userLanguage = state.utility.labelInfo?.language;
    let payload: TimeBoundTokenServicePayload = {
      survey_id: survey.survey.id,
      completed: true,
      ok_callback_url: "",
      fail_callback_url: "",
      flow_mode: "paid",
      first_name: userFirstName,
      last_name: userLastName,
      email: userMail,
      age: userAge,
      language: userLanguage,
      product_type:
        questionnarie.find(
          (question) => question.step_id === 4 && question.answer_id.length != 0
        )?.q_label === "Eyeglasses"
          ? "LENSES"
          : "CONTACT",
      eligibility_questionnarie: questionnarie,
      tests: {
        step1: {
          t_id: "step1",
          t_label: labelMap?.acuity?.connect_label_6_10,
          t_answer: {
            OD: {
              result: "KO",
              score: "0/0",
            },
            OS: {
              result: "KO",
              score: "0/0",
            },
          },
        },
        step2: {
          t_id: "step2",
          t_label: labelMap?.acuity?.connect_label_8_10,
          t_answer: {
            OD: {
              result: "KO",
              score: "0/0",
            },
            OS: {
              result: "KO",
              score: "0/0",
            },
          },
        },
        step3: {
          t_id: "step3",
          t_label: labelMap?.acuity?.connect_label_10_10,
          t_answer: {
            OD: {
              result: "KO",
              score: "0/0",
            },
            OS: {
              result: "KO",
              score: "0/0",
            },
          },
        },
      },
      status: "SURVEY_COMPLETED",
    };

    dispatch(saveTBTSData(payload));
    dispatch(saveConnectData(payload));
    // if connect token is already present (because taken by the ecommerce configuration), we update the payload instead of create a new one
    // ---- connect doesn't permit to update attributes already set so it's useless ----
    // const connectToken = sessionStorage.getItem(sessionStorageIds.CONNECT);
    // if(connectToken) {
    //   dispatch(updateTBTPayload({tokenId: "", payload: payload, updateTbts: false}));
    // }
  }
);

export const saveConnectData = createAsyncThunk(
  "/saveConnectData",
  async (ConnectPayload: TimeBoundTokenServicePayload, { dispatch }) => {
    dispatch(surveyActions.requestSaveConnectData());
    const payload = transformTBTSPayloadIntoConnect(ConnectPayload);
    console.log("trying to save data to connect");

    const url = getEndpoint("saveConnectData");
    axios({
      url,
      method: "POST",
      headers: getHeaders(),
      withCredentials: true,
      data: payload,
    })
      .then((response: { data: { token: string } }) => {
        console.log(
          "Saved data to Connect. Token generated:",
          response.data.token
        );
        dispatch(
          surveyActions.successSaveConnectData({
            connectToken: response.data?.token,
          })
        );

        dispatch(
          utilityAsyncActions.invokeEcommerceFunction({
            functionId: "writeToken",
            connectToken: response.data.token,
          })
        );
      })
      .catch(
        (error: {
          response: {
            status: number;
            data: SendAnswersResponse | SendAnswersResponseError;
          };
        }) => {
          const data = error.response?.data as SendAnswersResponseError;
          const errorLabel = "Error saving Connect Data";
          console.error(errorLabel, { error, data });
          dispatch(
            utilityActions.setShowErrorPopup({
              showErrorPopup: true,
              analyticsInfo: {
                Error_Details:
                  errorLabel +
                  "\n --- \n" +
                  JSON.stringify(error || {}) +
                  "\n --- \n" +
                  JSON.stringify(data || {}),
              },
            })
          );
          dispatch(surveyActions.failSaveTBTSData());
        }
      );
  }
);

export const saveTBTSData = createAsyncThunk(
  "/saveTBTSData",
  async (TBTSpayload: TimeBoundTokenServicePayload, { dispatch }) => {
    dispatch(surveyActions.requestSaveTBTSData());

    const url = getEndpoint("generateTBT");
    axios({
      url,
      method: "POST",
      headers: getHeaders(),
      withCredentials: true,
    })
      .then((response: { data: string }) => {
        console.log("Saved data to TBTS. Token generated:", response.data);
        const TBT = response.data;

        //placing token in the endpoint
        const url = getEndpoint("updateTBTPayload").replace("{token}", TBT);

        axios({
          url,
          method: "PUT",
          headers: getHeaders(),
          withCredentials: true,
          data: TBTSpayload,
        })
          .then((response: { data: string }) => {
            console.log("updated TBT payload");
            dispatch(
              surveyActions.successSaveTBTSData({
                TBT: TBT,
                TBTPayload: TBTSpayload,
              })
            );
          })
          .catch(
            (error: {
              response: {
                status: number;
                data: SendAnswersResponse | SendAnswersResponseError;
              };
            }) => {
              const data = error.response?.data as SendAnswersResponseError;
              const errorLabel = "Error updating TBT payload";
              console.error(errorLabel, { error, data });
              dispatch(surveyActions.failSaveTBTSData());

              //token is expired
              if (error.response?.status === 404) {
                dispatch(
                  utilityActions.setShowTimeoutPopup({ showTimeoutPopup: true })
                );
                return;
              }

              dispatch(
                utilityActions.setShowErrorPopup({
                  showErrorPopup: true,
                  analyticsInfo: {
                    Error_Details:
                      errorLabel +
                      "\n --- \n" +
                      JSON.stringify(error || {}) +
                      "\n --- \n" +
                      JSON.stringify(data || {}),
                  },
                })
              );
            }
          );
      })
      .catch(
        (error: {
          response: {
            status: number;
            data: SendAnswersResponse | SendAnswersResponseError;
          };
        }) => {
          const data = error.response?.data as SendAnswersResponseError;
          const errorLabel = "Error updating TBT";
          console.error(errorLabel, { error, data });
          dispatch(surveyActions.failSaveTBTSData());

          //token is expired
          if (error.response?.status === 404) {
            dispatch(
              utilityActions.setShowTimeoutPopup({ showTimeoutPopup: true })
            );
            return;
          }

          dispatch(
            utilityActions.setShowErrorPopup({
              showErrorPopup: true,
              label: error.response?.status === 400 ? "survey.error400" : "",
              analyticsInfo: {
                Error_Details:
                  errorLabel +
                  "\n --- \n" +
                  JSON.stringify(error || {}) +
                  "\n --- \n" +
                  JSON.stringify(data || {}),
              },
            })
          );
        }
      );
  }
);

export const getTBTPayload = createAsyncThunk(
  "/getTBTPayload",
  async (tokenId: string, { dispatch }) => {
    const url = getEndpoint("getTBTPayload").replace("{token}", tokenId);

    dispatch(surveyActions.requestGetTBTPayload());
    axios({
      url,
      method: "GET",
      headers: getHeaders(),
      withCredentials: true,
    })
      .then((response) => {
        let TBTPayloadTemp: TimeBoundTokenServicePayload | null = null;
        if (response.data) {
          console.log("TBT payload:", response.data);
          TBTPayloadTemp = response.data;
        }

        dispatch(
          surveyActions.successGetTBTPayload({ TBTPayload: TBTPayloadTemp })
        );
      })
      .catch((error) => {
        const errorLabel = "Error updating TBTpayload";
        console.error(errorLabel, { error });
        dispatch(surveyActions.failGetTBTPayload());

        //token is expired
        if (error.response?.status === 404) {
          dispatch(
            utilityActions.setShowTimeoutPopup({ showTimeoutPopup: true })
          );
          return;
        }

        dispatch(
          utilityActions.setShowErrorPopup({
            showErrorPopup: true,
            analyticsInfo: {
              Error_Details:
                errorLabel + "\n --- \n" + JSON.stringify(error || {}),
            },
          })
        );
      });
  }
);

export const updateTBTPayload = createAsyncThunk(
  "/updateTBTPayload",
  async (
    {
      tokenId,
      payload,
      onSuccessCallback,
      updateTbts = true,
    }: {
      tokenId: string;
      payload: TimeBoundTokenServicePayload;
      onSuccessCallback?: () => void;
      updateTbts?: boolean;
    },
    { dispatch }
  ) => {
    dispatch(surveyActions.requestUpdateTBTPayload());

    //placing token in the endpoint
    const url = getEndpoint("updateTBTPayload").replace(
      "{token}",
      tokenId || sessionStorage.getItem(sessionStorageIds.TBT)
    );

    if (updateTbts) {
      //only if we are updating the TBTS
      dispatch(
        utilityAsyncActions.invokeEcommerceFunction({
          functionId: "writeToken",
        })
      );
    }

    const tbtsUpdate = updateTbts
      ? axios({
          url,
          method: "PUT",
          headers: getHeaders(),
          withCredentials: true,
          data: payload,
        })
      : setTimeout(() => {}, 0);

    //placing token in the endpoint
    const urlConnect = getEndpoint("updateConnectData").replace(
      "{token}",
      sessionStorage.getItem(sessionStorageIds.CONNECT) //"af1dc8da-8c4b-4690-9072-2d36beab5535"
    );
    const payloadConnect = transformTBTSPayloadIntoConnect(payload);

    const connectUpdate = axios({
      url: urlConnect,
      method: "POST",
      headers: getHeaders(),
      withCredentials: true,
      data: payloadConnect,
    });

    Promise.all([connectUpdate, tbtsUpdate])
      .then((results) => {
        console.log("updateTBTPayload results", { results });
        if (updateTbts) {
          const tbtsResult = results[1]?.data;
          dispatch(
            surveyActions.successUpdateTBTPayload({ TBTPayload: payload })
          );
        } else {
          dispatch(surveyActions.successNoUpdateTBTPayload());
        }

        if (onSuccessCallback) {
          onSuccessCallback();
        }
      })
      .catch((error) => {
        console.error("Error updating TBT or Connect payload", { error });

        const data = error.response?.data as SendAnswersResponseError;
        const errorLabel = "Error updating TBT payload";
        console.error(errorLabel, { error, data });
        dispatch(surveyActions.failUpdateTBTPayload());

        //token is expired
        if (error.response?.status === 404) {
          dispatch(
            utilityActions.setShowTimeoutPopup({ showTimeoutPopup: true })
          );
          return;
        }

        dispatch(
          utilityActions.setShowErrorPopup({
            showErrorPopup: true,
            label: error.response?.status === 400 ? "survey.error400" : "",
            analyticsInfo: {
              Error_Details:
                errorLabel +
                "\n --- \n" +
                JSON.stringify(error || {}) +
                "\n --- \n" +
                JSON.stringify(data || {}),
            },
          })
        );
      });
  }
);

export const getCountryList = createAsyncThunk(
  "survey/getCountryList",
  async (_, { dispatch }) => {
    const mock = false;
    const mockUrl = "/mock/survey/countryList.json";

    dispatch(surveyActions.requestGetCountryList());

    const url = getEndpoint("getCountryList");

    (mock
      ? mockService(mockUrl, 1000)
      : axios({
          url,
          method: "GET",
          headers: getHeaders("application/json", false),
          withCredentials: true,
        })
    )
      .then((response: { data: CountryType[] }) => {
        dispatch(
          surveyActions.successGetCountryList({
            countryList: response?.data || [],
          })
        );
      })
      .catch((error) => {
        const errorLabel = "Error getting country list";
        console.error(errorLabel, { error });
        dispatch(
          utilityActions.setShowErrorPopup({
            showErrorPopup: true,
            analyticsInfo: {
              Error_Details:
                errorLabel + "\n --- \n" + JSON.stringify(error || {}),
            },
          })
        );
        dispatch(surveyActions.failGetCountryList());
      });
  }
);

////SELECTORS////
//get the survey with translated labels and answers already sorted
export const selectSurvey = createSelector(
  (state: RootState) => state.utility.labelMap,
  (state: RootState) => state.survey.survey,
  (state: RootState) => state.survey.countryListInfo,
  (labelMap, survey, countryListInfo): SurveyType | null => {
    let surveyTemp: SurveyType | null = null;

    if (Object.values(labelMap).length > 0 && survey) {
      surveyTemp = JSON.parse(JSON.stringify(survey));

      //translate all labels and sort answers
      surveyTemp?.steps?.forEach((step) => {
        step.idFE = "step_" + step.id;

        //REMOVE IN FUTURE
        if (step.stepLabel?.iconPath) {
          step.stepLabel.iconPath = step.stepLabel.iconPath.replace(
            "http://localhost:3000/assets/images",
            window.rxrEnv?.ASSETS_PATH + ""
          );
        }
        step.questions.forEach((question) => {
          if (question.recapGroup) {
            question.recapGroup.label = getLabelFromLabelMap(
              labelMap,
              question.recapGroup.label
            );
          }
          //REMOVE IN FUTURE
          if (question.recapGroup?.iconPath) {
            question.recapGroup.iconPath = question.recapGroup.iconPath.replace(
              "http://localhost:3000/assets/images",
              window.rxrEnv?.ASSETS_PATH + ""
            );
          }
        });

        if (step.title) {
          step.title = getLabelFromLabelMap(labelMap, step.title);
        }
        if (step.description) {
          step.description = getLabelFromLabelMap(labelMap, step.description);
        }
        if (step.ruleLabel) {
          step.ruleLabel = getLabelFromLabelMap(labelMap, step.ruleLabel);
        }
        if (step.recapLabel) {
          step.recapLabel = getLabelFromLabelMap(labelMap, step.recapLabel);
        }
        if (step.stepLabel) {
          step.stepLabel.label = getLabelFromLabelMap(
            labelMap,
            step.stepLabel.label
          );
        }

        step.questions?.forEach((question) => {
          question.idFE = "question_" + question.id;

          //REMOVE IN FUTURE
          if (question.placeholder?.iconPath) {
            question.placeholder.iconPath =
              question.placeholder.iconPath.replace(
                "http://localhost:3000/assets/images",
                window.rxrEnv?.ASSETS_PATH + ""
              );
          }

          if (question.placeholder) {
            question.placeholder.label = getLabelFromLabelMap(
              labelMap,
              question.placeholder.label
            );
          }

          question.answers?.forEach((answer) => {
            answer.idFE = "answer_" + answer.id;

            if (answer.title) {
              answer.title = getLabelFromLabelMap(labelMap, answer.title);
            }
            if (answer.description) {
              answer.description = getLabelFromLabelMap(
                labelMap,
                answer.description
              );
            }
            if (answer.titleRecap) {
              answer.titleRecap = getLabelFromLabelMap(
                labelMap,
                answer.titleRecap
              );
            }
          });

          //sort all answers alphabetically and then by order (order is more important than alphabet)
          question.answers?.sort((a, b) => {
            return a.title?.toLowerCase().localeCompare(b.title?.toLowerCase());
          });
          question.answers?.sort((a, b) => {
            return a.order - b.order;
          });
        });
      });
    }

    return surveyTemp;
  }
);
export const selectSurveyInfo = (state: RootState) => {
  return state.survey.surveyInfo;
};
export const selectSendAnswersInfo = (state: RootState) => {
  return state.survey.sendAnswersInfo;
};
export const selectUserAnswersForm = (state: RootState) => {
  return state.survey.userAnswersForm;
};
export const selectCountryListInfo = (state: RootState) => {
  return state.survey.countryListInfo;
};
export const selectCountryList = (state: RootState) => {
  return state.survey.countryListInfo?.countryList;
};
export const selectTBTInfo = (state: RootState) => {
  return state.survey.TBTInfo;
};

export const surveyReducer = surveySlice.reducer;
export const surveyActions = surveySlice.actions;
export const surveyAsyncActions = {
  getSurvey,
  sendAnswers,
  getCountryList,
  getTBTPayload,
  updateTBTPayload,
};
