import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTING } from "@utility/routingUtility";
import PageStartTutorial from "@components/common/PageStartTutorial/component";
import { useGetSetupVideos } from "@hooks/useGetSetupVideos";
import ModalDetectDevice from "../../components/common/ModalDetectDevice/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";

type Props = {};

function SetupStartPage(props: Props) {
  const translate = useTranslation();
  const navigate = useNavigate();
  const breakpoint = useBreakpointBoolean();
  useGetSetupVideos("tutorial", !breakpoint.isPhone); //just to make the HTTP request that will be used in next pages

  const goToFirstSetupStep = () => {
    navigate(ROUTING.SETUP_STEPS.url.replace(":tutorialStep", "1"));
  };

  return (
    <>
      <PageStartTutorial
        title={translate("setup.start_title")}
        descriptions={[
          translate("setup.start_descr1"),
          translate("setup.start_descr2"),
          translate("setup.start_descr3"),
          translate("setup.start_descr4"),
        ]}
        buttonAction={goToFirstSetupStep}
        showBackButton={true}
        analyticsInfoButton={{
          "data-element-id": "RxRenewal_SetUp-Next",
          "data-description": formatAnalyticsInnerText(
            translate("footer.next")
          ),
          "data-analytics_available_call": 0,
        }}
        analyticsInfoLeftButton={{
          "data-element-id": "RxRenewal_SetUp-Back",
          "data-analytics_available_call": 0,
        }}
      />
      <ModalDetectDevice />
    </>
  );
}

export default SetupStartPage;
