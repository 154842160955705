const BASE_BFF_URL = () =>
  window.rxrEnv?.BASE_BFF_URL || "https://dev-bff-rxrenewal.luxdeepblue.com";

export const getEndpoint = (endpoint: string) => {
  const ENDPOINT = {
    sendAnswers: BASE_BFF_URL() + "/survey/check",
    getSurvey: BASE_BFF_URL() + "/survey/1",
    getCountryList: BASE_BFF_URL() + "/country/all",
    getLabels:
      window.rxrEnv?.LABEL_URL ||
      "https://d5nhc6q3o19l2.cloudfront.net/rx-renewal/v1/dev/application/application_{locale}.json",
    getSetupVideos:
      BASE_BFF_URL() +
      "/media/tutorial?language={language}&area={area}&country={country}&docTypes=CMChannel&tagNames=tutorial",
    // "https://uat-multiplatformh2-cms.luxottica.com/caas/v1/contents/rxrenewal-en/?docTypes=CMChannel&tagNames=tutorial&language=it-it&area=na&country=US",
    //TBTS
    generateTBT: BASE_BFF_URL() + "/tbtoken/generate",
    updateTBTPayload: BASE_BFF_URL() + "/tbtoken/{token}",
    getTBTPayload: BASE_BFF_URL() + "/tbtoken/readPayload/{token}",
    getStatus: "/tbtoken/{token}/status",
    //CONNECT
    saveConnectData: BASE_BFF_URL() + "/connect/assessment/token",
    updateConnectData: BASE_BFF_URL() + "/connect/exam/save/{token}",
    sendCurrentPrescriptionPdf:
      BASE_BFF_URL() + "/connect/assessment/prescription/pdf/{token}",
    sendCurrentPrescriptionJpg:
      BASE_BFF_URL() + "/connect/assessment/prescription/jpg/{token}",
    sendCurrentPrescriptionPng:
      BASE_BFF_URL() + "/connect/assessment/prescription/png/{token}",
  };

  return ENDPOINT[endpoint];
};

/* HEADERS */
export const getHeaders = (
  contentType: string = "application/json",
  addBFFAuth: boolean = true
) => {
  let headers: { [key: string]: string } = {
    "strict-transport-security": "max-age=31536000",
    "Content-Security-Policy": "policy",
    "X-Frame-Options": "DENY",
    "X-Content-Type-Options": "nosniff",
    "Referrer-Policy": "no-referrer",
    "Feature-Policy": "microphone 'self' camera 'self' geolocation 'self'",
  };

  if (addBFFAuth) {
    headers.clientId = window.rxrEnv?.CLIENT_ID_COOKIE;
    headers.jwt = window.rxrEnv?.JWT_COOKIE;
  }

  //do not set the content-type for multipart/form-data --> the browser will do automatically setting the boundary data
  //example - Content-Type: multipart/form-data; boundary=----WebKitFormBoundaryRhDra0DXlqRUGBgx
  if (contentType) {
    headers["content-type"] = contentType;
  }

  return headers;
};

export const mockService = async <T>(
  url: string,
  delay: number = 2000,
  error: number = null
): Promise<{ data: T } | { response: { data: T; status: number } }> => {
  const onboardingOverview = await fetch(url);
  const onboardingOverviewJson: T = await onboardingOverview.json();
  let payload = { data: onboardingOverviewJson };
  if (error) {
    payload = { response: { data: onboardingOverviewJson, status: error } };
  }

  return promiseMock(payload, !!error, delay);
};

export const promiseMock = <Data>(
  data: Data,
  shouldReject = false,
  delay = 2000
): Promise<Data> => {
  return new Promise<Data>((resolve, reject) =>
    setTimeout(() => {
      if (shouldReject) {
        return reject(data);
      } else {
        return resolve(data);
      }
    }, delay)
  );
};
