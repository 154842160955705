import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import Button from "@components/common/Button/component";
import LazyImage from "@components/common/LazyImage/LazyImage";
import { useTranslation } from "@hooks/useTranslation";
import HowToReadPrescriptionModalContent from "@components/HowToReadPrescriptionModalContent/component";
import { createPortal } from "react-dom";
import { SELECTOR_RXR } from "@utility/utility";
import { useFocusTrap } from "@hooks/useFocusTrap";
import { useAutoFocus } from "@hooks/useAutoFocus";

type HowToReadPrescriptionButtonProps = {
  className?: string;
  isCL?: boolean;
  modalSelector?: string;
  insideModal?: boolean;
  onChangeShowModal?: (showModal: boolean) => void;
};

const HowToReadPrescriptionButton = ({
  className = "",
  isCL = false,
  modalSelector = "",
  insideModal = false,
  onChangeShowModal,
}: HowToReadPrescriptionButtonProps) => {
  const translate = useTranslation();
  const [showModal, setShowModal] = useState(false);

  useFocusTrap({
    isOpen: showModal,
    onClose: () => setShowModal(false),
    selector: "." + style.howToReadPrescriptionModal,
  });
  useAutoFocus({
    selector:
      showModal &&
      "." + style.howToReadPrescriptionModal + " ." + style.closeIcon,
  });

  useEffect(() => {
    if (!insideModal) {
      //opening the modal, the background will be unscrollable
      if (showModal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.removeAttribute("auto");
      }

      //unmounting the modal, overflow: hidden attribute will be removed
      return () => {
        document.body.removeAttribute("auto");
      };
    }

    if (onChangeShowModal) {
      onChangeShowModal(showModal);
    }
  }, [showModal]);

  return (
    <>
      <Button
        className={`${style.HowToReadPrescriptionModalLink} ${className}`}
        variant="underline"
        onClick={() => setShowModal(true)}
      >
        <LazyImage
          src={window.rxrEnv?.ASSETS_PATH + "/icons/help.svg"}
          alt={translate("wcag.wcag_help")}
          width={"20"}
          height={"20"}
        />
        {translate("survey.howtoread_yourprescription")}
      </Button>

      {showModal &&
        createPortal(
          <div
            className={`${style.howToReadPrescriptionModal} ${
              insideModal ? style.insideModal : ""
            }`}
            aria-modal="true"
            role="dialog"
            aria-labelledby="howToReadYourPrescriptionTitle"
          >
            <div className={style.howToReadPrescriptionModalContent}>
              <Button
                className={style.closeIcon}
                onClick={() => setShowModal(false)}
                icon="close"
                ariaLabel={translate("wcag.wcag_close_modal")}
              />

              <div
                id="howToReadYourPrescriptionTitle"
                className={style.howToReadPrescriptionModalContentTitle}
              >
                {translate("survey.howtoread_yourprescription")}
              </div>
              <HowToReadPrescriptionModalContent isCL={isCL} />
            </div>
          </div>,
          document.querySelector(modalSelector || SELECTOR_RXR)
        )}
    </>
  );
};

export default HowToReadPrescriptionButton;
