import ModalCheck from "@components/common/ModalCheck/component";
import { useBreakpointBoolean } from "@hooks/useBreakpointBoolean";
import { useTranslation } from "@hooks/useTranslation";
import { useUtag } from "@hooks/useUtag";
import { selectTBTInfo } from "@slices/surveySlice";
import { selectAcuityEnd } from "@slices/utilitySlice";
import { ROUTING } from "@utility/routingUtility";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import style from "./style.module.scss";

function CannotGoBackModal() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const breakpoint = useBreakpointBoolean();
  const TBTInfo = useSelector(selectTBTInfo);
  const acuityEnd = useSelector(selectAcuityEnd);
  const { sendAnalytics } = useUtag({ sendAtEveryPage: false });

  useEffect(() => {
    sendAnalytics("ERROR_EVENT", [""], {
      Events_Error: "1",
      Error_Source: "User",
      Error_Code: "Cannot go back error",
      Error_Details:
        "User cannot go back to the previous page during the app flow",
      Error_Message: translate("cannogoback.title"),
    });
  }, []);

  const handleCTA = () => {
    const afterActuityDesktop =
      TBTInfo?.payload?.status === "ACUITY_END_DESKTOP";
    const afterAcuitySmartphone = TBTInfo?.payload?.status === "ACUITY_END";

    if (afterActuityDesktop || afterAcuitySmartphone) {
      if (breakpoint.isPhoneOrTabletPortrait) {
        //smartphone viewport
        if (afterActuityDesktop) {
          //continue on desktop
          navigate(ROUTING.BEFORE_YOU_CONTINUE.url);
        } else {
          //continue on smartphone
          navigate(ROUTING.CURRENT_PRESCRIPTION.url);
        }
      } else {
        //desktop viewport
        if (afterActuityDesktop) {
          //continue on desktop
          navigate(ROUTING.CURRENT_PRESCRIPTION.url);
        } else {
          //continue on smartphone
          navigate(ROUTING.SURVEY.url.replace(":stepId?", "review_step"));
        }
      }
    } else if (acuityEnd && breakpoint.isPhoneOrTabletPortrait) {
      //if the user has finished the acuity test but doesn't take the decision to continue on desktop or smartphone
      navigate(ROUTING.ACUITY_END.url);
    } else {
      if (breakpoint.isPhoneOrTabletPortrait) {
        navigate(ROUTING.LEGAL_DISCLAIMER.url);
      } else {
        navigate(ROUTING.SURVEY.url.replace(":stepId?", "review_step"));
      }
    }
  };

  return (
    <ModalCheck
      className={style.cannotGoBackPopup}
      isOpen={true}
      icon="error"
      message={translate("cannogoback.title")}
      description={translate("cannogoback.description")}
      ctaPrimaryLabel={translate("cannogoback.cta")}
      ctaPrimaryAction={handleCTA}
    />
  );
}

export default CannotGoBackModal;
