import React, { memo, ReactNode, useEffect, useState } from "react";
import style from "./style.module.scss";
import { clsx } from "@utility/utility";
import { useSelector } from "react-redux";
import { selectLabelInfo } from "@slices/utilitySlice";

type Props = {
  className?: string;
  children?: ReactNode;
  justifyContent?: "center" | "start" | "end" | "space-between";
  fixed?: boolean;
  showShadowScroll?: boolean;
};

export default memo(function Footer(props: Props) {
  const isLabelLoading = useSelector(selectLabelInfo)?.isLoading;

  const [showShadow, setShowShadow] = useState<boolean>(false);
  const [showWhiteBackground, setShowWhiteBackground] =
    useState<boolean>(false);

  useEffect(() => {
    let resizeListener = null;
    const handleScroll = (e) => {
      try {
        if (Array.isArray(e)) {
          e = e[0];
        }

        let element = e?.target;
        if (!element) {
          return;
        }
        if (!element.scrollHeight) {
          element = element.scrollingElement;
        }

        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;
        const scrollTop = element.scrollTop;

        const scrollbarPresent = scrollHeight - 1 > clientHeight;
        const arrivedToBottom =
          Math.round((100 * scrollTop) / (scrollHeight - clientHeight)) || 0;

        setShowShadow(scrollbarPresent && arrivedToBottom < 97);
        setShowWhiteBackground(scrollbarPresent);
      } catch (e) {
        console.error("error during shadow scroll handling in footer", e);
      }
    };

    // if (props.showShadowScroll) {
    window.addEventListener("scroll", handleScroll);
    if (window.ResizeObserver) {
      resizeListener = new ResizeObserver(handleScroll).observe(
        document.querySelector("html")
      );
    } else {
      window.addEventListener("resize", handleScroll);
      setTimeout(() => {
        window.scrollBy(0, 1);
        window.resizeBy(0, 0);
      }, 500);
    }
    // }

    return () => {
      if (props.showShadowScroll) {
        window.removeEventListener("scroll", handleScroll);

        if (window.ResizeObserver) {
          if (resizeListener) {
            resizeListener.disconnect();
          }
        } else {
          window.removeEventListener("resize", handleScroll);
        }
      }
    };
  }, [props.showShadowScroll]);

  return (
    <div
      className={clsx(style.Footer, {
        [style.flexStart]: props.justifyContent == "start",
        [style.flexEnd]: props.justifyContent == "end",
        [style.FlexCenter]: props.justifyContent == "center",
        [style.flexSpaceBetween]: props.justifyContent == "space-between",
        [style.fixed]: !!props.fixed,
        [props.className]: !!props.className,
        [style.shadow]: showShadow,
        [style.whiteBackground]: showWhiteBackground,
      })}
    >
      {!isLabelLoading && props.children}
    </div>
  );
});
