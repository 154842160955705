import {
  SetupVideoType,
  acuityAsyncActions,
  selectSetupVideosInfo,
} from "@slices/acuitySlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useGetSetupVideos = (
  type: "tutorial" | "training" | "acuity-dx" | "acuity-sx",
  stopRequest = false
): SetupVideoType[] => {
  const dispatch = useDispatch();
  const setupVideosInfo = useSelector(selectSetupVideosInfo);

  const [typeSetupVideos, setTypeSetupVideos] = useState<SetupVideoType[]>([]);

  useEffect(() => {
    if (
      !stopRequest &&
      !setupVideosInfo?.language &&
      !setupVideosInfo?.isLoading
    ) {
      dispatch(acuityAsyncActions.getSetupVideos());
    }
  }, [stopRequest]);

  useEffect(() => {
    setTypeSetupVideos(setupVideosInfo?.data?.[type] || []);
  }, [setupVideosInfo, type]);

  return typeSetupVideos;
};
