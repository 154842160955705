import stylePageCardMobile from "@components/PageCardMobile/style.module.scss";
import Button from "@components/common/Button/component";
import ModalCheck from "@components/common/ModalCheck/component";
import PageStandard from "@components/common/PageStandard/component";
import { useTranslation } from "@hooks/useTranslation";
import { selectConfig } from "@slices/configSlice";
import { selectTBTInfo, updateTBTPayload } from "@slices/surveySlice";
import { utilityActions } from "@slices/utilitySlice";
import { formatAnalyticsInnerText } from "@utility/analyticsUtility";
import { printHTML } from "@utility/htmlUtility";
import { ROUTING } from "@utility/routingUtility";
import { exitRxr } from "@utility/utility";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";

type CurrentPrescriptionPageProps = {};

function BeforeYouContinuePage(props: CurrentPrescriptionPageProps) {
  const navigate = useNavigate();
  const translate = useTranslation();
  const dispatch = useDispatch();
  const TBTInfo = useSelector(selectTBTInfo);
  const config = useSelector(selectConfig);

  const [showModalSwitch, setShowModalSwitch] = useState(false);

  useEffect(() => {
    if (TBTInfo?.payload?.status === "ACUITY_END") {
      dispatch(
        utilityActions.setCannotGoBackPopup({ cannotGoBackPopup: true })
      );
    }
  }, [!!TBTInfo?.payload?.status]);

  const handleCTA = (goToDesktop: boolean = false) => {
    console.log("TBTInfo", TBTInfo);
    if (TBTInfo.payload?.status === "ACUITY_END_DESKTOP") {
      //if the status is already set to switch to desktop --> prevent smartphone flow
      handleShowModalSwitch();
    } else {
      //changing TBT status to ACUITY_END in order to return to desktop
      if (TBTInfo.payload && TBTInfo.TBT) {
        let newPayload = { ...TBTInfo.payload };
        newPayload.status = goToDesktop ? "ACUITY_END_DESKTOP" : "ACUITY_END";
        dispatch(
          updateTBTPayload({ tokenId: TBTInfo.TBT, payload: newPayload })
        );
      }
      if (goToDesktop) {
        handleShowModalSwitch();
      } else {
        navigate(ROUTING.CURRENT_PRESCRIPTION.url);
      }
    }
  };

  const handleShowModalSwitch = () => {
    setShowModalSwitch(true);
    setTimeout(() => {
      exitRxr(config.config?.headerBackLinkUrl);
    }, 10000);
  };

  return (
    <PageStandard
      className={`${style.Background} ${stylePageCardMobile.pageCardMobile}`}
      classNamePageBody={stylePageCardMobile.pageCardMobileBody}
      footerInfo={{
        showLeftButton: true,
        iconLeftButton: "leftArrow",
        classNameLeftButton: stylePageCardMobile.footerLeftButton,
        showRightButton: true,
        classNameRightButton: stylePageCardMobile.footerRightButton,
        labelRightButton: translate(
          "before_you_continue.switch_to_desktop_button_label"
        ),
        actionRightButton: () => handleCTA(true),
        analyticsInfoRightButton: {
          "data-element-id": "RxRenewal_ResultValidation-SwitchBackToDesktop",
          "data-description": formatAnalyticsInnerText(
            translate("before_you_continue.switch_to_desktop_button_label")
          ),
          "data-analytics_available_call": 1,
        },
        otherElements: (
          <Button
            className={style.continueToMobileButton}
            variant="lightBlue"
            stretchTo330px={true}
            onClick={() => handleCTA(false)}
            analyticsInfo={{
              "data-element-id":
                "RxRenewal_ResultValidation-ContinueWithMobileDevice",
              "data-description": formatAnalyticsInnerText(
                translate("before_you_continue.continue_to_mobile_button_label")
              ),
              "data-analytics_available_call": 1,
            }}
          >
            {translate("before_you_continue.continue_to_mobile_button_label")}
          </Button>
        ),
        backgroundWhite: true,
      }}
      showHeader={true}
      showBackgroundVideo={true}
    >
      <div
        className={`${stylePageCardMobile.overPageContainer} ${style.overPageContainer}`}
      >
        <p className={stylePageCardMobile.overPageTitle}>
          {translate("current_prescription.over_title")}
        </p>

        <p
          className={stylePageCardMobile.overPageSteps}
          dangerouslySetInnerHTML={printHTML(
            translate("current_prescription.steps_recap")
              ?.replace("{{step}}", "1")
              .replace("{{totalSteps}}", "3")
          )}
        />
      </div>

      <div
        className={`${stylePageCardMobile.pageBodyCard} ${stylePageCardMobile.noPaddingBodyCard}`}
      >
        <div className={style.pageBody}>
          <p
            className={stylePageCardMobile.title}
            dangerouslySetInnerHTML={printHTML(
              translate("before_you_continue.title")
            )}
          />
          <p
            className={`${style.description}`}
            dangerouslySetInnerHTML={printHTML(
              translate("before_you_continue.description")
            )}
          />
        </div>
      </div>
      {showModalSwitch && (
        <ModalCheck
          isOpen={showModalSwitch}
          icon="switch"
          message={translate("before_you_continue.modal_switch_title")}
          description={translate(
            "before_you_continue.modal_switch_description"
          )}
        />
      )}
    </PageStandard>
  );
}

export default BeforeYouContinuePage;
