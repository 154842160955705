import { configReducer } from "./slices/configSlice";
import { utilityReducer } from "./slices/utilitySlice";
import { surveyReducer } from "./slices/surveySlice";
import { acuityReducer } from "./slices/acuitySlice";

const rootReducer = {
  config: configReducer,
  utility: utilityReducer,
  survey: surveyReducer,
  acuity: acuityReducer,
};

export default rootReducer;
