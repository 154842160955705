import React, { CSSProperties, memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  src: string;
  alt: string;
  width: number | string; //to prevent CLS
  height: number | string; //to prevent CLS
  className?: string;
  onError?: () => void;
  placeholder?: string;
  style?: CSSProperties;
  ariaHidden?: boolean;
}

const LazyImage = memo(
  ({
    src,
    width,
    height,
    alt,
    className,
    onError = () => {},
    placeholder,
    style,
    ariaHidden,
  }: Props) => {
    return src ? (
      <LazyLoadImage
        className={className}
        src={src}
        alt={alt}
        width={width}
        height={height}
        placeholder={placeholder ? <span>{placeholder}</span> : null}
        onError={onError}
        style={style}
        aria-hidden={ariaHidden}
      />
    ) : null;
  }
);

export default LazyImage;
