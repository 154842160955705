import ModalCheck from "@components/common/ModalCheck/component";
import { useTranslation } from "@hooks/useTranslation";
import { useUtag } from "@hooks/useUtag";
import { selectConfig } from "@slices/configSlice";
import { utilityAsyncActions } from "@slices/utilitySlice";
import {
  cleanSessionStorage,
  sessionStorageIds,
} from "@utility/sessionStorageUtility";
import { exitRxr } from "@utility/utility";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function TimeoutModal() {
  const translate = useTranslation();
  const config = useSelector(selectConfig);
  const { sendAnalytics } = useUtag({ sendAtEveryPage: false });
  const dispatch = useDispatch();

  const restartCTAAction = () => {
    history.go(0);
  };

  useEffect(() => {
    const TBT = sessionStorage.getItem(sessionStorageIds.TBT);

    sendAnalytics("ERROR_EVENT", [""], {
      Events_Error: "1",
      Error_Source: "Server",
      Error_Code: "Timeout error",
      Error_Details: "Token " + TBT + " is expired or doesn't exist",
      Error_Message: translate("timeout.title"),
    });

    //clean tokens because they are expired
    cleanSessionStorage();
  }, []);

  return (
    <ModalCheck
      isOpen={true}
      icon="timeout"
      message={translate("timeout.title")}
      description={translate("timeout.description")}
      ctaPrimaryLabel={translate("timeout.restart_bottom_label")}
      ctaPrimaryAction={restartCTAAction}
      ctaLabel={translate("error.leave_bottom_label")}
      ctaAction={() => {
        dispatch(
          utilityAsyncActions.invokeEcommerceFunction({
            functionId: "updateAssessmentStatus",
            finallyCallback: () => {
              exitRxr(config.config?.headerBackLinkUrl);
            },
          })
        );
      }}
    />
  );
}

export default TimeoutModal;
