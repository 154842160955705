import { SurveyType } from "@model/SurveyModel";

export const numberOfFurtherSteps = 2;
export const prescriptionStepId = "prescription_step";
export const reviewStepId = "review_step";
export const findStepInfoByUrlParam = (
  urlParam: string,
  survey: SurveyType
): { stepId: string; stepNumber: number } => {
  if (!survey) {
    return { stepId: "", stepNumber: 1 };
  }

  switch (urlParam) {
    case prescriptionStepId:
      return {
        stepId: prescriptionStepId,
        stepNumber: survey?.steps?.length + 1,
      };
    case reviewStepId:
      return { stepId: reviewStepId, stepNumber: survey?.steps?.length + 2 };
    default:
      return {
        stepId: survey?.steps?.[+urlParam - 1]?.idFE,
        stepNumber: +urlParam,
      };
  }
};
